import React, { useState, useEffect } from "react";

import "./toggle.scss";
const Toggle = ({ options, input }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    input.onChange(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if (input.value !== "") {
      setSelectedOption(input.value);
    }
  }, [input.value]);

  const changeHandler = (e) => {
    setSelectedOption((prev) => e);
  };

  return (
    <div className="toggleField">
      {options.map((element, index) => (
        <label
          className={`${
            element.value === selectedOption.value
              ? "optionItem active"
              : "optionItem"
          }`}
          key={index}
          htmlFor={`${input.name}${index + 1}`}
          onClick={() => changeHandler(element)}
        >
          {element.text}
        </label>
      ))}
    </div>
  );
};

export default Toggle;
