import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, Field, reduxForm, getFormValues } from "redux-form";
import { useTranslation } from "react-i18next";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import Button from "../../components/ui/Button/Button";
import Input from "../../components/formComponent/Input/Input";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevroRight from "../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import emptyImage from "../../../style/icons/EmptyImg/emptyImg.svg";

import { voidRequired } from "../../../helpers/validate";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";
import {
  addNewBanner,
  updateBanner,
  deleteBanner,
  getAllBanners,
} from "../../../redux/banners/actions";

import api from "../../../api";

import "./newBannerForm.scss";

const BannerForm = ({
  dirty,
  invalid,
  handleSubmit,
  formValues,
  edit,
  setEditingOff,
  initialValues,
  resetInitialValues,
  setInitalValues,
  reset,
  addNewBanner,
  updateBanner,
  deleteBanner,
  getAllBanners,
  blob,
  setBlob,
  addingImg,
  setAddingImg,
}) => {
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit((values) => {
      if (edit && !deleting) {
        if (
          initialValues.text !== values.text ||
          initialValues.link !== values.link
        ) {
          updateBanner(values.id, {
            banner: {
              text: values.text,
              link: values.link,
              isActive: true,
            },
          })
            .then((res) => {
              console.log("succes edit", res);
              getAllBanners();
            })
            .catch(() => console.log("edit error"));
        }
        if (blob) {
          api.banners
            .upload(values.id, blob)
            .then((res) => {
              console.log("succes photo edit", res);
              getAllBanners();
              setBlob(null);
            })
            .catch(() => {
              setBlob(null);
              console.error("ERROR_MESSAGE");
            });
        }
      }
      if (edit && deleting) {
        deleteBanner(values.id).then(() => {
          console.log("succes delet");
          getAllBanners();
        });
        setDeleting(false);
      }
      if (!edit) {
        addNewBanner({
          banner: {
            text: values.text,
            link: values.link,
            isActive: true,
          },
        }).then(async ({ entity: { id } }) => {
          if (blob) {
            await api.banners
              .upload(id, blob)
              .then((res) => {
                console.log("succes add", res);
              })
              .catch(() => console.error("ERROR_MESSAGE"));
            setBlob(null);
          }
          getAllBanners();
        });
      }
      resetInitialValues();
      reset();
      setEditingOff();
    })();
  };

  return (
    <div className="wrappNewBannerForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit ? `${t("edit")}` : `${t("newBanner")}`} `}
        {edit && (
          <img
            src={close}
            alt="arrow-icon"
            onClick={() => {
              setEditingOff();
              resetInitialValues();
              reset();
            }}
          />
        )}
      </div>
      <Form className="newBannerForm" onSubmit={formSubmit}>
        <div className="formContent">
          <div className={`addingImg ${addingImg ? "show" : ""}`}>
            <div className="formTitle">
              <img
                src={arrowLeft}
                alt="arrow-icon"
                onClick={() => {
                  setAddingImg(false);
                  setEditingOff();
                }}
              />
              {t("addImage")}
            </div>
            <div className="photoUploadFieldContainer">
              <Field
                name="photo"
                // validate={[voidRequired]}
                component={FileUploader}
                previewTrashIcon={trash}
                // uploadReq={() => console.log("action")}
                close={() => setAddingImg(false)}
                editingImgUrl={
                  initialValues &&
                  initialValues.photo &&
                  initialValues.photo.cropImg
                }
                setBlob={setBlob}
                setEditingOff={setEditingOff}
                t={t}
              />
            </div>
          </div>
          {formValues && formValues.photo && formValues.photo.cropImg ? (
            <div className="croppImage">
              <div
                className="trashIcon"
                onClick={() => {
                  reset();
                  setInitalValues({
                    ...formValues,
                    photo: null,
                  });
                }}
              >
                <img src={trash} alt="" />
              </div>
              <div className="background">
                <img
                  src={
                    (formValues &&
                      formValues.photo &&
                      formValues.photo.cropImg) ||
                    emptyImage
                  }
                  alt=""
                />
              </div>
              <div className="fileName">
                {formValues && formValues.photo && formValues.photo.fileName}
              </div>
            </div>
          ) : (
            <Button
              secondary
              withIcon
              icon={plus}
              shevron={shevroRight}
              type="button"
              className="btn"
              text={t("addImage")}
              onClick={() => setAddingImg(true)}
            />
          )}

          <Field
            name="link"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("placeholderLink")}
            clearInput
          />
          <Field
            name="text"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("placeholderTitle")}
          />
        </div>

        <div className={`formFooter ${edit ? "edit" : ""}`}>
          {!edit ? (
            <Button
              className="btn"
              disabled={invalid}
              type="submit"
              text={t("addButton")}
            />
          ) : (
            <>
              <Button
                className="btn"
                disabled={invalid}
                type="submit"
                text={t("saveButton")}
              />
              <Button
                className="btn"
                disabled={invalid}
                danger
                type="submit"
                text={t("deleteButton")}
                onClick={() => setDeleting(true)}
              />
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  addNewBanner,
  updateBanner,
  deleteBanner,
  getAllBanners,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("banner")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "banner", enableReinitialize: true })
);

export default enhance(BannerForm);
