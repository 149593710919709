import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/messaging";

import bell from "../../../../style/icons/Bell/bell.svg";
import "./notification.scss";
import api from "../../../../api";

const Bell = ({ unsearchNotifications, toggleModal, getNotificationsList }) => {
  useEffect(() => {
    if ("Notification" in window) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(function () {
          messaging.getToken().then((currentToken) => {
            localStorage.setItem("sentFirebaseMessagingToken", currentToken);
            api.user.sendFirebaseToken(currentToken);
          });
        })
        .catch((err) => {
          console.log("Unable to get permission to notify.", err);
        });
      messaging.onMessage((payload) => {
        getNotificationsList();
      });
    }
  }, []);

  return (
    <div className="bell notify" onClick={() => toggleModal(true)}>
      <img className="notification" src={bell} alt="" />
      {unsearchNotifications > 0 && (
        <div className="unsearchNotifications">
          <span
            style={{
              zoom: unsearchNotifications < 10 ? "1" : "0.8",
              paddingTop: unsearchNotifications < 10 ? "1px" : "2px",
              paddingLeft: unsearchNotifications < 10 ? "0px" : "2px",
            }}
          >
            {unsearchNotifications < 10 ? unsearchNotifications : "9+"}
          </span>
        </div>
      )}
    </div>
  );
};

export default Bell;
