import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ConsultateList from "../../components/ConsultateList/ConsultateList";
import Filter from "../../components/Filter/Filter";

import { getAllSpecializations } from "../../../redux/specializations/actions";

const Monitoring = ({ getAllSpecializations, role, patientCard }) => {
  const [specializations, setSpecializations] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllSpecializations().then(({ items }) => setSpecializations(items));
  }, []);

  return (
    <div className="monitoringContainer flex justifyBeetwen">
      <ConsultateList
        role={role}
        filterParams={filterParams}
        loading={loading}
        setLoading={setLoading}
      />
      <Filter
        mode="monitoring"
        specializations={specializations}
        role={role}
        setFilterParams={setFilterParams}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

const enhance = compose(withRouter, connect(null, { getAllSpecializations }));

export default enhance(Monitoring);
