import * as types from "./types";
import api from "../../api";

export const getAllEmployees = (filterMode) => async (dispatch) => {
  if (filterMode === "all" || filterMode === undefined) {
    return await api.employees.getAllEmployees().then(async (responce) => {
      let countOperators = await api.employees
        .getAllOperators()
        .then((res) => res && res.data && res.data.total_item_count);

      let countAdmins = 0;

      let countDoctors = await api.employees
        .getAllDoctors()
        .then((res) => res && res.data && res.data.total_item_count);

      return dispatch({
        type: types.GET_ALL_EMPLOYEES,
        ...(responce && responce.data),
        countOperators,
        countDoctors,
        countAdmins,
        filterMode: filterMode || "all",
      });
    });
  }
  if (filterMode === "doctors") {
    return await api.employees.getAllDoctors().then((res) =>
      dispatch({
        type: types.GET_ALL_DOCTORS,
        ...res.data,
        filterMode,
      })
    );
  }
  if (filterMode === "operators") {
    return api.employees.getAllOperators().then((res) =>
      dispatch({
        type: types.GET_ALL_OPERATORS,
        filterMode,
        ...res.data,
        countOperators: res.data.total_item_count,
      })
    );
  }
};

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_EMPLOYEE,
    filterMode,
  };
};

export const createOperator = (data) => (dispatch) => {
  let operator = data;
  const role = () => {
    if (operator.isAdmin) {
      return "ROLE_INSURANCE_ADMIN";
    } else if (!operator.isAdmin && operator.insuranceCompany) {
      return "ROLE_INSURANCE_OPERATOR";
    } else {
      return "ROLE_OPERATOR";
    }
  };
  return api.employees
    .createOperator({
      employee: {
        email: operator.email,
        phone: operator.phone,
        firstName: operator.firstName,
        lastName: operator.lastName,
        patronymic: operator.patronymic,
        birthDate: operator.birthDate,
        gender: operator.gender,
        insuranceCompany: operator.insuranceCompany,
        plainPassword: operator.email,
        role: role(),
      },
      role: role(),
    })
    .then((res) => dispatch({ type: "CREATE_OPERATOR", res }))
    .catch((err) => ({ ...err }));
};

export const updateOperator = (id, data) => {
  let operator = data;
  return api.employees
    .updateEmployee(id, {
      employee: {
        email: operator.email,
        phone: operator.phone,
        firstName: operator.firstName,
        lastName: operator.lastName,
        patronymic: operator.patronymic,
        birthDate: operator.birthDate,
        gender: operator.gender,
        insuranceCompany: operator.insuranceCompany,
        plainPassword: operator.email,
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

export const deleteEmployee = (id) => (dispatch) => {
  return api.employees
    .deleteEmployee(id)
    .then((res) => dispatch({ type: "DELETE_EMPLOYEE", res }));
};

export const createDoctor = (data) => (dispatch) => {
  let doctor = data;
  return api.employees
    .createDoctor({
      doctor: {
        educationType: doctor.educationType,
        educationSpecialty: doctor.educationSpecialty,
        experience: doctor.experience,
        specialty: doctor.specialty.map((el) => el.id),
        description: doctor.description,
        employee: {
          email: doctor.email,
          phone: doctor.phone,
          firstName: doctor.firstName,
          lastName: doctor.lastName,
          patronymic: doctor.patronymic,
          birthDate: doctor.birthDate,
          gender: doctor.gender,
          plainPassword: doctor.email,
        },
      },
    })
    .then((res) => dispatch({ type: "CREATE_NEW_DOCTOR", res }))
    .catch((err) => ({ ...err }));
};

export const updateDoctor = (id, data) => (dispatch) => {
  let doctor = data;
  return api.employees
    .updateDoctor(id, {
      doctor: {
        educationType: doctor.educationType,
        educationSpecialty: doctor.educationSpecialty,
        experience: doctor.experience,
        specialty: doctor.specialty.map((el) => el.id),
        description: doctor.description,
        employee: {
          email: doctor.email,
          phone: doctor.phone,
          firstName: doctor.firstName,
          lastName: doctor.lastName,
          patronymic: doctor.patronymic,
          birthDate: doctor.birthDate,
          gender: doctor.gender,
          plainPassword: doctor.email,
        },
      },
    })
    .then((res) => dispatch({ type: "UPDATE_DOCTOR", res }));
};

export const deleteDoctor = (id) => (dispatch) => {
  return api.employees
    .deleteDoctor(id)
    .then((res) => dispatch({ type: "DELETE_DOCTOR", res }));
};

export const getDoctorsByFilter = (idFilter = []) => (dispatch) => {
  let filterString = "";
  idFilter.map((el, i) =>
    i > 0
      ? (filterString += `&filter[specialty][${el}]`)
      : (filterString += `filter[specialty][${el}]`)
  );
  return api.employees
    .getAllDoctors("", filterString, 100)
    .then((res) =>
      dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
    );
};

export const setPagination = ({ filterMode, numberOfPage }) => (dispatch) => {
  // console.log(filterMode, numberOfPage);
  if (filterMode === "all") {
    return api.employees.getAllEmployees(numberOfPage).then(({ data }) => {
      dispatch({
        type: types.GET_ALL_EMPLOYEES,
        ...data,
        items: data.items,
        filterMode,
      });
    });
  }
  if (filterMode === "doctors") {
    return api.employees.getAllDoctors(numberOfPage, "").then(({ data }) => {
      dispatch({
        type: types.GET_ALL_DOCTORS,
        ...data,
        items: data.items,
        filterMode,
      });
    });
  }
  if (filterMode === "operators") {
    return api.employees.getAllOperators(numberOfPage).then(({ data }) => {
      // console.log(data);
      dispatch({
        type: types.GET_ALL_OPERATORS,
        ...data,
        items: data.items,
        filterMode,
      });
    });
  }
};

export const getDoctorBySearch = (fullName) => (dispatch) => {
  return api.employees.getDoctorBySearch(fullName).then((res) => {
    dispatch({
      type: types.GET_DOCTOR_BY_SEARCH,
      data: { ...(res && res.data) },
    });
  });
};

export const getInsuranceCompanies = () => (dispatch) => {
  return api.employees.getInsuranceCompanies().then((res) => {
    dispatch({
      type: types.GET_INSURANCE_COMPANIES,
      data: { ...(res && res.data) },
    });
  });
};
