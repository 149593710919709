import React, { useEffect, useState } from "react";

const Paginator = ({
  id,
  current_page,
  setPagination,
  setLoading,
  filterMode,
  page_count,
  scrollElement,
  role,
  filterParams,
  isItemsFiltered,
  intervalDisabled,
  monitoringFilterValues: {
    allOrInWork,
    specialization,
    dateStart,
    dateEnd,
    status,
  } = {},
}) => {
  const [page, setPage] = useState([]);
  const [paginationArr, setPaginationArr] = useState([]);

  useEffect(() => {
    if (!intervalDisabled) {
      const interval = setInterval(() => {
        setPagination({
          filterParams,
          role,
          filterMode,
          numberOfPage: current_page,
          isItemsFiltered,
          allOrInWork,
          specialization,
          dateStart,
          dateEnd,
          status,
        });
        console.log("The list refreshing every 30 second!");
      }, 30000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    setPage(new Array(page_count).fill(0));
  }, [page_count]);

  const setPaginationPage = (numberOfPage) => {
    setLoading(true);
    setPagination({
      filterParams,
      id,
      role,
      filterMode,
      numberOfPage,
      isItemsFiltered,
      allOrInWork,
      specialization,
      dateStart,
      dateEnd,
      status,
    }).then(() => setLoading(false));
  };

  useEffect(() => {
    if (page && page.length) {
      if (page.length >= 7) {
        if (current_page >= 4 && current_page < page.length - 3) {
          setPaginationArr([
            current_page - 2,
            current_page - 1,
            current_page,
            current_page + 1,
            current_page + 2,
          ]);
        } else if (current_page >= page.length - 3) {
          setPaginationArr([
            page.length - 5,
            page.length - 4,
            page.length - 3,
            page.length - 2,
            page.length - 1,
            page.length,
          ]);
        } else if (current_page <= 6) {
          setPaginationArr([1, 2, 3, 4, 5, 6]);
        }
      } else if (page.length <= 7) {
        setPaginationArr(page);
      }
    }
  }, [page, current_page]);

  return (
    <div className="paginator">
      {page.length && (
        <>
          {current_page >= 2 && (
            <div
              className={`paginationItem`}
              onClick={() => {
                setPaginationPage(current_page - 1);
              }}
            >
              {"<"}
            </div>
          )}

          <div
            className={`paginationItem ${current_page === 1 ? "active" : ""}`}
            onClick={() => {
              setPaginationPage(1);
            }}
          >
            {1}
          </div>

          {page.length >= 7 && current_page >= 5 && (
            <div className="paginationItem">...</div>
          )}

          {page.length > 7
            ? paginationArr.map((el, i) => {
                if (el != 1 && el != page.length) {
                  return (
                    <div
                      key={i}
                      className={`paginationItem ${
                        current_page === el ? "active" : ""
                      }`}
                      onClick={() => setPaginationPage(el)}
                    >
                      {el}
                    </div>
                  );
                }
              })
            : page.map((el, i) => {
                if (i != 0 && i != page.length - 1) {
                  return (
                    <div
                      key={i}
                      className={`paginationItem ${
                        current_page === i + 1 ? "active" : ""
                      }`}
                      onClick={() => setPaginationPage(i + 1)}
                    >
                      {i + 1}
                    </div>
                  );
                }
              })}

          {page.length >= 7 && current_page < page.length - 3 && (
            <div className="paginationItem">...</div>
          )}

          {page.length >= 2 && (
            <div
              className={`paginationItem ${
                current_page === page.length ? "active" : ""
              }`}
              onClick={() => {
                setPaginationPage(page.length);
              }}
            >
              {page.length}
            </div>
          )}

          {current_page < page.length && (
            <div
              className={`paginationItem`}
              onClick={() => {
                setPaginationPage(current_page + 1);
              }}
            >
              {">"}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Paginator;
