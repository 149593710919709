import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

import { store } from "../../";
import { setNetworkError } from "../../redux/networkError/actions";

export default {
  getSubs: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/subscriptions`,
      headers: setHeader(),
    };

    return axios(config).then((res) => !res.code && res);
  },
  createSub: async (body) => {
    return await fetch(`${TEST_API}/admin/subscriptions`, {
      method: "POST",
      headers: setHeader(),
      body,
    }).then((res) => res.statusText === "Created" && res);
  },
  createCompany: async (body) => {
    return await fetch(`${TEST_API}/admin/companies`, {
      method: "POST",
      headers: setHeader(),
      body,
    }).then((res) => res && res.json());
  },
  addCompanyPhoto: async (id, file) => {
    let formData = new FormData();
    formData.append("imageFile", file);
    return axios
      .post(`${TEST_API}/admin/companies/${id}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  getSubscribers: ({ filterMode, id, numberOfPage }) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/companies/${id}/patients${
        filterMode
          ? numberOfPage
            ? `?filter[${filterMode}]=true&page=${numberOfPage}`
            : `?filter[${filterMode}]=true`
          : numberOfPage
          ? `?page=${numberOfPage}`
          : ""
      }?perPage=16`,
      headers: setHeader(),
    };

    return axios(config).then((res) => !res.code && res && res.data);
  },

  upload: (file, id) => {
    let formData = new FormData();
    formData.append("cardsFile", file);
    return axios
      .post(`${TEST_API}/admin/companies/${id}/import`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        let data = res.data;
        if (data.entity.errorTotal > 0) {
          store.dispatch(
            setNetworkError({
              response: {
                status: "Error",
                succes: false,
                statusText: data.message,
                erorrsArr: data.errors,
                hold: true,
              },
            })
          );
        } else {
          store.dispatch(
            setNetworkError({
              response: {
                status: "Succes",
                succes: true,
                statusText: data.message,
                hold: true,
              },
            })
          );
        }
      });
  },
};
