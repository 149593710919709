import React from "react";

import NewSubscriptionForm from "../../forms/NewSubscriptionForm/NewSubscriptionForm";
import SubscriptionList from "../../components/SubscriptionList/SubscriptionList";

import "./Subscription.scss";

const Subscription = () => {
  return (
    <div className="subscription flex justifyBeetwen">
      <SubscriptionList />
      <NewSubscriptionForm />
    </div>
  );
};

export default Subscription;
