import React, { useState, useEffect } from "react";

import "./toggleImg.scss";

const ToggleImg = ({ options, input }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    input.onChange(selectedOption);
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (!input.value) {
      setSelectedOption(options[0]);
      input.onChange(options[0]);
    }
  }, [input.value]);

  const changeHandler = (e) => {
    setSelectedOption((prev) => e);
  };

  // console.log({ options, selectedOption, value: input.value });
  return (
    <div className="toggleFieldImg">
      {options.map((element, index) => (
        <label
          className={`${
            element.value === selectedOption.value
              ? "optionItem active"
              : "optionItem"
          }`}
          key={index}
          htmlFor={`${input.name}${index + 1}`}
          onClick={() => changeHandler(element)}
        >
          <img
            src={
              element.value === selectedOption.value
                ? element.imgActive
                : element.imgInactive
            }
            alt=""
          />
          {element.text}
        </label>
      ))}
    </div>
  );
};

export default ToggleImg;
