import { setHeader, TEST_API } from "../index";
import axios from "axios";

export default {
  getAllPatients: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data.data);
  },
  getThePatient: (patient, patientCard) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/${patient}/cards/${patientCard}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res.data && res.data.entity);
  },
  getThePatientAnamnesis: (patient, patientCard) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/${patient}/cards/${patientCard}/anamnesis`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getPatientCards: (id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/${id}/cards`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  createPatientCard: (id, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/patients/${id}/cards`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  updatePatientCard: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/patients/cards/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  createNewPatientCard: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/patients/new`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  getThePatientAppointments: (patientCard) => {
    // admin/patients/cards/{patientCard}/appointments
    // admin/patients/{patient}/appointments
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/cards/${patientCard}/appointments`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  searchPatients: (role, key) => {
    if (role === "operator") {
      let config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/doctors/search?q=${key}`,
        headers: setHeader(),
      };
      return axios(config).then((res) => res && res.data);
    } else if (role === "doctor") {
      let config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/patients/search?q=${key}`,
        headers: setHeader(),
      };
      return axios(config).then((res) => res && res.data);
    }
  },
  getPatientRelations: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/patients/cards/relations`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  deletePatientCard: (cardId) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/patients/cards/${cardId}`,
      header: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  sendPatientConsultationToInsuranse: (consultationId, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${consultationId}/anamnesis/report`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  downloadPDFConsultation: (consultationId, token) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/appointments/${consultationId}/anamnesis/report?bearer=${token}`,
      headers: setHeader(),
    };
    window.open(
      `${TEST_API}/admin/appointments/${consultationId}/anamnesis/report?bearer=${token}`,
      "_blank"
    );
    return axios(config).then((res) => res && res.data);
  },
};
