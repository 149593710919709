import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = null;

const consultationReducer = createReducer(initialState)({
  [types.GET_ALL_CONSULTATIONS]: (
    state,
    {
      filterMode,
      count,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    count,
    isItemsFiltered: false,
    ...props,
  }),

  [types.GET_FILTERED_CONSULTATIONS]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
      ...payload.data,
      isItemsFiltered: true,
    };
  },

  [types.GET_TODAY_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    ...props,
  }),

  [types.GET_TOMORROW_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    ...props,
  }),

  [types.GET_WEEK_CONSULTATIONS]: (
    state,
    {
      filterMode,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    ...props,
  }),

  [types.SET_FILTER_MODE_MONITORING]: (state, { filterMode }) => ({
    ...state,
    filterMode,
    isItemsFiltered: false,
  }),
  [types.GET_CURRENT_CONSULTATION]: (state, { currentConsultation }) => ({
    ...state,
    currentConsultation,
    isItemsFiltered: false,
  }),
  [types.RESET_CURR_CONSULTATION]: (state) => {
    // delete state.currentConsultation;
    return {
      ...state,
      currentConsultation: {},
      isItemsFiltered: false,
    };
  },
});

export default consultationReducer;
