import axios from "axios";

import { store } from "../";
import { setToken } from "../redux/auth/action";
import { setNetworkError } from "../redux/networkError/actions";
import banners from "./banners";
import chat from "./chat";
import companies from "./companies";
import consultations from "./consultations";
import employees from "./employees";
import notification from "./notification";
import onboarding from "./onboarding";
import patients from "./patients";
import promocodes from "./promocodes";
import shedule from "./shedule";
import specializations from "./specializations";
import subscriptions from "./subscriptions";
import symptoms from "./symptoms";

import user from "./users";

export const CancelToken = axios.CancelToken;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch(setNetworkError(error));
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios({
          method: "POST",
          baseURL: `${TEST_API}/token/refresh`,
          data: {
            refreshToken,
          },
        })
          .then((res) => {
            if (res.data) {
              const { data } = res;
              if (data && data.token) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("refreshToken", data.refreshToken);
                store.dispatch(setToken(data.token));
                axios({
                  ...originalRequest,
                  headers: {
                    ...setHeader(),
                    Authorization: `Bearer ${data.token}`,
                  },
                })
                  .then((res) => res)
                  .catch(() => localStorage.clear());
              }
            } else {
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("token");
              window.location = `${TEST_HOST}/login`;
            }
          })
          .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
          });
      }
    }
    return error.response;
  }
);

export const TEST_HOST = process.env.REACT_APP_TEST_HOST;
export const SIGNALING_SERVER = process.env.REACT_APP_SIGNAL_SERVER;
export const PORT_CHAT_SOCKET = process.env.REACT_APP_PORT_CHAT_SOCKET;
export const PORT_CALL_SOCKET = process.env.REACT_APP_PORT_CALL;
export const PORT_SUPPORT_SOCKET = process.env.REACT_APP_PORT_CHAT_SUPPORT;

export const TEST_API = `${TEST_HOST}/api`;

export const setHeader = () => {
  const token = localStorage.getItem("token");

  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  let language =
    localStorage.getItem("lang") || localStorage.getItem("i18nextLng");
  if (language) {
    // console.log({ language });
    if (language === "uk") language = "ua";
    header = { ...header, "Accept-Language": language };
  }

  return header;
};

export default {
  user,
  banners,
  specializations,
  shedule,
  employees,
  consultations,
  patients,
  onboarding,
  symptoms,
  notification,
  subscriptions,
  promocodes,
  chat,
  companies,
};
