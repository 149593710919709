import React, { useState } from "react";

import DatePickerUI from "../../ui/DatePicker/DatePicker";

import deleteIconRed from "../../../../style/icons/CloseRed/close-red.svg";

import { formatDate } from "../../../../helpers/values";

import "../formComponents.scss";
import moment from "moment";
import { useEffect } from "react";

const DatePicker = ({
  label,
  input,
  meta: { touched, error, visited },
  placeholder,
  disabled,
  inputProps,
  validDate,
  tagsMode,
  locale,
  ...props
}) => {
  const [selectedDates, setSelectedDate] = useState([]);

  useEffect(() => {
    if (input.value === "") {
      setSelectedDate([]);
    }
  }, [input.value]);

  const deleteDate = (index) => {
    setSelectedDate((selectedDates) =>
      selectedDates.filter((el, i) => (i !== index ? el : ""))
    );
  };

  // console.log({ input, selectedDates });
  return (
    <div className={"formField picker"}>
      {label ? <label>{label}</label> : ""}

      <DatePickerUI
        {...input}
        name={inputProps && inputProps.name}
        value={
          (input && input.value && input.value.value) || (input && input.value)
        }
        onBlur={() => input.onBlur(input.value)}
        onChange={(value) => {
          if (tagsMode) {
            let tmpV =
              typeof value === "object"
                ? moment(value, "DD.MM.YYYY").format(formatDate)
                : value;
            selectedDates.push(tmpV);
            setSelectedDate([...selectedDates]);
            return input.onChange({
              value: tmpV,
              selectedDates,
            });
          } else {
            return input.onChange(
              typeof value === "object"
                ? moment(value, "DD/MM/YYYY").format(formatDate)
                : value
            );
          }
        }}
        placeholder={placeholder}
        touched={touched}
        error={error}
        visited={visited}
        disabled={disabled}
        tagsMode={tagsMode}
        validDate={validDate}
        selectedDates={selectedDates}
        locale={locale || localStorage.getItem("lang")}
        {...props}
      >
        {tagsMode && input && input.value && selectedDates !== [] && (
          <div className="selectedDates">
            {selectedDates &&
              selectedDates.map((item, index) => (
                <span className="date" key={index}>
                  {item}
                  <img
                    src={deleteIconRed}
                    alt=""
                    onClick={() => deleteDate(index)}
                  />
                </span>
              ))}
          </div>
        )}
      </DatePickerUI>
      {disabled && <div className="hiddingEl" />}
      {visited && error && <span className={"formItemError"}>{error}</span>}
    </div>
  );
};

export default DatePicker;
