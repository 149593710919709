export const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
export const GET_PATIENT_CARD = "GET_PATIENT_CARD";
export const GET_THE_PATIENT = "GET_THE_PATIENT";
export const GET_PATIENT_CARDS = "GET_PATIENT_CARDS";
export const GET_THE_PATIENT_ANAMNESIS = "GET_THE_PATIENT_ANAMNESIS";
export const GET_THE_PATIENT_APPOINTMENTS = "GET_THE_PATIENT_APPOINTMENTS";
export const SEARCH_PATIENTS = "SEARCH_PATIENTS";
export const GET_RELATIONS = "GET_RELATIONS";
export const END_OF_CONSULTATION = "END_OF_CONSULTATION";
export const CANCEL_OF_CONSULTATION = "CANCEL_OF_CONSULTATION";
export const RESET_PATIENT = "RESET_PATIENT";
