import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";

import Button from "../ui/Button/Button";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import DiagnosisActive from "../../../style/icons/Diagnosis/DiagnosisActive.svg";
import DiagnosisDisActive from "../../../style/icons/Diagnosis/DiagnosisDisActive.svg";
import FamilyDoctorActive from "../../../style/icons/FamilyDoctor/FamilyDoctorActive.svg";
import FamilyDoctorDisActive from "../../../style/icons/FamilyDoctor/FamilyDoctorDisActive.svg";
import ExaminationsActives from "../../../style/icons/Examinations/ExaminationsActive.svg";
import ExaminationsDisActives from "../../../style/icons/Examinations/ExaminationsDisActive.svg";
import LabsActive from "../../../style/icons/Labs/LabsActive.svg";
import LabsDisActive from "../../../style/icons/Labs/LabsDisActive.svg";
import PreparationsActive from "../../../style/icons/Preparations/PreparationsActive.svg";
import PreparationsDisActive from "../../../style/icons/Preparations/PreparationsDisActive.svg";
import { required } from "../../../helpers/validate";
import { connect } from "react-redux";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import TextArea from "../formComponent/TextArea/TextArea";
import { getCurrentConsultation } from "../../../redux/consultations/actions";
import { useTranslation } from "react-i18next";

const CardData = ({
  formValues,
  submited,
  variant,
  disease,
  specializations,
  diagnostic,
  drugs,
  analysis,
  dirty,
  invalid,
  setCancel,
  setInitalValues,
  resetInitialValues,
  appointments,
  status,
  started,
  setStarted,
  getCurrentConsultation,
}) => {
  const { t } = useTranslation();

  const [initialConsultData, setInitialConsultData] = useState();

  useEffect(() => {
    if (status === "new" || status === "in_process") {
      getCurrentConsultation(
        localStorage.getItem("currentConsultationId")
      ).then((res) => {
        setInitialConsultData({
          complaintsText:
            res.currentConsultation && res.currentConsultation.complaintsText,
          currentAnamnesisText:
            res.currentConsultation &&
            res.currentConsultation.currentAnamnesisText,
          allergicText:
            res.currentConsultation && res.currentConsultation.allergicText,
          pastIllnessText:
            res.currentConsultation && res.currentConsultation.pastIllnessText,
          treatmentPlanText:
            res.currentConsultation &&
            res.currentConsultation.treatmentPlanText,
          conclusionText:
            res.currentConsultation && res.currentConsultation.conclusionText,
          disease: res.currentConsultation && res.currentConsultation.disease,
          specializations: [],
          diagnostic:
            res.currentConsultation && res.currentConsultation.diagnostic,
          analysis: res.currentConsultation && res.currentConsultation.analysis,
          drugs: res.currentConsultation && res.currentConsultation.drugs,
        });
      });
    } else {
      getCurrentConsultation(
        localStorage.getItem("currentConsultationId")
      ).then((res) => {
        setInitialConsultData({
          complaintsText:
            res.currentConsultation && res.currentConsultation.complaintsText,
          currentAnamnesisText:
            res.currentConsultation &&
            res.currentConsultation.currentAnamnesisText,
          allergicText:
            res.currentConsultation && res.currentConsultation.allergicText,
          pastIllnessText:
            res.currentConsultation && res.currentConsultation.pastIllnessText,
          treatmentPlanText:
            res.currentConsultation &&
            res.currentConsultation.treatmentPlanText,
          conclusionText:
            res.currentConsultation && res.currentConsultation.conclusionText,
          disease: res.currentConsultation && res.currentConsultation.disease,
          specializations: [
            res.currentConsultation && res.currentConsultation.specialty,
          ],
          diagnostic:
            res.currentConsultation && res.currentConsultation.diagnostic,
          analysis: res.currentConsultation && res.currentConsultation.analysis,
          drugs: res.currentConsultation && res.currentConsultation.drugs,
        });
      });
    }
    resetInitialValues();
  }, []);

  useEffect(() => {
    setInitalValues({ ...initialConsultData, ...formValues });
  }, [initialConsultData]);

  useEffect(() => {
    if (status === "in_process") {
      setStarted(true);
    }
  }, [status, started]);

  // useEffect(() => {
  //   setInitalValues();
  // }, []);
  // console.log("=>>>>> CardData <<<<<<=");

  // console.log({ started });

  return (
    <div className={"buttonsAndInputsArea"}>
      <Field
        name={"complaintsText"}
        component={TextArea}
        validate={[required]}
        placeholder={t("complaints")}
        label={
          formValues.complaints === "" || formValues.complaints === undefined
            ? ""
            : t("complaints")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <Field
        name={"currentAnamnesisText"}
        component={TextArea}
        placeholder={t("currentDiseaseHistory")}
        label={
          formValues.currentAnamnesis === "" ||
          formValues.currentAnamnesis === undefined
            ? ""
            : t("currentDiseaseHistory")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <Field
        name={"allergicText"}
        component={TextArea}
        placeholder={t("allergicDiseases")}
        label={
          formValues.allergicDisorder === "" ||
          formValues.allergicDisorder === undefined
            ? ""
            : t("allergicDiseases")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <Field
        name={"pastIllnessText"}
        component={TextArea}
        placeholder={t("medicalHistory")}
        label={
          formValues.previousDiseases === "" ||
          formValues.previousDiseases === undefined
            ? ""
            : t("medicalHistory")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <Field
        name={"treatmentPlanText"}
        component={TextArea}
        placeholder={t("treatmentPlan")}
        label={
          formValues.treatmentPlanning === "" ||
          formValues.treatmentPlanning === undefined
            ? ""
            : t("treatmentPlan")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <Field
        name={"conclusionText"}
        component={TextArea}
        validate={[required]}
        placeholder={t("conclusion")}
        label={
          formValues.conclusion === "" || formValues.conclusion === undefined
            ? ""
            : t("conclusion")
        }
        disabled={status === "cancelled" || status === "closed"}
      />
      <div className="buttonMultiChoiceArea">
        <Field
          name="disease"
          component={FilterSelect}
          optionAsList={!!(formValues && formValues.disease)}
          placeholder={t("presumptiveDiagnosis")}
          options={disease}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={formValues && formValues.disease}
          previewIcon={DiagnosisActive}
          disabled={status === "cancelled" || status === "closed"}
          //   disabled={submited ? true : false}
          disabledIcon={DiagnosisDisActive}
          apiSearchURL="/admin/appointments/card/disease?filter[title]="
        />
      </div>
      <div className="recommend">{t("recommendations")}</div>
      <div className="buttonMultiChoiceArea">
        <Field
          name="specializations"
          component={FilterSelect}
          optionAsList={!!(formValues && formValues.specializations)}
          placeholder={t("consultationWithDoctor")}
          options={specializations.map((spec) => ({
            imgUrl: spec.imageUrl,
            title: spec.title,
            id: spec.id,
          }))}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={formValues && formValues.specializations}
          previewIcon={FamilyDoctorActive}
          disabled={status === "cancelled" || status === "closed"}
          //   disabled={submited ? true : false}
          disabledIcon={FamilyDoctorDisActive}
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <Field
          name="diagnostic"
          component={FilterSelect}
          optionAsList={!!(formValues && formValues.diagnostic)}
          placeholder={t("examinations")}
          options={diagnostic}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={formValues && formValues.diagnostic}
          previewIcon={ExaminationsActives}
          disabled={status === "cancelled" || status === "closed"}
          //   disabled={submited ? true : false}
          disabledIcon={ExaminationsDisActives}
          apiSearchURL="/admin/appointments/card/diagnostic?filter[title]="
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <Field
          name="analysis"
          component={FilterSelect}
          optionAsList={!!(formValues && formValues.analysis)}
          placeholder={t("tests")}
          options={analysis}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={formValues && formValues.analysis}
          previewIcon={LabsActive}
          disabled={status === "cancelled" || status === "closed"}
          //   disabled={submited ? true : false}
          disabledIcon={LabsDisActive}
          apiSearchURL="/admin/appointments/card/analysis?filter[title]="
        />
      </div>
      <div className="buttonMultiChoiceArea">
        <Field
          name="drugs"
          component={FilterSelect}
          optionAsList={!!(formValues && formValues.drugs)}
          placeholder={t("medications")}
          options={drugs}
          withSearch
          withIcon
          icon={plus}
          multiple={true}
          withPreview={formValues && formValues.drugs}
          previewIcon={PreparationsActive}
          disabled={status === "cancelled" || status === "closed"}
          //   disabled={submited ? true : false}
          disabledIcon={PreparationsDisActive}
          apiSearchURL="/admin/appointments/card/drugs?filter[title]="
        />
      </div>
      <Button
        className="finishForClientActive"
        text={t("saveButton")}
        type="submit"
        disabled={
          (!formValues.complaintsText && !formValues.conclusionText) ||
          // status === "new" ||
          status === "cancelled" ||
          status === "closed" ||
          !started
        }
      />
      <div className={"saveCancelBtns"}>
        {/* <Button type="submit" className={"save"} text={"Сохранить"} /> */}
        <Button
          className={"cancel"}
          type="button"
          text={t("cancelButton")}
          onClick={() => {
            const confirm = window.confirm(t("cancelConfirm"));
            if (confirm) {
              setCancel(true);
            }
          }}
          disabled={status === "cancelled" || status === "closed"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, monitoring }) => ({
  // patientCard: patients && patients.patient,
  // endOfConsultationFlag: patients && patients.endOfConsultation,
  appointments: patients && patients.appointments,
  status:
    monitoring &&
    monitoring.currentConsultation &&
    monitoring.currentConsultation.status,
});

export default connect(mapStateToProps, {
  setInitalValues,
  getCurrentConsultation,
  resetInitialValues,
})(CardData);
