import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getAllEmployees: (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/employees${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  getAllDoctors: async (numberOfPage, filterString, perPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/doctors?${filterString ? filterString : ""}${
        numberOfPage ? `page=${numberOfPage}` : ""
      }${perPage ? `&perPage=${perPage}` : ""}`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  getAllOperators: (numberOfPage) => {
    let config = {
      mathod: "GET",
      baseURL: `${TEST_API}/admin/employees?filter[role]=ROLE_OPERATOR${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  createOperator: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/employees`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  updateEmployee: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/employees/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  deleteEmployee: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/employees/${id}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  uploadPhoto: (id, file) => {
    let formData = new FormData();
    formData.append("photoFile", file);
    return axios
      .post(`${TEST_API}/admin/employees/${id}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  createDoctor: async (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/doctors`,
      headers: setHeader(),
      data,
    };

    const res = await axios(config);
    return res && res.data;
  },

  updateDoctor: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/doctors/${id}`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  deleteDoctor: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/doctors/${id}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  sendNotificationToCall: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/devices/push`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },
  getDoctorBySearch: (fullName) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/search/all?q=${fullName}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  toLockEmployee: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/employees/${id}/block`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  toUnlockEmployee: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/employees/${id}/unblock`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  uploadFromFile: (file) => {
    let formData = new FormData();
    formData.append("employeeFile", file);
    return axios
      .post(`${TEST_API}/admin/employee/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  getInsuranceCompanies: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/companies/insurance`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
};
