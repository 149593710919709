import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, getFormValues, reduxForm, Field } from "redux-form";
import { useTranslation } from "react-i18next";

import Button from "../../components/ui/Button/Button";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import shevroRight from "../../../style/icons/ShevronRight/shevron.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";

import { notNegative, voidRequired } from "../../../helpers/validate";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";

import "./newSpecializationForm.scss";

import Input from "../../components/formComponent/Input/Input";
import TextArea from "../../components/formComponent/TextArea/TextArea";
import Checkbox from "../../components/formComponent/Checkbox/Checkbox";
import {
  editSpecialization,
  addSpecialization,
  getAllSpecializations,
  deleteSpecializations,
  setPagination,
} from "../../../redux/specializations/actions";
import api from "../../../api";

const NewSpecializationForm = ({
  edit,
  invalid,
  setEditingOff,
  resetInitialValues,
  setInitalValues,
  initialValues,
  handleSubmit,
  editSpecialization,
  addSpecialization,
  reset,
  formValues,
  getAllSpecializations,
  deleteSpecializations,
  blob,
  setBlob,
  addingImg,
  setAddingImg,
  current_page,
  filters,
  setFilters,
  setPagination,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  const closeEditing = () => {
    setDeleting(false);
    resetInitialValues();
    reset();
    setEditingOff();
  };

  const formSubmit = (e) => {
    e.preventDefault();
    console.log(deleting);
    handleSubmit((values) => {
      if (edit && !deleting) {
        editSpecialization(values).then((res) => {
          console.log("succes edit", res);
          // getAllSpecializations(10, current_page);
          setPagination(current_page);
        });
        if (blob) {
          api.specializations
            .uploadFile(values.id, blob)
            .then((res) => {
              console.log("succes photo edit", res);
              // getAllSpecializations(10, current_page && current_page);
              setPagination(current_page);
            })
            .catch(() => console.error("ERROR_MESSAGE"));
          setBlob(null);
        }
        closeEditing();
      }
      if (edit && deleting) {
        console.log("deleting");
        deleteSpecializations(values.id).then((res) => {
          console.log(res);
          setFilters(filters.filter((el) => el.id !== values.id));
          // getAllSpecializations(10, current_page && current_page);
          setPagination(current_page);
        });
        closeEditing();
      }
      if (!edit) {
        addSpecialization(values).then(async ({ entity: { id }, entity }) => {
          setFilters([...filters, entity]);
          if (blob) {
            await api.specializations
              .uploadFile(id, blob)
              .then((res) => {
                console.log("succes add", res);
              })
              .catch(() => console.error("ERROR_MESSAGE"));
            setBlob(null);
          }
          // getAllSpecializations(10, current_page && current_page);
          setPagination(current_page);
        });
        closeEditing();
      }
    })();
  };

  return (
    <div className="wrappNewSpecializationForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit ? t("edit") : t("newSpecialty")} `}
        {edit && (
          <img
            src={close}
            alt="arrow-icon"
            onClick={() => {
              setEditingOff();
              resetInitialValues();
            }}
          />
        )}
      </div>
      <Form className="newSpecializationForm" onSubmit={formSubmit}>
        <div className="formContent">
          <div className={`addingImg ${addingImg ? "show" : ""}`}>
            <div className="formTitle">
              <img
                src={arrowLeft}
                alt="arrow-icon"
                onClick={() => {
                  setAddingImg(false);
                  setEditingOff();
                }}
              />
              {t("addImage")}
            </div>
            <div className="photoUploadFieldContainer">
              <Field
                name="photo"
                // validate={[voidRequired]}
                component={FileUploader}
                previewTrashIcon={trash}
                close={() => setAddingImg(false)}
                editingImgUrl={
                  initialValues &&
                  initialValues.photo &&
                  initialValues.photo.cropImg
                }
                setBlob={setBlob}
                setEditingOff={setEditingOff}
                t={t}
              />
            </div>
          </div>

          {formValues && formValues.photo && formValues.photo.cropImg ? (
            <div className="specAvatar">
              <div className="flex alignCenter">
                <img src={formValues.photo.cropImg} alt="avatar-icon" />
                <div className="fileName">
                  {formValues && formValues.photo && formValues.photo.fileName}
                </div>
              </div>
              <div
                className="trashIcon"
                onClick={() => {
                  reset();
                  setInitalValues({
                    ...formValues,
                    photo: null,
                  });
                }}
              >
                <img src={trash} alt="" />
              </div>
            </div>
          ) : (
            <Button
              secondary
              withIcon
              icon={plus}
              shevron={shevroRight}
              type="button"
              className="btn"
              text={t("addButton")}
              onClick={() => setAddingImg(true)}
            />
          )}

          {/* <Field
            name="title"
            component={FilterSelect}
            placeholder="Специализация"
            options={specializations}
            withSearch
            withIcon
            icon={plus}
            withPreview={formValues && formValues.title}
            previewIcon={docAvatar}
            initialValues={formValues && formValues.title}
          /> */}

          <Field
            validate={[voidRequired]}
            name="title"
            component={Input}
            placeholder={t("title")}
          />

          <Field
            name="priceOnline"
            type="number"
            validate={[voidRequired, notNegative]}
            component={Input}
            placeholder={t("priceOnline")}
          />

          <Field
            name="priceHome"
            validate={[voidRequired, notNegative]}
            type="number"
            component={Input}
            placeholder={t("priceHome")}
          />

          <Field
            // validate={[voidRequired]}
            name="description"
            placeholder={t("specializationDescription")}
            component={TextArea}
          />

          <Field
            validate={[voidRequired, notNegative]}
            name="priority"
            placeholder={t("priority")}
            type="number"
            component={Input}
          />

          <Field
            id={"viewMainScreen"}
            name="viewMainScreen"
            component={Checkbox}
            label={t("showOnMainScreen")}
          />

          <Field
            id={"viewCategoryList"}
            name="viewCategoryList"
            component={Checkbox}
            label={t("showOnDoctorsList")}
          />
        </div>
        <div className={`formFooter ${edit ? "edit" : ""}`}>
          {!edit ? (
            <Button
              className="btn"
              disabled={invalid}
              type="submit"
              text={t("addButton")}
            />
          ) : (
            <>
              <Button
                className="btn"
                // disabled={invalid && !edit}
                type="submit"
                text={t("saveButton")}
              />
              <Button
                className="btn"
                danger
                // disabled={invalid && !edit}
                type="submit"
                text={t("deleteButton")}
                onClick={() => setDeleting(true)}
              />
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  editSpecialization,
  addSpecialization,
  getAllSpecializations,
  deleteSpecializations,
  setPagination,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("specialization")(state),
  initialValues: state.formInitialValues,
  current_page: state.specialization && state.specialization.current_page,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "specialization", enableReinitialize: true })
);

export default enhance(NewSpecializationForm);
