import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getFormValues } from "redux-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";

import {
  getAllConsultations,
  getFilteredConsultations,
  setFilterMode,
  setPagination,
} from "../../../redux/consultations/actions";
import { clearPatient, getPatientCard } from "../../../redux/patients/actions";

import Button from "../ui/Button/Button";

import plus from "../../../style/icons/PlusInCircle/plus-white.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import call from "../../../style/icons/Call/call.svg";
import camera from "../../../style/icons/Camera/camera.svg";
import msg from "../../../style/icons/Message/message.svg";
import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import doc from "../../../style/icons/Doc/doc-blue.svg";

import { searchByFilter } from "../../../redux/searchByFilter/actions";

import {
  consultationDate,
  isActionType,
  statusIs,
} from "../../../helpers/functions";

import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";

import "./consultateList.scss";

const ConsultateList = ({
  loading,
  setLoading,
  getAllConsultations,
  getFilteredConsultations,
  getPatientCard,
  items = [],
  formValues = {},
  role,
  page_count,
  current_page,
  filterMode = "all",
  filterItemsCount = {
    countAll: "",
    countToday: "",
    countTomorrow: "",
    countWeek: "",
  },
  consultList = [],
  monitoring,
  setFilterMode,
  setPagination,
  history,
  clearPatient,
  monitoringFilterValues,
  isItemsFiltered,
  filterParams,
  filter_params,
  trigger_count
}) => {
  const { t } = useTranslation();

  const [dateList, setDateList] = useState({});
  const [sortDateArr, setSortDateArr] = useState([]);

  const actionType = {
    0: msg,
    1: call,
    2: camera,
    3: "TYPE_HOME",
    4: "TYPE_HOSPITAL",
  };

  useEffect(() => {
    clearPatient();

    return function () {
      clearPatient();
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (Object.keys(filterParams).length >= 1) {
      getFilteredConsultations({...filterParams, filterMode, role, isItemsFiltered }, current_page).then(() => setLoading(false));
    } else {
      getAllConsultations(role, filterMode, current_page).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    // setLoading(true);
    setDateList(
      () =>
        items &&
        items.reduce((acc, elem) => {
          let date = elem.start.split("T")[0];

          if (acc[date]) {
            acc[date].push(elem);
          } else {
            acc[date] = [elem];
          }
          return acc;
        }, {})
    );
  }, [items, filterMode]);

  useEffect(() => {
    setSortDateArr(
      () =>
        dateList &&
        Object.keys(dateList).sort(function (a, b) {
          var dateA = new Date(a).getTime(),
            dateB = new Date(b).getTime();
          return dateB - dateA;
        })
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [dateList]);

  const changeViewPeriod = (choisenFilterMode) => {
    setFilterMode(choisenFilterMode)
    setLoading(true);
    if (Object.keys(filterParams).length >= 1) {
      getFilteredConsultations({...filterParams, choisenFilterMode, role, isItemsFiltered }, '1').then(() => setLoading(false));
    } else {
      getAllConsultations(role, choisenFilterMode, '1').then(() => setLoading(false));
    }
  }

  return (
    <div className="consultaleListContainer">
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {items && !loading && (
        <>
          <div className="titleWithFilters">
            <div className="title">{t("monitoring")}</div>
            <div className="filters">
              <div
                className={`filter ${filterMode === "all" ? "active" : ""}`}
                onClick={() => {
                  changeViewPeriod("all");
                }}
              >
                {`${t("filterAll")} ${filterItemsCount.countAll}`}
              </div>
              <div
                className={`filter ${filterMode === "today" ? " active" : ""}`}
                onClick={() => {
                  changeViewPeriod("today");
                }}
              >
                {`${t("filterToday")} ${filterItemsCount.countToday}`}
              </div>
              <div
                className={`filter ${
                  filterMode === "tomorrow" ? "active" : ""
                }`}
                onClick={() => {
                  changeViewPeriod("tomorrow");
                }}
              >
                {`${t("filterTomorrow")} ${filterItemsCount.countTomorrow}`}
              </div>
              <div
                className={`filter ${filterMode === "week" ? "active" : ""}`}
                onClick={() => {
                  changeViewPeriod("week");
                }}
              >
                {`${t("filterWeek")} ${filterItemsCount.countWeek}`}
              </div>
            </div>
          </div>
          <div className="consultationItemsContainer">
            {dateList &&
            dateList &&
            Object.keys(dateList) &&
            Object.keys(dateList).length &&
            sortDateArr &&
            sortDateArr.length ? (
              sortDateArr &&
              sortDateArr.length &&
              sortDateArr.map((el, i) => (
                <div className="consultationPeriod" key={i}>
                  <div className="date">
                    {el && moment(el).format("DD MMMM YYYY")}
                  </div>
                  {dateList &&
                    dateList &&
                    Object.keys(dateList) &&
                    Object.keys(dateList).length > 0 &&
                    dateList[el] &&
                    dateList[el].map((item, index) => (
                      <div
                        key={index}
                        className={`consultationItem`}
                        onClick={() => {
                          history.push(
                            `/home/${role}/client/${item.patient.id}/${item.patientCard.id}/`
                          );
                          localStorage.setItem(
                            "currentConsultationId",
                            item.id
                          );
                          localStorage.setItem(
                            "patientID",
                            item.patient.userId
                          );
                        }}
                      >
                        <div className="id">
                          <div className="title">ID</div>
                          <div className="idInfo">#{item.id}</div>
                        </div>
                        <div className="patient">
                          <div className="title">{t("patient")}</div>
                          <div className="patientInfo">
                            <img
                              src={
                                (item.patientCard &&
                                  item.patientCard.photoUrl) ||
                                patient_active
                              }
                              alt="patient photo"
                            />
                            <div className="details">
                              <span className="name">
                                {item.patientCard && item.patientCard.fullName}
                              </span>
                              {item.patientCard &&
                                item.patientCard.insuranceCompany &&
                                item.patientCard.insuranceCompany.title && (
                                  <span className="insuranceCompanyName">
                                    {item.patientCard.insuranceCompany.title}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                        {(role === "operator" ||
                          role === "insurance-operator") && (
                          <div className="doc">
                            <div className="title">
                              {t("specializationTitle")}
                            </div>
                            <div className="docInfo">
                              <img
                                src={
                                  (item.doctor &&
                                    item.doctor.employee.photoUrl) ||
                                  docIcon
                                }
                                alt="doc-avatar"
                              />
                              <div className="details">
                                <span className="name">
                                  {item.doctor &&
                                    item.doctor.employee &&
                                    item.doctor.employee.fullName}
                                </span>
                                <span className="spec">
                                  {item.specialty && item.specialty.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {role === "doctor" && (
                          <>
                            <div className="cause">
                              <div className="title">{t("cause")}</div>
                              <div className="causeInfo">
                                {(item.anamnesisMonitoring &&
                                  item.anamnesisMonitoring.content) ||
                                  t("empty")}
                              </div>
                            </div>
                            <div className="spec">
                              <div className="title">
                                {t("placeholderSpecialty")}
                              </div>
                              <div className="specInfo">
                                {item.specialty && item.specialty.title}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="status">
                          <div className="title">{t("status")}</div>
                          <div className="statusInfo">
                            <span className="time">
                              {`${moment(item.start).format("HH:mm")}`}
                            </span>
                            <span className={`statusRecord ${item.status}`}>
                              {t(item.status)}
                            </span>
                          </div>
                        </div>
                        <div className="action">
                          <img src={actionType[item.method]} alt="" />
                        </div>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <>
                {role === "operator" && (
                  <div className={"createClientButton"}>
                    <p>{t("emptyList")}</p>
                    <NavLink to={`/home/operator/newClient/`}>
                      <Button
                        className={"btn"}
                        withIcon
                        icon={plus}
                        type="button"
                        text={t("createButton")}
                      ></Button>
                    </NavLink>
                  </div>
                )}
                {role === "doctor" && (
                  <p className="monitoringEmptyText">{t("emptyList")}</p>
                )}
              </>
            )}
          </div>
          {items !== [] && page_count !== 0 && filterMode && dateList && (
            <Paginator
              current_page={current_page}
              setPagination={setPagination}
              setLoading={setLoading}
              filterMode={filterMode}
              page_count={page_count}
              monitoringFilterValues={{
                allOrInWork:
                  filterParams.allOrInWork &&
                  filterParams.allOrInWork.value,
                specialization:
                  filterParams &&
                  filterParams.specialization,
                dateStart:
                  filterParams &&
                  filterParams.dateStart &&
                  filterParams.dateStart,
                dateEnd:
                  filterParams &&
                  filterParams.dateEnd &&
                  filterParams.dateEnd,
                status: filterParams && filterParams.status,
                serviceFormat: filterParams.serviceFormat
              }}
              isItemsFiltered={isItemsFiltered}
              scrollElement="consultationItemsContainer"
              role={role}
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ monitoring, patients, ...state }) => ({
  items: monitoring && monitoring.items,
  filterMode: monitoring && monitoring.filterMode,
  filterItemsCount: monitoring && monitoring.count,
  page_count: monitoring && monitoring.page_count,
  current_page: monitoring && monitoring.current_page,
  monitoringFilterValues: getFormValues("monitoringFilters")(state),
  isItemsFiltered: monitoring && monitoring.isItemsFiltered,
  filter_params: monitoring && monitoring.filter_params,
  trigger_count: monitoring && monitoring.trigger_count
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getAllConsultations,
    getFilteredConsultations,
    getPatientCard,
    setFilterMode,
    setPagination,
    clearPatient,
  })
);

export default enhance(ConsultateList);
