import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import ShowHistory from "./History";
import HistoryItem from "./HistoryItem";

import "./ShowMedCard.scss";

import moment from "moment";
import MedCard from "./MedCard/MedCard";
import Button from "../ui/Button/Button";
import EditAnamnezForm from "./EditAnamnezForm/EditAnamnezForm";

moment.relativeTimeRounding(Math.floor);

const MedCardField = ({ title = "", value = "", last = false }) => {
  return (
    <div className="medcard-field">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
      {!last && <div className="separator"></div>}
    </div>
  );
};

const Anamnez = (props) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  // console.log({ ...props });
  if (
    !props.patients ||
    !props.patients.anamnesis ||
    !Array.isArray(props.patients.anamnesis.items)
  )
    return null;

  return (
    <div className="medcard-info">
      <EditAnamnezForm
        edit={edit}
        setEdit={setEdit}
        fields={props.patients.anamnesis.items}
      />
      {!edit && (
        <Button
          className="btn"
          text={t("editButton")}
          freeTopSpace
          onClick={() => setEdit(true)}
        />
      )}
    </div>
  );
};

const ShowMedCard = ({
  formValues,
  handleSubmit,
  propsRole,
  patientCard,
  ...props
}) => {
  const { t } = useTranslation();

  let TABS_NAMES = [`${t("common")}`];
  if (propsRole === "doctor") TABS_NAMES.push(`${t("anamnez")}`);
  TABS_NAMES.push(`${t("history")}`);

  const [tab, setTab] = useState(0);
  const [historyItem, setHistoryItem] = useState(undefined);

  if (historyItem) {
    return (
      <div className="show-med-card">
        <HistoryItem
          {...props}
          item={historyItem}
          setHistoryItem={setHistoryItem}
        />
      </div>
    );
  }

  return (
    <div className="show-med-card">
      <strong>{t("medCardTitle")}</strong>
      <div className="tabs">
        {TABS_NAMES.map((item, index) => {
          const className = index === tab ? "tab active" : "tab";
          return (
            <div
              className={className}
              key={item}
              onClick={() => {
                patientCard && patientCard.phone ? setTab(index) : setTab(0);
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className="container">
        {tab === 0 && <MedCard propsRole={propsRole} />}
        {tab === 1 && propsRole === "doctor" && <Anamnez {...props} />}
        {((tab === 1 && propsRole !== "doctor") ||
          (tab === 2 && propsRole === "doctor")) && (
          <ShowHistory {...props} setHistoryItem={setHistoryItem} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients }) => ({
  patientCard: patients && patients.patient,
  patients,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShowMedCard);
