import { setHeader, TEST_API } from "../index";
import axios from "axios";

export default {
  getAllConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }${numberOfPage ? `?page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  getFilteredConsultations: async ({
    allOrInWork,
    role = "",
    specialization = {
      id: "",
    },
    dateStart = "",
    dateEnd = "",
    serviceFormat = {
      type: "",
    },
    status = {
      value: "",
    },
    numberOfPage = 1,
    filterMode,
  }) => {
    if (role === "operator" || role === "insurance-operator") {
      const URL =
        filterMode !== "all"
          ? `${TEST_API}/admin/employee/appointment/monitoring${
              role === "insurance-operator" ? "/insurance" : ""
            }?filter[${filterMode}]=true&filter[specialty]=${
              specialization.id
            }&filter[status]=${status.value}&filter[type]=${
              serviceFormat.type
            }${numberOfPage ? `&page=${numberOfPage}` : ""}
      `
          : `${TEST_API}/admin/employee/appointment/monitoring${
              role === "insurance-operator" ? "/insurance" : ""
            }?filter[from]=${dateStart}&filter[to]=${dateEnd}&filter[specialty]=${
              specialization.id
            }&filter[status]=${status.value}&filter[type]=${
              serviceFormat.type
            }${numberOfPage ? `&page=${numberOfPage}` : ""}`;
      let config = {
        method: "GET",
        baseURL: URL,
        headers: setHeader(),
      };

      const res = await axios(config);
      return res && res.data;
    } else if (role === "doctor") {
      let config = {
        method: "GET",
        baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[${filterMode}]=true&filter[from]=${dateStart}&filter[to]=${dateEnd}&filter[status]=${
          status.value
        }${numberOfPage ? `&page=${numberOfPage}` : ""}`,
        headers: setHeader(),
      };

      const res = await axios(config);
      return res && res.data;
    }
  },

  getAllDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTodayDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[today]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTodayConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[today]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTomorrowDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[tomorrow]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getTomorrowConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[tomorrow]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getWeekDoctorConsultations: (numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/doctor/appointment/monitoring?filter[week]=true${
        numberOfPage ? `&page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getWeekConsultations: (role, numberOfPage) => {
    let config = {
      method: "GET",
      /* &filter[status]=new */
      baseURL: `${TEST_API}/admin/employee/appointment/monitoring${
        role === "insurance-operator" ? "/insurance" : ""
      }?filter[week]=true${numberOfPage ? `&page=${numberOfPage}` : ""}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  createConsultationAnamnesis: ({ appointment_id, data }) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${appointment_id}/anamnesis`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  createConsultation: (id, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/specialty/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  updateConsultation: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/appointments/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  getConsultationAnamnesis: (appointment_id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/appointments/${appointment_id}/anamnesis`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  fillingCard: (id, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/fillCard`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },
  finish: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/done`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
  startConsultation: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/start`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  canselConsultation: (id) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/appointments/${id}/cancel`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getCardOptions: (searchURL, page = 1) => {
    const URL = searchURL
      ? `${TEST_API}${searchURL}&page=${page}`
      : `${TEST_API}/admin/appointments/card/entities`;
    let config = {
      method: "GET",
      baseURL: URL,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  getCurrentConsultation: (id) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/appointments/${id}`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  
  getRandomDoctor: (specialty , type) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/consultations/doctor/specialty/${specialty}/type/${type}`,
      headers: setHeader(),
    };
    
    return axios(config).then((res) => res && res.data);
  }
};
