import * as types from "./types";
import createReduser from "../../utils/createReducer";

const initialState = {
  defaultFilter: "all",
};

const sheduleReducer = createReduser(initialState)({
  [types.GET_SHEDULE]: (
    state,
    {
      filterMode,
      count,
      data: {
        items,
        current_page,
        page_count,
        items_per_page,
        total_item_count,
        ...props
      },
    }
  ) => ({
    ...state,
    items,
    filterMode,
    current_page,
    page_count,
    items_per_page,
    total_item_count,
    isItemsFiltered: false,
    count,
    ...props,
  }),

  [types.SET_FILTER_MODE_SHEDULE]: (state, { filterMode }) => ({
    ...state,
    filterMode,
    isItemsFiltered: false,
  }),

  [types.GET_FILTERED_SHEDULE]: (state, { payload }) => ({
    ...state,
    ...payload,
    ...payload.data,
    isItemsFiltered: true,
  }),
});

export default sheduleReducer;
