import React from "react";
import { Field } from "redux-form";

import {
  voidRequired,
  phoneFormat,
  email,
  notNegative,
  cirillicResist,
} from "../../../../helpers/validate";

import Input from "../../../components/formComponent/Input/Input";
import FileUploader from "../../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import DatePicker from "../../../components/formComponent/DatePicker/DatePicker";
import Button from "../../../components/ui/Button/Button";
import FilterSelect from "../../../components/formComponent/FilterSelect/FilterSelect";
import Selecter from "../../../components/formComponent/Select/Select";

import trash from "../../../../style/icons/Trash/trash.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";
import plus from "../../../../style/icons/PlusInCircle/plus.svg";
import shevroRight from "../../../../style/icons/ShevronRight/shevron.svg";
import docAvatar from "../../../../style/icons/DoctorAvatar/avatar.svg";
import calendar from "../../../../style/icons/Calendar/calendar.svg";
import close from "../../../../style/icons/Close/close-icon.svg";

import TextArea from "../../../components/formComponent/TextArea/TextArea";
import { useTranslation } from "react-i18next";
// import EducationSelect from "../../../components/formComponent/EducationSelect/EducationSelect";

const Doc = ({
  setAddingImg,
  formValues = {},
  addingImg,
  initialValues,
  setInitalValues,
  setEditingOff,
  reset,
  fillType,
  specializations,
  setBlob,
  isBlocked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="commonInfo">{t("common")}</div>
      {addingImg && (
        <div className={`addingImg ${addingImg ? "show" : ""}`}>
          <div className="formTitle">
            <img
              src={arrowLeft}
              alt="arrow-icon"
              onClick={() => {
                setAddingImg(false);
                setEditingOff();
              }}
            />
            {t("addImage")}
          </div>
          <div className="photoUploadFieldContainer">
            <Field
              name="photo"
              // validate={[voidRequired]}
              component={FileUploader}
              previewTrashIcon={trash}
              // uploadReq={() => console.log("action")}
              close={() => setAddingImg(false)}
              editingImgUrl={
                initialValues &&
                initialValues.photo &&
                initialValues.photo.cropImg
              }
              setBlob={setBlob}
              setEditingOff={setEditingOff}
              disabled={isBlocked}
              t={t}
            />
          </div>
        </div>
      )}

      {formValues && formValues.photo && formValues.photo.cropImg ? (
        <div className="specAvatar">
          <div className="flex alignCenter">
            <img
              className="cropPhoto"
              src={formValues.photo.cropImg}
              alt="avatar-icon"
            />
            <div className="fileName">
              {formValues && formValues.photo && formValues.photo.fileName}
            </div>
          </div>
          <div
            className="trashIcon"
            onClick={() => {
              reset();
              setInitalValues({
                ...formValues,
                photo: null,
              });
            }}
          >
            <img src={trash} alt="" />
          </div>
        </div>
      ) : (
        <Button
          secondary
          withIcon
          icon={plus}
          // shevron={shevroRight}
          type="button"
          className="btn"
          text={t("addImage")}
          onClick={() => setAddingImg(true)}
          disabled={isBlocked}
        />
      )}
      <Field
        name="firstName"
        validate={[voidRequired]}
        component={Input}
        placeholder={t("name")}
        disabled={isBlocked}
      />

      <Field
        validate={[voidRequired]}
        name="lastName"
        component={Input}
        placeholder={t("lastName")}
        disabled={isBlocked}
      />

      <Field
        name="patronymic"
        validate={[voidRequired]}
        component={Input}
        placeholder={t("patronymic")}
        disabled={isBlocked}
      />

      <div className="dateOfBith">
        <Field
          validate={[voidRequired]}
          name={`birthDate`}
          inputProps={{
            name: "birthDate",
          }}
          // validate={[required, date, maxDateNow]}
          component={DatePicker}
          // placeholder="--/--/----"
          placeholder={t("birthDate")}
          validDate={{ number: 18, type: "year", period: "isBefore" }}
          disabled={isBlocked}
        />
        <img
          className="closeImg"
          src={close}
          alt="close"
          onClick={async () => {
            await setInitalValues({
              ...formValues,
              birthDate: null,
            });
            let picker = document.getElementsByClassName("form-control");
            for (let i = 0; i < picker.length; i++) {
              const element = picker[i];
              element.value = "";
            }
          }}
        />
        <img className="calendarImg" src={calendar} alt="calendar" />
      </div>

      <Field
        name="sex"
        options={[t("maleSex"), t("femaleSex")]}
        // validate={[voidRequired]}
        component={Selecter}
        placeholder={t("gender")}
        disabled={isBlocked}
      />

      <Field
        name="email"
        validate={[voidRequired, email]}
        component={Input}
        placeholder={t("email")}
        mask={cirillicResist}
        disabled={isBlocked}
      />

      <Field
        name="phone"
        validate={[phoneFormat]}
        component={Input}
        placeholder={t("phoneNumber")}
        disabled={isBlocked}
      />

      <Field
        // validate={[voidRequired]}
        name="description"
        type="number"
        component={TextArea}
        placeholder={t("specializationDescription")}
        disabled={isBlocked}
      />

      <Field
        name="specialty"
        component={FilterSelect}
        validate={[voidRequired]}
        placeholder={t("placeholderSpecialty")}
        options={specializations}
        withSearch
        withIcon
        multiple
        icon={plus}
        withPreview={formValues && formValues.specialty}
        previewIcon={docAvatar}
        disabled={isBlocked}
      />

      <div className="commonInfo">{t("education")}</div>

      <Field
        name="educationType"
        component={Input}
        validate={[voidRequired]}
        placeholder={t("education")}
        disabled={isBlocked}
      />

      <Field
        name="educationSpecialty"
        validate={[voidRequired]}
        component={Input}
        placeholder={t("placeholderSpecialty")}
        disabled={isBlocked}
      />

      <div className="commonInfo">{t("experience")}</div>

      <Field
        name="experience"
        validate={[voidRequired, notNegative]}
        // type="number"
        component={Input}
        placeholder={t("old")}
        disabled={isBlocked}
      />
    </>
  );
};

export default Doc;
