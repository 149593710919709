import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment";

import EditMedCardForm from "../EditMedCardForm";
import Button from "../../ui/Button/Button";
import api from "../../../../api";

import { getPatientRelations } from "../../../../redux/patients/actions";

const MedCard = ({
  patientCard,
  propsRole,
  getPatientRelations,
  relations,
}) => {
  const [edit, setEdit] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getPatientRelations();
  }, []);

  if (!patientCard) return null;

  const getOld = (input_date) => {
    const date = new Date(input_date);
    const date_in_ms = date.getTime();

    const beauty_date = date.toLocaleDateString().replace(/\//gi, ".");
    return `${beauty_date} (${moment(date_in_ms).fromNow(true)})`;
  };

  const deleteCardHandler = (cardId) => {
    if (window.confirm(t("deleteConfirm"))) {
      api.patients.deletePatientCard(cardId).then(() => history.push("/"));
    }
  };

  const MedCardField = ({ title = "", value = "", last = false }) => {
    return (
      <div className="medcard-field">
        <div className="title">{title}</div>
        <div className="value">{value}</div>
        {!last && <div className="separator"></div>}
      </div>
    );
  };

  return (
    <div className="medcard-info">
      <div className="medcard-content">
        {!edit && ( 
          <>
            <MedCardField
              title={t("name")}
              value={patientCard.firstName ? patientCard.firstName : ""}
            />
            <MedCardField
              title={t("lastName")}
              value={patientCard.lastName ? patientCard.lastName : ""}
            />
            <MedCardField
              title={t("patronymic")}
              value={patientCard.patronymic ? patientCard.patronymic : ""}
            />
            <MedCardField
              title={t("birthDate")}
              value={patientCard.birthDate ? getOld(patientCard.birthDate) : ""}
            />
            <MedCardField
              title={t("sex")}
              value={
                (patientCard && patientCard.gender === 0 && t("maleSex")) ||
                (patientCard && patientCard.gender === 1 && t("femaleSex")) ||
                ""
              }
            />
            <MedCardField
              title={t("relations")}
              value={patientCard.relationShip && patientCard.relationShip.title}
            />
            {/* <MedCardField
            title="Компания / страховая"
            value={patientCard.company}
          /> */}
            <MedCardField
              title={t("policeNumber")}
              value={patientCard.policeNumber}
            />
            <MedCardField title={t("email")} value={patientCard.email} />
            <MedCardField
              title={t("phoneNumber")}
              value={patientCard.phone}
              last
            />
          </>
        )}
        {edit && (
          <EditMedCardForm
            setEdit={setEdit}
            patientCard={patientCard}
            relations={relations}
          />
        )}
      </div>

      {!edit && propsRole === "operator" && (
        <div className="medcard-footer">
          <Button
            text={t("editButton")}
            freeTopSpace
            className="btn editCardButton"
            type="button"
            onClick={() => setEdit(true)}
          />
          <Button
            text={t("deleteButton")}
            className="btn deleteCardButton"
            type="button"
            danger
            disabled
            onClick={() => {
              deleteCardHandler(patientCard.id);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ patients }) => ({
  patientCard: patients && patients.patient,
  relations: patients && patients.relations,
  patients,
});

const mapDispatchToProps = { getPatientRelations };

export default connect(mapStateToProps, mapDispatchToProps)(MedCard);
