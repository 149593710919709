import api from "../../api";
import * as types from "./types";

export const getAllPromoCodes = () => (dispatch) => {
  return api.promocodes.getAllPromoCodes().then((res) =>
    dispatch({
      type: types.GET_ALL_PROMO_CODES,
      promocodes: res.data,
    })
  );
};

export const getFilteredPromocodes = (
  status,
  type,
  dateStart,
  dateEnd,
  companyId
) => (dispatch) => {
  return api.promocodes
    .getFilteredPromocodes(status, type, dateStart, dateEnd, companyId)
    .then((res) =>
      dispatch({
        type: types.GET_FILTERED_PROMOCODES,
        promocodes: res.data,
      })
    );
};

export const setPagination = ({
  numberOfPage,
  isItemsFiltered,
  filterParams,
}) => (dispatch) => {
  const { promocodeStatus, promocodeType, dateStart, dateEnd, insuranceCompany } = filterParams;
  if (isItemsFiltered) {
    return api.promocodes
      .getFilteredPromocodes(
        promocodeStatus && promocodeStatus.value,
        promocodeType && promocodeType.value,
        dateStart,
        dateEnd,
        insuranceCompany && insuranceCompany.id,
        numberOfPage
      )
      .then((res) =>
        dispatch({
          type: types.GET_FILTERED_PROMOCODES,
          promocodes: res.data,
        })
      );
  } else {
    return api.promocodes.getAllPromoCodes(numberOfPage).then((res) => {
      return dispatch({
        type: types.GET_ALL_PROMO_CODES,
        promocodes: res.data,
      });
    });
  }
};
