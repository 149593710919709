import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";

import { useTranslation } from "react-i18next";

import {
  getAllEmployees,
  setFilterMode,
  setPagination,
  getDoctorsByFilter,
} from "../../../redux/employees/actions";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";

import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";

import TagsInput from "../TagsInput/TagsInput";
import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";

import "./employeeList.scss";

const EmployeeList = ({
  setEdit,
  employees,
  setInitalValues,
  filterMode,
  getAllEmployees,
  total_item_count,
  countDoctors,
  countOperators,
  setFilterMode,
  items = [],
  page_count,
  setPagination,
  current_page,
  specialities,
  formInitialValues,
  getDoctorsByFilter,
  edit,
  token,
  setAddingImg,
  setBlob,
}) => {
  const [filterTags, setFilterTags] = useState([]);
  const [loading, setLoading] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getAllEmployees(filterMode).then(() => setLoading(false));
    // setFilterTags([]);
    // eslint-disable-next-line
  }, [filterMode]);

  // useEffect(() => {
  //   getAllEmployees(filterMode);
  //   // eslint-disable-next-line
  // }, [token]);

  useEffect(() => {
    if (filterTags && filterTags.length) {
      getDoctorsByFilter(filterTags);
    } else {
      // getAllEmployees(filterMode);
    }
    // eslint-disable-next-line
  }, [filterTags.length]);

  return (
    <>
      <div className="employeeListContainer">
        <div className="titleWithFilters">
          <div className="title">{t("employeesFlowTitle")}</div>

          <div className="filters">
            <div
              className={`filter ${
                employees.filterMode === "all" && items && items.length > 0
                  ? "active"
                  : ""
              }`}
              onClick={() => !loading && setFilterMode("all")}
            >
              {t("filterAll")} {total_item_count}
            </div>
            <div
              className={`filter ${
                employees.filterMode === "doctors" && items && items.length > 0
                  ? "active"
                  : ""
              }`}
              onClick={() => !loading && setFilterMode("doctors")}
            >
              {t("doctors")} {countDoctors}
            </div>
            <div
              className={`filter ${
                employees.filterMode === "operators" &&
                items &&
                items.length > 0
                  ? "active"
                  : ""
              }`}
              onClick={() => !loading && setFilterMode("operators")}
            >
              {t("operators")} {countOperators}
            </div>
          </div>

          <TagsInput
            disabled={
              filterMode !== "doctors" || (items && items.length < 1) || loading
            }
            options={filterMode === "doctors" ? specialities : []}
            onChange={(choise) => !loading && setFilterTags([...choise])}
            placeholder={t("placeholderSpecialty")}
          />
        </div>
        <div
          className={`employeeItemsContainer ${
            items && items.length > 0 ? "" : "empty"
          }`}
        >
          {loading && (
            <div className="absoluteCenter">
              <Spinner />
            </div>
          )}

          {!loading &&
          items &&
          items.length &&
          page_count &&
          items.length >= 1 ? (
            items.map((item) => (
              <div
                key={item.id}
                className={`employeeItem ${
                  edit && item.id === formInitialValues.editingId
                    ? "editItem"
                    : edit
                    ? "editMode"
                    : ""
                }
                ${
                  item.isBlocked || (item.employee && item.employee.isBlocked)
                    ? "locked"
                    : ""
                }`}
              >
                <div className="id">
                  <div className="title">ID</div>
                  <div className="idInfo">#{item.id}</div>
                </div>
                <div className="specialization">
                  <div className="title">
                    {item.doctor || item.specialty
                      ? t("specializationTitle")
                      : t("operator")}
                  </div>
                  <div className="specializationInfo">
                    <img
                      src={
                        item.photoUrl ||
                        (item.employee && item.employee.photoUrl) ||
                        docIcon
                      }
                      alt=""
                    />
                    <div className="details">
                      <span className="name">
                        {item.fullName ||
                          (item.employee && item.employee.fullName)}
                      </span>
                      {((item.doctor && item.doctor.educationSpecialty) ||
                        (item && item.educationSpecialty)) && (
                        <span className="spec">
                          {filterMode === "all"
                            ? item.doctor &&
                              item.doctor.specialty.map((el, i) => (
                                <span key={i}>{`${i >= 1 ? ", " : ""} ${
                                  el.title
                                }`}</span>
                              ))
                            : item.specialty &&
                              item.specialty.map((el, i) => (
                                <span key={i}>{`${i >= 1 ? ", " : ""} ${
                                  el.title
                                }`}</span>
                              ))}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="phone">
                  <div className="title">{t("phone")}</div>
                  <div className="phoneInfo">
                    <div className="phoneNumber">
                      {item.phone ||
                        (item.employee && item.employee.phone) ||
                        ""}
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <img
                    src={specEdit}
                    alt="edit-icon"
                    onClick={() => {
                      setEdit();
                      reset();
                      resetInitialValues();
                      setAddingImg(false);
                      setBlob(null);
                      setInitalValues({
                        ...item,
                        ...item.employee,
                        ...item.doctor,
                        employeeID:
                          (item.employee && item.employee.id) || item.id,
                        doctorId: item.doctor && item.doctor.id,
                        editingId: item.id,
                        fillType: { value: "hand" },
                        employeeType:
                          item.doctor || item.specialty
                            ? { value: "doc" }
                            : { value: "operator" },
                        sex:
                          item.gender === 0 ||
                          (item.employee && item.employee.gender === 0)
                            ? t("maleSex")
                            : t("femaleSex"),

                        photo: {
                          cropImg:
                            item.photoUrl ||
                            (item.employee && item.employee.photoUrl),
                        },
                        insuranceCompany:
                          item &&
                          item.insuranceCompany &&
                          item.insuranceCompany.title,
                      });
                    }}
                  />
                </div>
                {(item.isBlocked ||
                  (item.employee && item.employee.isBlocked)) && (
                  <div className="locker" />
                )}
              </div>
            ))
          ) : (
            <>
              {!loading && (
                <p className="employeeEmptyText">{t("emptyList")}</p>
              )}
            </>
          )}
        </div>
        {items != [] && items.length >= 1 && page_count !== 0 && (
          <Paginator
            current_page={current_page}
            setLoading={setLoading}
            setPagination={setPagination}
            filterMode={filterMode}
            page_count={page_count}
            scrollElement="employeeItemsContainer"
            intervalDisabled
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ employees, formInitialValues, user }) => ({
  employees,
  items: employees && employees.items,
  filterMode: employees.filterMode,
  total_item_count: employees.total_item_count,
  countDoctors: employees.countDoctors,
  countOperators: employees.countOperators,
  countAdmins: employees.countAdmins,
  page_count: employees.page_count,
  current_page: employees.current_page,
  formInitialValues,
  token: user && user.token,
});

export default connect(mapStateToProps, {
  getAllEmployees,
  setInitalValues,
  setFilterMode,
  setPagination,
  getDoctorsByFilter,
})(EmployeeList);
