import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Button from "../ui/Button/Button";
import white_bell from "../../../style/icons/Bell/white-bell-in-blue.svg";
import ShevronRight from "../../../style/icons/ShevronRight/shevron.svg";

import moment from "moment";

import "./NotificationsModal.scss";
import api from "../../../api";
import { useHistory } from "react-router-dom";
import { clearPatient } from "../../../redux/patients/actions";

const NotificationItem = ({ item, closeModal, clearPatient }) => {
  const history = useHistory();

  const calculateDate = () => {
    const now = Date.now();
    const date = new Date(item.created).getTime();
    const diff = (now - date) / 1000;

    const minutes = diff / 60;

    if (minutes < 44) return moment(date).fromNow();
    if (
      new Date(date).toLocaleDateString() !== new Date(now).toLocaleDateString()
    ) {
      const item_date = new Date(date);
      return `${item_date
        .toLocaleTimeString()
        .slice(0, 5)} (${item_date.toLocaleDateString().replace(/\//gi, ".")})`;
    }

    return new Date(date).toLocaleTimeString().slice(0, 5);
  };
  const handleClick = () => {
    console.log({ item });
    console.log("push_item.id", item.id, item.user.id, item.user.patient.id);
    localStorage.setItem("patientID", item.user.id);
    clearPatient();
    if (item.user && item.user.patient) {
      api.patients.getPatientCards(item.user.patient.id).then((response) => {
        const main_card = response.data.items.find(
          (item) => item.relationShip && item.relationShip.id === 1
        );
        if (main_card) {
          // debugger;
          history.push(
            "/home/operator/client/" +
              item.user.patient.id +
              "/" +
              main_card.id +
              "/"
          );
          closeModal();
        } else if (response.data.items.length) {
          // debugger;
          history.push(
            "/home/operator/client/" +
              item.user.patient.id +
              "/" +
              response.data.items[0].id +
              "/"
          );
          closeModal();
        } else {
          // debugger;
          history.push(
            `/home/operator/client/${item.user.patient.id}/${item.user.id}`
          );
        }
      });
    }
  };

  return (
    <div className="notification-item" onClick={handleClick}>
      <div className="type">
        {!item.exist && <img src={white_bell} alt="" />}
      </div>
      <div className="notification-item-content">
        <div className="text">
          <p className="title">{item.text}</p>
          <p className="date">{calculateDate()}</p>
          <p className="id">#{item.id}</p>
        </div>
        <img src={ShevronRight} alt="" />
      </div>
    </div>
  );
};

const NotificationsModal = (props) => {
  const [update, setUpdate] = useState(false);

  let ids = [];
  if (localStorage.getItem("nots-ids")) {
    ids = JSON.parse(localStorage.getItem("nots-ids"));
  }

  const updateStorage = () => {
    let storage_ids = [];
    if (localStorage.getItem("nots-ids")) {
      storage_ids = JSON.parse(localStorage.getItem("nots-ids"));
    }
    const updated = storage_ids.concat(
      props.list
        .filter((item) => !storage_ids.includes(item.id))
        .map((item) => Number(item.id))
    );
    localStorage.setItem("nots-ids", JSON.stringify(updated));
  };

  useEffect(() => {
    setTimeout(() => {
      updateStorage();
      setUpdate(true);
    }, 3000);
    console.clear();
    console.log({ ...props });
    console.log("------------------------------------------", {
      ...(props && props.notifications),
    });
    return () => {
      updateStorage();
    };
  }, []);

  const display_list = props.list.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return (
    <div className="notifications-modal">
      <div className="with-scrollbar modal-content">
        {display_list.map((item) => (
          <NotificationItem
            key={item.id}
            item={{ ...item, exist: ids.includes(item.id) }}
            closeModal={props.closeModal}
            clearPatient={props.clearPatient}
          />
        ))}
      </div>
      {/* <Button className="btn" text="Создать тикет"></Button> */}
    </div>
  );
};

const mapStateToProps = (state) => ({ notifications: state.notifications });

const mapDispatchToProps = { clearPatient };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsModal);
