import React, { useEffect, useState } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import Toggle from "../formComponent/Toggle/Toggle";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import {
  fillingCard,
  canselConsultation,
  getCardDataOptions,
  postConsultationAnamnesis,
  getConsultationAnamnesis,
  finishConsultation,
  getCurrentConsultation,
} from "../../../redux/consultations/actions";

import { compose } from "redux";
import { connect } from "react-redux";

import "./ConsultationCard.scss";

import plusNonActive from "../../../style/icons/PlusInCircle/plus-nonActive.svg";

import Anamnez from "../Anamnez/Anamnez";
import CardData from "../CardData/CardData";

import { getAllSpecializations } from "../../../redux/specializations/actions";
import { useRouteMatch, withRouter } from "react-router-dom";
import {
  cancelOfConsultationFlagSet,
  endOfConsultation,
} from "../../../redux/patients/actions";
import { useTranslation } from "react-i18next";

const ConsultCard = ({
  formValues = {},
  variant,
  handleSubmit,
  chat,
  reset,
  setEditingOff,
  cardId,
  dirty,
  invalid,
  fillingCard,
  getCardDataOptions,
  getAllSpecializations,
  specializations,
  canselConsultation,
  postConsultationAnamnesis,
  getConsultationAnamnesis,
  finishConsultation,
  setInitalValues,
  endOfConsultation,
  history,
  appointments,
  started,
  setStarted,
  cancelOfConsultationFlagSet,
  loading,
  setLoading,
  getCurrentConsultation,
}) => {
  const [options, setOptions] = useState();
  const [cancel, setCancel] = useState();
  const [anamnesis, setAnamnesis] = useState();
  const [status, setStatus] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("currentConsultationId")) {
      getCurrentConsultation(
        localStorage.getItem("currentConsultationId")
      ).then((res) => {
        setStatus(res.currentConsultation.status);
      });
    }
  }, []);

  useEffect(() => {
    if (cancel) {
      setLoading(true);
      canselConsultation({
        id: localStorage.getItem("currentConsultationId"),
      }).then(() => {
        setLoading(false);
        cancelOfConsultationFlagSet(true);
        // setTimeout(() => {
        history.push("/");
        // }, 750);
      });
    }
  }, [cancel]);

  useEffect(() => {
    if (formValues.consultCard && formValues.consultCard.value === "anamnez") {
      setInitalValues({ ...formValues });
    }
    if (formValues.consultCard && formValues.consultCard.value === "cardData") {
      setInitalValues({ ...formValues });
    }
  }, [formValues.consultCard]);

  useEffect(() => {
    getCardDataOptions().then(({ res }) => setOptions(res));
    getAllSpecializations(100);
    getConsultationAnamnesis({
      id: localStorage.getItem("currentConsultationId"),
    }).then(({ res }) => {
      // console.log("currentConsultationAnamnesis");
      res && setAnamnesis(res.data.items);
    });
    // eslint-disable-next-line
  }, []);

  const formSubmit = (e) => {
    e.preventDefault();
    const confirm = window.confirm(t("submitConfirm"));
    handleSubmit((values) => {
      if (confirm) {
        if (!cancel) {
          return fillingCard({
            id: localStorage.getItem("currentConsultationId"),
            data: {
              appointmentCard: {
                complaintsText: values.complaintsText,
                currentAnamnesisText: values.currentAnamnesisText,
                allergicText: values.allergicText,
                pastIllnessText: values.pastIllnessText,
                treatmentPlanText: values.treatmentPlanText,
                conclusionText: values.conclusionText,
                analysis: values.analysis.map((el) => el.id),
                diagnostic: values.diagnostic.map((el) => el.id),
                disease: values.disease.map((el) => el.id),
                drugs: values.drugs.map((el) => el.id),
                specialtyRecommend: values.specializations.map((el) => el.id),
              },
            },
          })
            .then((res) => {
              console.log(res);

              postConsultationAnamnesis({
                appointment_id: localStorage.getItem("currentConsultationId"),
                anamnezField: anamnesis,
                values: values.anamnezField,
              }).then((res) => {
                // if (res && res.code === (200 || 201)) {
                // }
              });

              endOfConsultation(true, formValues.conclusionText);
              finishConsultation({
                id: localStorage.getItem("currentConsultationId"),
              });
              setTimeout(() => {
                history.push("/");
              }, 750);
              // .then((res) => endOfConsultation(true,values.conclusionText));
            })
            .then(() => reset());
          // .catch((err) => {
          //   console.log(err);
          // });
        }
      }
      // endOfConsultation(true,values.conclusionText);
    })();
  };

  const classNameConsultCard =
    chat && chat.mode ? "consultCard fullscreen" : "consultCard";

  return (
    <div className={classNameConsultCard}>
      <p className="medCardTitle">{t("consultationCard")}</p>
      <Form onSubmit={formSubmit}>
        <Field
          name="consultCard"
          component={Toggle}
          options={[
            { value: "anamnez", text: t("anamnez") },
            { value: "cardData", text: t("cardData") },
          ]}
        />
        <div className="consultCartFormContent">
          <div className="formContent">
            {formValues.consultCard &&
              formValues.consultCard.value === "anamnez" && (
                <Anamnez
                  formValues={formValues}
                  anamnezFields={anamnesis}
                  setInitalValues={setInitalValues}
                  status={status}
                />
              )}
            {formValues.consultCard &&
              formValues.consultCard.value === "cardData" &&
              options && (
                <CardData
                  formValues={formValues}
                  disease={options.disease}
                  specializations={specializations}
                  diagnostic={options.diagnostic}
                  analysis={options.analysis}
                  drugs={options.drugs}
                  dirty={dirty}
                  invalid={invalid}
                  cansel={cancel}
                  setCancel={setCancel}
                  // status={status}
                  started={started}
                  setStarted={setStarted}
                />
              )}
          </div>
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  getCardDataOptions,
  getAllSpecializations,
  getConsultationAnamnesis,
  fillingCard,
  canselConsultation,
  postConsultationAnamnesis,
  finishConsultation,
  endOfConsultation,
  cancelOfConsultationFlagSet,
  getCurrentConsultation,
};

const mapStateToProps = ({ patients, chat, ...state }) => ({
  formValues: getFormValues("consultationCardForm")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  appointments: patients && patients.appointments,
  chat,
});

const ConsultationCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "consultationCardForm", enableReinitialize: true }),
  withRouter
);

export default ConsultationCard(ConsultCard);
