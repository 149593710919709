import React, { useEffect, useRef } from "react";
import clearField from "../../../../style/icons/PlusInCircle/field-clear.svg";
import "./input.scss";

const Input = ({
  input,
  disabled,
  placeholder,
  noLabel,
  meta: { touched, error },
  meta,
  icon,
  type,
  label,
  clearInput,
  handleError,
  mask,
  defaultValue,
  time,
}) => {
  const inputEl = useRef(null);

  const clearInputField = () => {
    input.onChange("");
  };

  useEffect(() => {
    if (defaultValue && (!input.value || input.value === "")) {
      input.onChange(defaultValue);
    }
    if (defaultValue && input.value) {
      input.onChange(input.value);
    }
  }, [defaultValue]);

  useEffect(() => {
    // if (input.value === "") {
    //   inputEl.current.style.padding = "15px";
    // } else {
    //   inputEl.current.style.padding = "24px 15px 14px 15px";
    // }
  }, [input.value]);

  return (
    <>
      <div className="formField">
        <div className={touched && error ? "fieldInput error" : "fieldInput"}>
          {!noLabel &&
            placeholder &&
            (meta.active || touched || input.value !== "") &&
            input.value && <label className="inputLabel">{placeholder}</label>}
          {type === "time" && !input.value.length >= 1 && (
            <label className="inputLabel">{placeholder}</label>
          )}
          <input
            type={type || "text"}
            min={time && "00:00"}
            max={time && "23:59"}
            ref={inputEl}
            {...input}
            onBlur={() => {
              if (!input.value) {
                inputEl.current.style.padding = "15px";
              }
            }}
            disabled={disabled}
            placeholder={placeholder}
            // onKeyDown={() => {
            //   inputEl.current.style.padding = "24px 15px 14px 15px";
            // }}
            onKeyUp={mask && ((e) => mask(e, input))}
          />
          {input.value && clearInput && (
            <img
              className="clearInputFieldBtn"
              src={clearField}
              alt="clear-field"
              onClick={clearInputField}
            />
          )}
        </div>
        {touched && error && <p className="formItemError">{error && error}</p>}
        {handleError && <p className="formItemError">{handleError}</p>}
      </div>
    </>
  );
};

export default Input;
