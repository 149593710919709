import * as types from "./type";
import api from "../../api";

export const logIn = ({ token, refreshToken }) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);

    return {
      type: types.LOG_IN,
      token,
    };
  }
};

export const signIn = (credentials) => (dispatch) =>
  api.user.signin(credentials).then((res) => {
    // console.log(res.data);
    if (!res.data.code) {
      dispatch(logIn(res.data));
    } else return res;
  });

export const me = () => (dispatch) =>
  api.user.me().then(({ entity }) => {
    const isLogged = localStorage.getItem("refreshToken");
    const token = localStorage.getItem("token");

    dispatch({
      type: types.GET_USER,
      user: { ...entity, isLogged: isLogged ? true : false, token },
    });
  });

export const setToken = (token) => ({ type: types.SET_REFRESH_TOKEN, token });

export const signOut = () => {
  api.user.logOut().then((res) => console.log(res));
  Object.keys(localStorage).forEach((el) => {
    if (el !== "i18nextLng" && el !== "lang") {
      localStorage.removeItem(el);
    }
  });
  // localStorage.clear();
  return {
    type: types.SIGN_OUT,
  };
};
