import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  items: [],
  mode: false,
};

const chatReducer = createReducer(initialState)({
  [types.GET_CHAT_MESSAGES_TEMPLATES]: (state, { data }) => {
    return {
      ...state,
      ...data,
    };
  },
  [types.SET_FULL_SCREEN_VIDEO_MODE]: (state, { mode }) => {
    return {
      ...state,
      mode,
    };
  },
});

export default chatReducer;
