import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../../redux/сompanies/actions";

import "./CompaniesList.scss";

const CompaniesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useSelector(
    (state) => state && state.companies && state.companies.items
  );

  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const CompanyItem = ({ title }) => (
    <div className="item-container">
      <p>{title}</p>
    </div>
  );

  return (
    <>
      <div className="page-title">
        <span className="title">{t("companies")}</span>
      </div>
      <div className="companiesListContainer">
        {companies &&
          companies.length &&
          companies.map((company, i) => (
            <CompanyItem title={company.title} key={i} />
          ))}
      </div>
    </>
  );
};

export default CompaniesList;
