import * as types from "./types";
import api from "../../api";

export const toggleModal = (openModal = false) => (dispatch) => {
  if (typeof openModal === "boolean") {
    return dispatch({
      type: types.TOGGLE_MODAL,
      openModal,
    });
  }
};

export const addItemToList = (item) => (dispatch) => {
  if (item) {
    return dispatch({
      type: types.ADD_ITEM_TO_LIST,
      item,
    });
  }
};

const list = [
  {
    id: 3,
    title: "Заявка через приложение",
    date: new Date(2020, 9, 2, 14, 43, 15, 0).getTime(),
  },
  {
    id: 2,
    title: "Клиент не подключился к консультации",
    date: new Date(2020, 9, 2, 10, 35, 15, 0).getTime(),
  },
  {
    id: 1,
    title: "Врач не вышел на смену",
    date: new Date(2020, 9, 1, 11, 35, 15, 0).getTime(),
  },
];

export const getNotificationsList = () => (dispatch) => {
  api.notification.getNotificationsList().then((res) => {
    return dispatch({
      type: types.GET_NOTIFICATIONS_LIST,
      list: res && res.data && res.data.items,
    });
  });
};
