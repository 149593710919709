import api from "../../api";
import * as types from "./types";

export const setSubs = () => (dispatch) => {
  return api.subscriptions.getSubs().then((res) =>
    dispatch({
      type: types.GET_ALL_SUBSCRIPTIONS,
      subscriptions: res.data,
    })
  );
};

export const getSubscribers = (id) => (dispatch) => {
  return api.subscriptions.getSubscribers({ id }).then((res) =>
    dispatch({
      type: types.GET_SUBSCRIPTED_USERS,
      ...res.data,
    })
  );
};

export const setPagination = ({id, numberOfPage }) => (dispatch) => {
    return api.subscriptions
      .getSubscribers({id, numberOfPage } )
      .then((res) => {
        return dispatch({
          type: types.GET_SUBSCRIPTED_USERS,
          ...res.data,
          filterMode: "all",
        });
      });
};

export const createSub = (body) => {
  return api.subscriptions.createSub(body).then((res) => setSubs());
};
