import React from "react";
import ShevronRight from "../../../style/icons/ShevronRight/shevron.svg";
import { useTranslation } from "react-i18next";

import moment from "moment";

const History = (props) => {
  const { t } = useTranslation();
  const mounths = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  if (!props.patients || !props.patients.appointments) return null;

  const list = props.patients.appointments.data.items;

  // const list = [
  //   { id: 1, doctor: 'Семейный врач', date: '11 июня' },
  //   { id: 2, doctor: 'Терапевт', date: '16 июня' }
  // ]

  // console.log(props.patients.appointments)

  // const list = messages.filter(item => new Date(item.date_sent * 1000).toLocaleDateString() === date)
  // const item_date = new Date(list[0].date_sent * 1000)
  // `${item_date.getDate()} ${mounths[item_date.getMonth()]}`
  return (
    <div className="show-history">
      {list.map((item) => {
        const item_date = new Date(item.start);
        const date = `${item_date.getDate()} ${mounths[item_date.getMonth()]}`;

        return (
          <div
            className="history-item"
            key={item.id}
            onClick={() => props.setHistoryItem(item)}
          >
            <div className="info">
              <div className="doctor">{`${item.specialty.title} ${moment(
                item.start
              ).format("HH:mm")}`}</div>
              <div>{date}</div>
            </div>
            <img src={ShevronRight} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default History;
