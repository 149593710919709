import moment from "moment";

export const requiredLogin = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : "Введите логин";

export const absentLogin = (values) => {
  console.log(values);
  return "";
};

// let r = /\d{2}\/\d{2}\/\d{4}/gmi;
// console.log(r.test(value));
export const dd_mm_yyyy = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /\d{2}\/\d{2}\/\d{4}/gim.test(value))
    ? undefined
    : "Дата должна быть в формате хх/хх/хххх";

export const inPast = (value) => {
  let today = moment().format("YYYY-MM-DD");
  let vMoment;
  if (value.length > 9) {
    vMoment = value.split(" ")[0];
  }
  // console.log(today, vMoment, moment(vMoment).isBefore(today));
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (value && moment(vMoment).isBefore(today, "day")
      ? undefined
      : "birthDateInFuture")
  );
};

export const phoneFormat = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^\+380\d{3}\d{2}\d{2}\d{2}$/.test(value))
    ? undefined
    : "Номер телефона должен быть в формате +380ХХХХХХХХХ";

export const durationTimeInMin = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  // (value && /^(\d){1,2}$/.test(value))
  (value && /\b([1-9]|[1-5]\d|60)\b/.test(value))
    ? undefined
    : "Количество минут должно быть до 60 мин";

export const timeInMin = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
    ? undefined
    : "Время должно быть в формате 23ч:59 мин";

export const email = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    ? undefined
    : "Неправильный формат e-mail";

export const requiredPassword = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : "Введите пароль";

export const required = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : "Это обязательное поле";

export const voidRequired = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : " ";
export const notNegative = (value) =>
  value === "" ||
  value === null ||
  value === undefined ||
  !(value && /-\d{0,}/gm.test(value))
    ? undefined
    : "Не может быть отрицательным числом";

export const maskTime = (e, input) => {
  if (input.value && input.value.length === 2 && e.keyCode !== 8) {
    input.onChange(`${input.value}:`);
  } else if (input.value && input.value.length === 2 && e.keyCode === 8) {
    input.onChange(input.value.substr(0, 1));
  }
  if (input.value && input.value.length >= 4 && e.keyCode !== 8) {
    input.onChange(input.value.substr(0, 5));
  }
};

export const cirillicResist = (e, input) => {
  if (/\w/.test(e.target.value)) {
    // console.log("ok");
    input.onChange(input.value);
  } else {
    // console.log("neok");
    input.onChange(input.value.substr(input.value.length, 1));
  }
};
