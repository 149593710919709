import React, { useEffect, useState } from "react";
import "./NewCardForm.scss";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  Form,
  getFormValues,
  reduxForm,
  Field,
  SubmissionError,
} from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";

import Button from "../../components/ui/Button/Button";
import closeIcon from "../../../style/icons/Close/close-icon.svg";
import Address from "../../components/formComponent/Address/Address";
import Checkbox from "../../components/formComponent/Checkbox/Checkbox";
import NiceInput from "../../components/formComponent/Input/Input";

import Selecter from "../../components/formComponent/Select/Select";
import InputPhone from "../../components/formComponent/InputPhone/InputPhone";

import { useHistory, useRouteMatch } from "react-router-dom";
import { dd_mm_yyyy, inPast, required } from "../../../helpers/validate";
import { relationIs } from "../../../helpers/functions";
import {
  creteNewPatientCard,
  addNewPatientCard,
  getPatientCards,
  getPatientRelations,
} from "../../../redux/patients/actions";
import { setPopUpSuccess } from "../../../redux/networkError/actions";
import Spinner from "../../components/ui/Spinner/Spinner";
import { useTranslation } from "react-i18next";

import InputDateCleave from "../../components/formComponent/InputDateCleave/InputDateCleave.jsx";
import moment from "moment";

const NewCard = ({
  toClose,
  formValues = {},
  reset,
  dirty,
  invalid,
  handleSubmit,
  creteNewPatientCard,
  resetInitialValues,
  addNewPatientCard,
  setNewCard,
  getPatientCards,
  getPatientRelations,
  setPopUpSuccess,
  relations,
  setInitalValues,
  lang,
  valid,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [localRel, setLocalRel] = useState();
  const [rel, setRel] = useState();
  const [lockSubmit, setLockSubmit] = useState(false);

  useEffect(() => {
    getPatientRelations().then(({ data }) => {
      setLocalRel(data.items.map((el) => el.title));
      setRel(data.items);
    });
    if (localStorage.getItem("numericFromSearch")) {
      setInitalValues({ phone: localStorage.getItem("numericFromSearch") });
    }
    return () => {
      localStorage.removeItem("numericFromSearch");
    };
  }, []);

  useEffect(() => {
    setLockSubmit(false);
  }, [formValues]);

  useEffect(() => {
    if (formValues.principalCard) {
      setLocalRel(localRel.slice().splice(0, 1));
    }
  }, [formValues.principalCard]);

  const match = useRouteMatch();
  const patientId = match.params.patient;
  const history = useHistory();

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit((f_values) => {
      const selectedRel = rel.find((el) => el.title === f_values.relationShip);
      if (f_values.sex === t("maleSex")) {
        f_values.gender = 0;
      } else {
        f_values.gender = 1;
      }
      const { principalCard, sex } = f_values;
      if (!patientId) {
        return creteNewPatientCard({
          user: {
            phone: f_values.phone,
            patientCard: {
              firstName: f_values.firstName,
              lastName: f_values.lastName,
              patronymic: f_values.patronymic,
              email: f_values.email,
              birthDate: f_values.birthDate,
              gender: f_values.gender,
              relationShip: selectedRel.id,
              policeNumber: f_values.policeNumber,
              address: {
                ...f_values.address,
                //  entrance: "entrance",
                // floor: 'floor',
              },
            },
          },
        }).then(({ res }) => {
          setLockSubmit(true);
          // console.log(res);
          if (res.code) {
            setLoading(false);
            for (let key in res.errors) {
              const inputNameWithError = key.split(".").pop();
              throw new SubmissionError({
                [inputNameWithError]: res.errors[key],
              });
            }
            return;
          }
          const { entity } = res;
          // console.log(entity);
          getPatientCards(entity.patientId || patientId);
          setPopUpSuccess({
            response: {
              succes: true,
              status: t("success"),
              data: {
                message: t("successCreatingPatient"),
              },
            },
          });
          setLoading(false);
          // history.push("/");
          // пациент успешно создан
          // reset();
          // resetInitialValues();
          setNewCard && setNewCard(false);
          // document.getElementById('99999').value = '';
        });
      } else {
        return addNewPatientCard(patientId, {
          birthDate: f_values.birthDate,
          gender: f_values.gender,
          policeNumber: f_values.policeNumber,
          firstName: f_values.firstName,
          lastName: f_values.lastName,
          patronymic: f_values.patronymic,
          email: f_values.email,
          phone: f_values.phone,
          relationShip: selectedRel.id,
          address: {
            ...f_values.address,
            //  entrance: "entrance",
            // floor: 'floor',
          },
        }).then(() => {
          setLockSubmit(true);
          setLoading(false);
          getPatientCards(patientId);
          // reset();
          // resetInitialValues();
          setNewCard && setNewCard(false);
        });
      }
    })();
  };

  //   console.log(dirty);
  return (
    <div className={"newCardContainer"}>
      <div className={"newCardHeading"}>
        <div className={"heading"}>{t("newPacient")}</div>
        {setNewCard && (
          <img src={closeIcon} alt="close-icon" onClick={toClose} />
        )}
      </div>
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      {!loading && (
        <Form
          className="newCardFormWrapper"
          onSubmit={formSubmit}
          autoComplete="off"
        >
          <Field
            name="principalCard"
            component={Checkbox}
            label={t("mainCard")}
            id={"principal card"}
          />
          <Field
            name={"phone"}
            // noLabel={true}
            component={InputPhone}
            // localization={localStorage.getItem('lang')}
            placeholder={t("phoneNumber")}
          />

          <Field
            name={"firstName"}
            validate={[required]}
            component={NiceInput}
            placeholder={t("name")}
          />

          <Field
            name={"lastName"}
            component={NiceInput}
            placeholder={t("lastName")}
          />
          <Field
            name={"patronymic"}
            component={NiceInput}
            placeholder={t("patronymic")}
          />
          <Field
            name={`birthDate`}
            validate={[required, inPast]}
            component={InputDateCleave}
            placeholder={t("birthDate")}
          />
          <div className="fieldWrapper">
            <Field
              name="sex"
              validate={[required]}
              options={[t("maleSex"), t("femaleSex")]}
              component={Selecter}
              placeholder={t("gender")}
            />
          </div>
          <div className="fieldWrapper">
            {relations && relations.length && (
              <Field
                name={"relationShip"}
                component={Selecter}
                options={
                  formValues.principalCard
                    ? localRel
                    : relations.map((el) => el.title).filter((el) => el !== "я")
                }
                title={t("relations")}
                withPreview
                placeholder={t("relations")}
              />
            )}
          </div>
          <Field
            name={"address"}
            component={Address}
            placeholder={t("adress")}
            label={t("adress")}
            blur={!formValues.address}
          />
          <Field
            name={"insurance"}
            component={NiceInput}
            placeholder={t("insurance")}
          />
          <Field
            name={"policeNumber"}
            // validate={[required]}
            component={NiceInput}
            placeholder={t("policeNumber")}
          />
          <Field
            name={"email"}
            // validate={[required]}
            component={NiceInput}
            placeholder={t("email")}
          />
          <Button
            text={t("createButton")}
            className={"btn"}
            type="submit"
            disabled={lockSubmit || (!dirty && invalid)}
          />
        </Form>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  creteNewPatientCard,
  addNewPatientCard,
  getPatientCards,
  getPatientRelations,
  setPopUpSuccess,
};

const mapStateToProps = ({ language, ...state }) => ({
  formValues: getFormValues("NewCardForm")(state),
  initialValues: state.formInitialValues,
  relations: state.patients && state.patients.relations,
  lang: language.lang,
});

const NewCardForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewCardForm", enableReinitialize: true })
);

export default NewCardForm(NewCard);
