import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import App from "./routes/index";
import "./i18n";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import "@csstools/normalize.css";

import "./index.scss";

// Sentry.init({
//   dsn:
//     "https://789a0a5aa26c4e6bb55a06dbad3e9006@o340358.ingest.sentry.io/5420010",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
