import { setHeader, TEST_API } from "../index";
import axios from "axios";

export default {
  getAllCompanies: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/divisions`,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
  createCompany: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/divisions`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
};
