import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Field, getFormValues, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  medCardNames,
  // doctors,
  format,
  paymentMode,
  // specializations,
} from "../../../helpers/values";

import {
  actionTypeIs,
  isActionType,
  consultationDate,
} from "../../../helpers/functions";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import patientIcon from "../../../style/icons/Subscription/patient_active.svg";
import plusNonActive from "../../../style/icons/PlusInCircle/plus-nonActive.svg";
import checkSign from "../../../style/icons/CheckSign/CheckSign-green.svg";
import calendar from "../../../style/icons/Calendar/calendar-in-blue.svg";
import speceDoc from "../../../style/icons/FamilyDoctor/FamilyDoctorActive.svg";
import noPayment from "../../../style/icons/NoPayment/noPayment.svg";
import chat from "../../../style/icons/Message/message3.svg";
import call from "../../../style/icons/Call/call2.svg";
import camera from "../../../style/icons/Camera/camera2.svg";
import visitAtHome from "../../../style/icons/Home/home.svg";
import clinic from "../../../style/icons/Clinic/Clinic.svg";

import Spinner from "../ui/Spinner/Spinner";
import Button from "../ui/Button/Button";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import DateTimePicker from "../formComponent/DateTimePicker/DateTimePicker";

import { getAllSpecializations } from "../../../redux/specializations/actions";
import { getDoctorsByFilter } from "../../../redux/employees/actions";
import { getFreeDoctorTime } from "../../../redux/shedule/actions";
import {
  getRandomDoctor,
  canselConsultation,
  createConsultation,
  updateConsultation,
  resetCurrentConsultation,
} from "../../../redux/consultations/actions";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";

import "./NewConsultation.scss";

const Consultation = ({
  formValues = {},
  reset,
  cards = [],
  getAllSpecializations,
  getDoctorsByFilter,
  getFreeDoctorTime,
  handleSubmit,
  createConsultation,
  updateConsultation,
  canselConsultation,
  currentConsultation,
  setInitalValues,
  invalid,
  resetCurrentConsultation,
  resetInitialValues,
  fullScreenMode,
  loading,
  setLoading,
  getRandomDoctor,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState();
  const [execute, setExecute] = useState(false);
  const [time, setTime] = useState({
    day: null,
    hour: null,
  });
  const [specializations, setSpecializations] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [periods, setPeriods] = useState();
  const [cancel, setCancel] = useState(false);
  const [edit, setEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [randomDoctor, setRandomDoctor] = useState({});

  const history = useHistory();

  useEffect(() => {
    getAllSpecializations(50).then((res) =>
      setSpecializations([...(res && res.items)])
    );
    resetCurrentConsultation();

    return function () {
      resetCurrentConsultation();
    };
  }, []);

  useEffect(() => {
    if (formValues && formValues.specialization) {
      getDoctorsByFilter([
        formValues.specialization && formValues.specialization.id,
      ])
        .then(({ data }) => {
          // console.log({ data });
          return setDoctors(
            data.items.map((doctor) => ({
              imgUrl: doctor.employee.photoUrl || docIcon,
              title: doctor.employee.fullName,
              id: doctor.id,
              specialty: doctor.specialty,
            }))
          );
        })
        .then(() => {
          setDoctors((prevState) => {
            prevState.unshift({
              randomDoc: true,
              imgUrl: docIcon,
              specialty: [],
              title: "Любой доктор",
            });
            return prevState;
          });
        });
    }
  }, [formValues && formValues.specialization]);

  useEffect(() => {
    if (
      formValues &&
      formValues.specialization &&
      formValues.doctor &&
      formValues.format
    ) {
      getFreeDoctorTime({
        doctor: formValues.doctor.id,
        specialty: formValues.specialization.id,
        type: 0 /* actionTypeIs(formValues.format.value), */,
      }).then((res) => {
        if (res.entity && res.entity.periods) {
          setPeriods([...(res.entity && res.entity.periods)]);
          setRefresh(false);
        } else {
          setPeriods([]);
          setRefresh(true);
        }
      });
    }
  }, [formValues && formValues.doctor]);

  useEffect(() => {
    if (
      formValues &&
      formValues.specialization &&
      formValues.doctor &&
      formValues.format
    ) {
      if (formValues.doctor.randomDoc) {
        getRandomDoctor(
          formValues.specialization.id,
          formValues.format.num_type
        ).then(({ res }) => {
          setRandomDoctor(res && res.entity && res.entity.doctor);
          if (res.entity && res.entity.periods) {
            setPeriods([...res.entity.periods]);
            setInitalValues({
              ...formValues,
              doctor: {
                ...formValues.doctor,
                id: res.entity.doctor.id,
              },
            });
            setRefresh(false);
          } else {
            setPeriods([]);
            setRefresh(true);
          }
        });
      } else {
        getFreeDoctorTime({
          doctor: formValues.doctor.id,
          specialty: formValues.specialization.id,
          type: 0 /* actionTypeIs(formValues.format.value), */,
        }).then((res) => {
          if (res.entity && res.entity.periods) {
            setPeriods([...(res.entity && res.entity.periods)]);
            setRefresh(false);
          } else {
            setPeriods([]);
            setRefresh(true);
          }
        });
      }
    }
  }, [
    formValues &&
      formValues.specialization &&
      formValues.doctor &&
      formValues.format,
  ]);

  useEffect(() => {
    if (edit && currentConsultation) {
      setInitalValues({
        ...formValues,
        card: {
          imgUrl: currentConsultation.patientCard.photoUrl,
          id: currentConsultation.patientCard.id,
          title: currentConsultation.patientCard.fullName,
        },
        specialization: { ...currentConsultation.specialty },
        doctor: {
          imgUrl: currentConsultation.doctor.employee.photoUrl,
          id: currentConsultation.doctor.id,
          title: currentConsultation.doctor.employee.fullName,
        },
        format: actionTypeIs(isActionType(currentConsultation.method)),
        reservationDate: {
          day: moment(currentConsultation.start).format("YYYY-MM-DD"), //"2020-10-22",
          hours: moment(currentConsultation.start).format("hh:mm"),
        },
        paymentMode: {
          imgUrl: noPayment,
          title: "Не требует оплаты",
        },
      });
    }
  }, [edit, currentConsultation]);

  useEffect(() => {
    if (cards && cards !== []) {
      cards.map((card) => ({
        imgUrl: card.photoUrl || docIcon,
        title: card.fullName,
        id: card.id,
        specialty: card.specialty,
      }));
    }
  }, [cards]);

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (cancel) {
      canselConsultation({
        id: localStorage.getItem("currentConsultationId"),
      }).then(() => {
        // cancelOfConsultationFlagSet(true);
        setLoading(false);
        history.push("/");
      });
      resetInitialValues();
      reset();
      resetCurrentConsultation();
      setPeriods(null);
      return;
    }
    handleSubmit((values) => {
      setTime({
        day: values.reservationDate && values.reservationDate.day,
        hour: values.reservationDate && values.reservationDate.hours,
      });
      if (edit) {
        updateConsultation(localStorage.getItem("currentConsultationId"), {
          appointment: {
            start: moment(values.reservationDate.day)
              .add(values.reservationDate.hours.split(":")[0], "hour")
              .add(values.reservationDate.hours.split(":")[1], "minute")
              .format("YYYY-MM-DD T HH:mm Z")
              .split(" ")
              .join(""),
            doctor: values.doctor.id,
          },
        }).then(() => {
          setLoading(false);
          resetInitialValues();
          reset();
          setEdit(false);
        });
      }
      if (!edit && !cancel) {
        createConsultation(values).then(() => {
          resetInitialValues();
          reset();
          resetCurrentConsultation();
          setPeriods(null);
          // console.log(values);
          setLoading(false);
          setCancel(false);
          setEdit(false);
        });
      }
    })();
    // resetInitialValues();
    // reset();
    setExecute(true);
    setPeriods(null);
    setCancel(false);
    setEdit(false);
    // setInitalValues({});
  };
  // console.log({
  //   edit,
  //   currentConsultation,
  //   specializations,
  //   doctors,
  //   periods,
  //   cancel,
  // });
  const finish = () => {
    setExecute(false);
    setActive(false);
    // setSpecializations(undefined);
    setDoctors(undefined);
    setPeriods(undefined);
    setCancel(false);
    setEdit(false);
    setRandomDoctor({});
  };

  // useEffect(() => {
  //   const values = Object.values(formValues);
  //   function isPositive(number) {
  //     return number !== undefined && number !== null;
  //   }
  //   if (values.every(isPositive) && values.length >= 6) {
  //     setTime({
  //       day: formValues.reservationDate && formValues.reservationDate.day,
  //       hour: formValues.reservationDate && formValues.reservationDate.hours,
  //     });
  //     setActive(true);
  //   }
  // }, [formValues, formValues.reservationDate]);

  const done = execute && (
    <div className={"newConsultationWrapper"}>
      {!loading ? (
        <div className="doneContainer">
          <div className="contentWrapper">
            <img src={checkSign} alt="sign-done" />
            <div className={"created"}>{t("requestCreated")}</div>
            <div className={"doneContent"}>
              {`Консультация назначена на ${consultationDate(time.day)} в ${
                time.hour
              }`}
              {Object.keys(randomDoctor).length && (
                <p>{`Вы записаны к доктору ${
                  randomDoctor.employee && randomDoctor.employee.fullName
                }`}</p>
              )}
            </div>
          </div>
          <div className="buttonWrapper">
            <Button text={t("goodButton")} className={"btn"} onClick={finish} />
          </div>
        </div>
      ) : (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
    </div>
  );

  const fillForm = !execute && (
    <div
      className={`newConsultationWrapper ${
        fullScreenMode ? "withFullScreenVideo" : ""
      }`}
    >
      <div className={"setting"}>{t("registration")}</div>
      <div className="formWrapper">
        <Form onSubmit={formSubmit}>
          <div className={"fieldsWrapper"}>
            <Field
              name="card"
              component={FilterSelect}
              placeholder={t("placeholderMedCard")}
              options={
                cards &&
                cards.map((card) => ({
                  imgUrl: card.photoUrl || patientIcon,
                  title: card.fullName,
                  id: card.id,
                  specialty: card.specialty,
                }))
              }
              withIcon
              icon={plus}
              withPreview
              previewIcon={plus}
              withIconInOptions
              disabled={!(cards && cards.length) || edit}
              disabledIcon={edit ? undefined : plusNonActive}
            />
            <Field
              name="specialization"
              component={FilterSelect}
              placeholder={t("placeholderSpecialty")}
              options={
                specializations &&
                specializations.map((spec, i) => ({
                  imgUrl: spec.imageUrl || patientIcon,
                  title: spec.title,
                  id: spec.id,
                }))
              }
              withIcon
              icon={plus}
              withPreview
              multiple={false}
              previewIcon={plus}
              withIconInOptions
              disabled={
                edit ||
                (formValues && formValues.card === undefined) ||
                formValues === null ||
                !specializations
              }
              disabledIcon={edit ? "" : plusNonActive}
            />
            <Field
              name="doctor"
              component={FilterSelect}
              placeholder={t("doctor")}
              options={doctors && doctors}
              withIcon
              icon={plus}
              withPreview
              previewIcon={plus}
              withIconInOptions
              disabled={
                (formValues && formValues.specialization === undefined) ||
                !doctors
              }
              disabledIcon={edit ? "" : plusNonActive}
            />

            <Field
              name="format"
              component={FilterSelect}
              placeholder={t("filterTypeOfService")}
              options={[
                {
                  value: "TYPE_CHAT",
                  title: t("optionsServiceTypeInChat"),
                  imgUrl: chat,
                  num_type: 0,
                },
                {
                  value: "TYPE_PHONE",
                  title: t("optionsServiceTypeCall"),
                  imgUrl: call,
                  num_type: 0,
                },
                {
                  value: "TYPE_VIDEO",
                  title: t("optionsServiceTypeInVideo"),
                  imgUrl: camera,
                  num_type: 0,
                },
                {
                  value: "TYPE_HOME",
                  title: t("optionsServiceTypeAtHome"),
                  imgUrl: visitAtHome,
                  num_type: 1,
                },
                {
                  value: "TYPE_HOSPITAL",
                  title: t("optionsServiceTypeInHospital"),
                  imgUrl: clinic,
                  num_type: 2,
                },
              ]}
              withIcon
              icon={plus}
              withPreview
              previewIcon={plus}
              withIconInOptions
              disabled={
                edit ||
                (formValues && formValues.doctor === undefined) ||
                !doctors
              }
              disabledIcon={edit ? "" : plusNonActive}
            />
            <Field
              name="reservationDate"
              component={DateTimePicker}
              options={periods || []}
              placeholder={t("bookDate")}
              withIcon
              refresh={refresh}
              icon={plus}
              withPreview={formValues && formValues.reservationDate}
              previewIcon={calendar}
              // disabled={edit || formValues.card === undefined}
              disabled={
                !periods || (formValues && formValues.format === undefined)
              }
              disabledIcon={plusNonActive}
              defaultValue={
                edit &&
                currentConsultation &&
                currentConsultation.start && {
                  day: moment(currentConsultation.start).format("YYYY-MM-DD"), //"2020-10-22",
                  hours: moment(currentConsultation.start).format("HH:mm"),
                }
              }
            />
            <Field
              name="paymentMode"
              component={FilterSelect}
              placeholder={t("paymentType")}
              options={[{ title: t("noPayment"), imgUrl: noPayment }]}
              withIcon
              icon={plus}
              withPreview
              previewIcon={plus}
              withIconInOptions
              disabled={
                edit ||
                (formValues && formValues.reservationDate === undefined) ||
                !periods
              }
              disabledIcon={edit ? "" : plusNonActive}
            />
          </div>
          <div className={"buttonWrapper"}>
            {!edit && formValues && formValues.paymentMode && (
              <>
                <div className="price">
                  <div className="cost">{t("priceTitle")}</div>
                  <div className="cost">0₴</div>
                </div>
                <Button
                  type="submit"
                  danger
                  text={t("arrangeButton")}
                  className={`btn btn-submit ${
                    active ? "active" : "disactive"
                  }`}
                  disabled={invalid}
                />
              </>
            )}
            {currentConsultation && currentConsultation.status === "new" && (
              <>
                {edit && (
                  <Button className="btn" type="submit" text="Сохранить" />
                )}
                {!edit && (
                  <>
                    <Button
                      className="btn btn-change"
                      type="button"
                      text={t("changeButton")}
                      onClick={() => setEdit(true)}
                    />
                    <Button
                      className="btn btn-cancel"
                      danger
                      type="submit"
                      text={t("cancelButton")}
                      onClick={() => setCancel(true)}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
  return (
    <div className={"newConsultationContainer"}>
      {fillForm}
      {done}
    </div>
  );
};
const mapDispatchToProps = {
  setInitalValues,
  getRandomDoctor,
  resetInitialValues,
  getDoctorsByFilter,
  getAllSpecializations,
  getFreeDoctorTime,
  createConsultation,
  updateConsultation,
  canselConsultation,
  resetCurrentConsultation,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("NewConsultationForm")(state),
  initialValues: state && state.formInitialValues,
  specializations: state && state.specialization && state.specialization.items,
  currentConsultation: state.monitoring && state.monitoring.currentConsultation,
  fullScreenMode: state.chat && state.chat.mode,
  cards: state.patients && state.patients.cards && state.patients.cards.items,
});
const NewConsultationCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewConsultationForm", enableReinitialize: true })
);

export default NewConsultationCard(Consultation);
