import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import defaultUserIcon from "../../../style/icons/Subscription/patient_active.svg";

import "./PatientsSearchList.scss";

const PatientsSearchList = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (props.searchKey && Array.isArray(props.list) && props.list.length) {
    return (
      <div className="patients-search-list with-scrollbar">
        {props.list.map((item, index) => {
          return (
            <div
              onClick={
                item.type !== 2
                  ? () => {
                      history.push(
                        `/home/${props.role}/client/${
                          item.patientId || item.id
                        }/${item.patientId ? `${item.id}/` : ""}`
                      );
                      localStorage.setItem(
                        "patientID",
                        item.patientId || item.id
                      );
                    }
                  : null
              }
              key={index}
              className="patients-search-item"
            >
              <img
                src={item.photoUrl ? item.photoUrl : defaultUserIcon}
                alt=""
                className="photo"
              />
              <span className="name">{item.fullName}</span>
              {item.phone && (
                <>
                  <span className="phone gray">{t("phone")}</span>
                  <span className="phone">{item.phone}</span>
                </>
              )}
              {typeof item.type === "undefined" && (
                <span className="type">{t("placeholderMedCard")}</span>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  if (props.searchKey && Array.isArray(props.list)) {
    return <div className="patients-search-list">{Array.isArray(props)}</div>;
  }

  return <></>;
};

const mapStateToProps = (state) => ({
  list: state.patients && state.patients.searchList,
  searchKey: state.patients && state.patients.searchKey,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSearchList);
