import React, { useState, useEffect } from "react";
import EmployeeList from "../../components/EmployeeList/EmployeeList";
import NewEmployeeForm from "../../forms/NewEmployeeForm/NewEmployeeForm";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { connect } from "react-redux";

const Employees = ({ getAllSpecializations }) => {
  const [edit, setEdit] = useState(false);
  const [specialities, setSpecializations] = useState();
  const [blob, setBlob] = useState(null);
  const [addingImg, setAddingImg] = useState(false);

  useEffect(() => {
    getAllSpecializations(50).then(
      (res) => res && res.items && setSpecializations([...res.items])
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="employeeContainer flex justifyBeetwen">
      {specialities && specialities.length >= 1 && (
        <>
          <EmployeeList
            edit={edit}
            setEdit={() => setEdit(true)}
            specialities={specialities}
            setAddingImg={setAddingImg}
            setBlob={setBlob}
          />
          <NewEmployeeForm
            edit={edit}
            setEditingOff={() => setEdit(false)}
            specialities={specialities}
            setAddingImg={setAddingImg}
            addingImg={addingImg}
            setBlob={setBlob}
            blob={blob}
          />
        </>
      )}
    </div>
  );
};

export default connect(null, { getAllSpecializations })(Employees);
