import React from "react";
import moment from "moment";

import "./PromocodesList.scss";

const PromocodesList = ({ items, t }) => {
  return (
    <>
    <div className="promoCodesItemsContainer">
      {items && items.length ? (
        items.map((item, i) => (
          <div key={i} className="promocodesItem">
            <p>
              {t("promocodeName")}: {item.code}
            </p>
            <p>
              {t("promocodeCreated")}: {moment(item.createdAt).format("L")}
            </p>
            <p>
              {t("promocodeExpityDate")}: {moment(item.validTill).format("L")}
            </p>
            <span>{item.type === 0 ? t("promocodeType_0") : t("promocodeType_1")}</span>
          </div>
        ))
      ) : (
        <p className="errorMsg">{t("emptyList")}</p>
      )}
    </div>
    </>);
};

export default PromocodesList;
