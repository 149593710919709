import axios from "axios";
import { setHeader, TEST_API } from "../index";

export default {
  getShedule: async ({ filterMode, numberOfPage }) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/doctors/schedules${
        filterMode
          ? numberOfPage
            ? `?filter[${filterMode}]=true&page=${numberOfPage}`
            : `?filter[${filterMode}]=true`
          : numberOfPage
          ? `?page=${numberOfPage}`
          : ""
      }`,
      headers: setHeader(),
    };
    const res = await axios(config);
    return res && res.data;
  },

  getFilteredShedule: async ({
    specialization = {
      id: "",
    },
    serviceFormat = {
      type: "",
    },
    dateStart = "",
    dateEnd = "",
    filterMode,
  }) => {
    const URL =
      filterMode !== "all"
        ? `${TEST_API}/admin/doctors/schedules?filter[${filterMode}]=true&filter[type]=${serviceFormat.type}&filter[specialty]=${specialization.id}`
        : `${TEST_API}/admin/doctors/schedules?filter[from]=${dateStart}&filter[to]=${dateEnd}&filter[type]=${serviceFormat.type}&filter[specialty]=${specialization.id}`;
    let config = {
      method: "GET",
      baseURL: URL,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  addNewShedule: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/doctors/schedules`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  updateShedule: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/doctors/schedules/${id}`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  getFreeDoctorTime: (doctor, specialty, type) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/consultations/doctor/${doctor}/specialty/${specialty}/type/${type}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  deleteSchedule: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/doctors/schedules/period/${id}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },

  upload: (file) => {
    let formData = new FormData();
    formData.append("scheduleFile", file);
    return axios
      .post(`${TEST_API}/admin/doctors/schedules/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },
};
