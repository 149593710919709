import * as types from "./types";
import api from "../../api";
import moment from "moment";

export const getAllShifts = (filterMode) => (dispatch) => {
  if (filterMode === "all" || filterMode === undefined) {
    return api.shedule.getShedule({}).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode: "all",
      });
    });
  }
  if (filterMode === "today") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "tomorrow") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "week") {
    return api.shedule.getShedule({ filterMode }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
};

export const createShifts = (values) => (dispatch) => {
  return api.shedule
    .addNewShedule({
      doctorSchedule: {
        doctor: values.doctor.id,
        specialty: values.specialty.id,
        period: values.period.selectedDates.map((el, i) => ({
          // id: i,
          type: values.serviceFormat.type,
          duration: +values.duration,
          startDate: moment(`${el}`, "YYYY-MM-DD HH:mm Z")
            .hour(values.startDate.split(":")[0])
            .minute(values.startDate.split(":")[1])
            .format("YYYY-MM-DD T HH:mm Z")
            .split(" ")
            .join("") /* `T${values.startDate}` */,
          endDate: moment(`${el}`, "YYYY-MM-DD HH:mm Z")
            .hour(values.endDate.split(":")[0])
            .minute(values.endDate.split(":")[1])
            .format("YYYY-MM-DD T HH:mm Z")
            .split(" ")
            .join(""),
        })),
      },
    })
    .then((res) => dispatch({ type: "CREATE_NEW_SHIFT", res }));
};

// let a = moment(`${formValues.period.selectedDates[0]}`, "YYYY-MM-DD HH:mm Z")
//   .hour(formValues.startDate.split(":")[0])
//   .minute(formValues.startDate.split(":")[1])
//   .format("YYYY-MM-DD T HH:mm Z")
//   .split(" ")
//   .join("");

export const updateShift = (id, values) => (dispatch) => {
  return api.shedule
    .updateShedule(id, {
      doctorSchedule: {
        doctor: values.scheduleItem.doc.id,
        specialty: values.specialty.id,
        period:
          values.scheduleItem &&
          values.scheduleItem.period &&
          values.scheduleItem.period.map((el) => {
            if (el.id === values.deleteEditPeriodId) {
              return (el = {
                // id: values.deleteEditPeriodId,
                duration: +values.duration,
                type: values.serviceFormat.type,
                startDate: moment(`${values.period}`, "YYYY-MM-DD HH:mm Z")
                  .hour(values.startDate.split(":")[0])
                  .minute(values.startDate.split(":")[1])
                  .format("YYYY-MM-DD T HH:mm Z")
                  .split(" ")
                  .join("") /* `T${values.startDate}` */,
                endDate: moment(`${values.period}`, "YYYY-MM-DD HH:mm Z")
                  .hour(values.endDate.split(":")[0])
                  .minute(values.endDate.split(":")[1])
                  .format("YYYY-MM-DD T HH:mm Z")
                  .split(" ")
                  .join(""),
              });
            }
            // type: values.scheduleItem.type,

            return el;
          }),
      },
    })
    .then((res) => {
      // api.shedule.updateOnlyOneShift(id, {
      //   doctorSchedule: {
      //     doctor: values.doctor.id,
      //     type: values.serviceFormat.type,
      //     specialty: values.specialty.id,
      //     period: {
      //       id: values.deleteEditPeriodId,
      //       startDate: `${values.period.split("T")[0]}T${values.startDate}Z`,
      //       endDate: `${values.period.split("T")[0]}T${values.endDate}Z`,
      //     },
      //   },
      // });
      return dispatch({ type: "UPDATE_SHIFT" });
    });
};

export const filterSet = ({
  filterMode,
  specialization,
  serviceFormat,
  dateStart,
  dateEnd,
}) => (dispatch) => {
  return api.shedule
    .getFilteredShedule({
      specialization,
      serviceFormat,
      dateStart,
      dateEnd,
      filterMode,
    })
    .then((res) =>
      dispatch({
        type: types.GET_FILTERED_SHEDULE,
        payload: { filterMode, ...res },
      })
    );
};

export const deleteShift = (id) => (dispatch) =>
  api.shedule
    .deleteSchedule(id)
    .then((res) => dispatch({ type: "DELETE_SCHEDULE", res })); // TYPE_ONLINE = 0;
// TYPE_HOME = 1;

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_SHEDULE,
    filterMode,
  };
};

export const setPagination = ({
  filterMode,
  numberOfPage,
  isItemsFiltered,
  specialization,
  dateStart,
  dateEnd,
  serviceFormat,
}) => (dispatch) => {
  if (isItemsFiltered) {
    return api.shedule
      .getFilteredShedule({
        specialization,
        serviceFormat,
        dateStart,
        dateEnd,
        filterMode,
      })
      .then((res) =>
        dispatch({
          type: types.GET_FILTERED_SHEDULE,
          payload: { filterMode, ...res },
        })
      );
  }
  if (filterMode === "all" || filterMode === undefined) {
    return api.shedule.getShedule({ numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode: "all",
      });
    });
  }
  if (filterMode === "today") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "tomorrow") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
  if (filterMode === "week") {
    return api.shedule.getShedule({ filterMode, numberOfPage }).then((res) => {
      return dispatch({
        type: types.GET_SHEDULE,
        ...res,
        filterMode,
      });
    });
  }
};

export const getFreeDoctorTime = ({ doctor, specialty, type }) => (
  dispatch
) => {
  return api.shedule
    .getFreeDoctorTime(doctor, specialty, type)
    .then((res) => dispatch({ type: "GET_FREE_DOC_TIME", ...res }));
};
