import * as types from "./types";
import api from "../../api";
import { actionTypeIs } from "../../helpers/functions";
import moment from "moment";

export const getAllConsultations = (role, filterMode, numberOfPage) => (
  dispatch
) => {
  if (role === "doctor") {
    if (
      filterMode === "all" ||
      filterMode === undefined ||
      filterMode === null
    ) {
      return api.consultations
        .getAllDoctorConsultations(numberOfPage)
        .then((res) => {
          dispatch({
            type: types.GET_ALL_CONSULTATIONS,
            ...res,
            filterMode: filterMode || "all",
          });
        });
    }
    if (filterMode === "today") {
      return api.consultations
        .getTodayDoctorConsultations(numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_TODAY_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
    if (filterMode === "tomorrow") {
      return api.consultations
        .getTomorrowDoctorConsultations(numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_TOMORROW_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
    if (filterMode === "week") {
      return api.consultations
        .getWeekDoctorConsultations(numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_WEEK_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
  }
  if (role === "operator" || role === "insurance-operator") {
    if (
      filterMode === "all" ||
      filterMode === undefined ||
      filterMode === null
    ) {
      return api.consultations
        .getAllConsultations(role, numberOfPage)
        .then((res) => {
          // console.log({ res });
          return dispatch({
            type: types.GET_ALL_CONSULTATIONS,
            ...res,
            filterMode: filterMode || "all",
          });
        });
    }
    if (filterMode === "today") {
      return api.consultations
        .getTodayConsultations(role, numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_TODAY_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
    if (filterMode === "tomorrow") {
      return api.consultations
        .getTomorrowConsultations(role, numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_TOMORROW_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
    if (filterMode === "week") {
      return api.consultations
        .getWeekConsultations(role, numberOfPage)
        .then((res) =>
          dispatch({
            type: types.GET_WEEK_CONSULTATIONS,
            ...res,
            filterMode,
          })
        );
    }
  }
};

export const getFilteredConsultations = ({
  allOrInWork,
  specialization,
  dateStart,
  dateEnd,
  serviceFormat,
  status,
  filterMode,
  role,
  isItemsFiltered,
  numberOfPage,
}) => (dispatch) => {
  return api.consultations
    .getFilteredConsultations({
      allOrInWork,
      specialization,
      dateStart,
      dateEnd,
      serviceFormat,
      status,
      role,
      isItemsFiltered,
      numberOfPage,
      filterMode,
    })
    .then((res) =>
      dispatch({
        type: types.GET_FILTERED_CONSULTATIONS,
        payload: { filterMode, ...res },
      })
    );
};

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_MONITORING,
    filterMode,
  };
};

export const setPagination = ({
  role,
  filterMode,
  numberOfPage,
  isItemsFiltered,
  allOrInWork,
  specialization,
  dateStart,
  dateEnd,
  status,
}) => (dispatch) => {
  if (role === "doctor") {
    if (isItemsFiltered) {
      return api.consultations
        .getFilteredConsultations({
          allOrInWork,
          specialization,
          dateStart,
          dateEnd,
          status,
          filterMode,
          role,
          numberOfPage,
        })
        .then((res) =>
          dispatch({
            type: types.GET_FILTERED_CONSULTATIONS,
            payload: { filterMode, ...res },
          })
        );
    } else {
      if (
        filterMode === "all" ||
        filterMode === undefined ||
        filterMode === null
      ) {
        return api.consultations
          .getAllDoctorConsultations(numberOfPage)
          .then((res) => {
            return dispatch({
              type: types.GET_ALL_CONSULTATIONS,
              ...res,
              filterMode: filterMode || "all",
            });
          });
      }
      if (filterMode === "today") {
        return api.consultations
          .getTodayDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TODAY_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "tomorrow") {
        return api.consultations
          .getTomorrowDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TOMORROW_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "week") {
        return api.consultations
          .getWeekDoctorConsultations(numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_WEEK_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
    }
  }
  if (role === "operator" || role === "insurance-operator") {
    if (isItemsFiltered) {
      return api.consultations
        .getFilteredConsultations({
          allOrInWork,
          specialization,
          dateStart,
          dateEnd,
          status,
          filterMode,
          role,
          numberOfPage,
        })
        .then((res) =>
          dispatch({
            type: types.GET_FILTERED_CONSULTATIONS,
            payload: { filterMode, ...res },
          })
        );
    } else {
      if (
        filterMode === "all" ||
        filterMode === undefined ||
        filterMode === null
      ) {
        return api.consultations
          .getAllConsultations(role, numberOfPage)
          .then((res) => {
            return dispatch({
              type: types.GET_ALL_CONSULTATIONS,
              ...res,
              filterMode: filterMode || "all",
            });
          });
      }
      if (filterMode === "today") {
        return api.consultations
          .getTodayConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TODAY_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "tomorrow") {
        return api.consultations
          .getTomorrowConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_TOMORROW_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
      if (filterMode === "week") {
        return api.consultations
          .getWeekConsultations(role, numberOfPage)
          .then((res) =>
            dispatch({
              type: types.GET_WEEK_CONSULTATIONS,
              ...res,
              filterMode,
            })
          );
      }
    }
  }
};

export const createConsultation = (data) => (dispatch) =>
  api.consultations
    .createConsultation(data.specialization.id, {
      appointment: {
        start: moment(data.reservationDate.day)
          .add(data.reservationDate.hours.split("-")[0].split(":")[0], "hour")
          .add(data.reservationDate.hours.split("-")[0].split(":")[1], "minute")
          .format("YYYY-MM-DD T HH:mm Z")
          .split(" ")
          .join(""),
        method: actionTypeIs(data.format.value).num_type,
        paymentType: 2, // 0 or 1 -need to pay, 2 - for free
        patientCard: data.card.id,
        doctor: data.doctor.id,
      },
    })
    .then((res) => dispatch({ type: types.CREATE_CONSULTATION, res }))
    .catch((err) => err);

export const updateConsultation = (id, data) => (dispatch) =>
  api.consultations
    .updateConsultation(id, data)
    .then((res) => dispatch({ type: "UPDATE_APPOINTMENT", res }));

export const fillingCard = ({ id, data }) => (dispatch) =>
  api.consultations.fillingCard(id, data);

export const canselConsultation = ({ id }) => (dispatch) =>
  api.consultations.canselConsultation(id).then((res) => {
    dispatch({ type: types.CANCEL_CONSULTATION });
  });

export const getCardDataOptions = () => (dispatch) =>
  api.consultations
    .getCardOptions()
    .then((res) => dispatch({ type: "GET_DATA_CARD_OPTIONS", res }));

export const getConsultationAnamnesis = ({ id }) => (dispatch) =>
  api.consultations
    .getConsultationAnamnesis(id)
    .then((res) =>
      dispatch({ type: "GET_CONSULTATION_ANAMNESIS_QUESTIONS", res })
    );

export const postConsultationAnamnesis = ({
  appointment_id,
  anamnezField,
  values,
}) => (dispatch) => {
  let formData = {};
  anamnezField.map((el, i) => {
    formData[el.id] = values[el.id];
  });
  return api.consultations.createConsultationAnamnesis({
    appointment_id,
    data: {
      anamnesis: {
        ...formData,
      },
    },
  });
};

export const finishConsultation = ({ id }) => (dispatch) =>
  api.consultations
    .finish(id)
    .then(() => dispatch({ type: "FINISH_CONSULTATION" }));

export const getCurrentConsultation = (id) => (dispatch) =>
  api.consultations.getCurrentConsultation(id).then((res) =>
    dispatch({
      type: types.GET_CURRENT_CONSULTATION,
      currentConsultation: res && res.entity,
    })
  );

export const resetCurrentConsultation = () => ({
  type: types.RESET_CURR_CONSULTATION,
});

export const getRandomDoctor = (specialty, type) => (dispatch) =>
  api.consultations
    .getRandomDoctor(specialty, type)
    .then((res) => dispatch({ type: "GET_RANDOM_DOCTOR", res }));

// export const createConsultation = (id, values) => (dispatch) =>
//   api.consultations
//     .createConsultation(id, data)
//     .then((res) => dispatch({ type: "CREATE_NEW_CONSULTATION", res }));
