import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const resources = {
  ru: {
    translation: {
      createPassword: "Установите пароль для вашего аккаунта",
      month: "Месяца",
      newSubscription: "Новая подписка",
      percentileDiscount: "Процент скидки",
      priceDiscount: "Цена подписки",
      company: "Компания",
      subscriptionCreated: "Подписка создана",
      activateSubscription: "Активировать подписку",
      addPatient: "Добавить пациента",
      isAdmin: "Администратор компании",
      //sendPDFFile
      exportButton: "Экспортировать",
      sendToEmail: "Отправить на эл. почту",
      sendPDF:
        "Пожалуйста, выберите страховую компанию, куда желаете отправить файл",
      sendSuccess: "Успешно отправлено",
      //Companies
      companies: "Компании",
      createCompany: "Создать компанию",
      //Success
      successOperatorUpdate: "Оператор успешно обновлён",
      successDoctorUpdate: "Доктор успешно обновлён",
      successOperatorDelete: "Оператор успешно удалён",
      successDoctorDelete: "Доктор успешно удалён",
      successBlockUser: "Сотрудник успешно заблокирован",
      successUnblockUser: "Сотрудник успешно разблокирован",
      successOperatorCreate: "Оператор успешно создан",
      successDoctorCreate: "Доктор успешно создан",
      // ERRORS
      submitConfirm:
        "Вы уверены, что хотите сохранить изменения и завершить текущую консультацию?",
      cancelConfirm:
        "Вы уверены, что хотите закрыть и отменить текущую консультацию?",
      birthDateInFuture: "Невозможно родиться в будущем",
      templateTitle: "Шаблоны",
      min: "мин",
      from: "с",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону",
      medQuestion: "Мед. вопрос",
      techSupport: "Техподдержка",
      infoMedQueation: "B случае, если хотите уточнить медицинский вопрос",
      missedCall: "Пропущенный звонок",
      canceledCall: "Отменённый вызов",
      canceledConsultationMessage:
        "Уважаемый пациент, ваша консультация была отменена врачом.",
      finishConsultationMessage:
        "Ваша консультация завершена. Заключение врача: ",
      //Sider
      shifts: "Расписание",
      employees: "Сотрудники",
      specializations: "Специализации",
      subscriptions: "Подписки",
      rates: "Тарифы",
      patients: "Пациентов",
      promocodes: "Промокоды",
      onboarding: "Онбоардинг",
      client: "Клиент",
      //=====
      //MODAL
      helpModalText_1:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону:",
      helpModalText_2: "В случае, если хотите уточнить медицинский вопрос:",
      techSupportButton: "Техподдержка",
      medQuestionButton: "Мед. вопрос",
      help: "Помощь",
      //NetworkErrorPopup
      errorCode: "Ошибка. Код:",
      errorDescription: "Описание ошибки:",
      //UserTool
      notifications: "Уведомления",
      //Validate
      addressValidate: "Все поля с * должны быть заполнены",
      //Address Component
      address: "Адрес",
      city: "Город",
      district: "Район",
      street: "Улица",
      building: "Дом",
      apartment: "Квартира",
      codeOrNote: "Код или заметка",
      //СardData
      noPayment: "Не требует оплаты",
      currentDiseaseHistory: "Анамнез текущего заболевания",
      allergicDiseases: "Аллергические заболевания",
      medicalHistory: "Перенесённые заболевания",
      treatmentPlan: "План лечения",
      recommendations: "Рекомендации",
      //Client
      warning: "Внимание!",
      consultationIsNotPayed: "Косультация не была оплачена",
      consultationCard: "Карта консультации",
      cardData: "Данные карты",
      //HistoryItemDetails
      consultationDetails: "Детали консультации",
      onlineConsultation: "Онлайн консультация",
      consultationWithDoctor: "Консультация с врачом",
      complaints: "Жалобы",
      presumptiveDiagnosis: "Предположительный диагноз (по МКБ-10)",
      TreatmentPlan: "План лечения",
      conclusion: "Вывод",
      medications: "Препараты",
      examinations: "Инструментальные исследования",
      tests: "Анализы",
      //History
      january: "января",
      february: "февраля",
      march: "марта",
      april: "апреля",
      may: "мая",
      june: "июня",
      july: "июля",
      august: "августа",
      september: "сентября",
      october: "октября",
      november: "ноября",
      december: "декабря",
      //Anamnez
      medCardTitle: "Медицинская карта",
      anamnez: "Анамнез",
      history: "История",
      deleteConfirm: "Вы уверены, что хотите удалить карту?",
      //MedCard
      sex: "Пол",
      maleSex: "Мужской",
      femaleSex: "Женский",
      //Monitoring
      monitoring: "Мониторинг",
      patient: "Пациент",
      cause: "Причина",
      status: "Статус",
      new: "Новая",
      newConsultation: "Новая",
      filled: "Заполнена",
      filledConsultation: "Заполнена",
      closed: "Завершена",
      closedConsultation: "Завершена",
      in_process: "В процессе",
      inProgressConsultation: "В процессе",
      cancelled: "Отменена",
      cancelledConsultation: "Отменена",
      //Specializations
      newSpecialty: "Новая специализация",
      title: "Наименование",
      priceOnline: "Стоимость online-консультации",
      priceHome: "Стоиомось вызовы на дом",
      priority: "Приоритет",
      showOnMainScreen: "Показывать на главном экране",
      showOnDoctorsList: "Показывать в списке врачей",
      //Banners
      banners: "Баннеры",
      newBanner: "Новый баннер",
      //Subscribers
      subscribers: "Подписанные пациенты",
      fullName: "Ф.И.О.",
      policeStartDate: "Дата начала",
      policeExpireDate: "Дата окончания",
      email: "Эл. почта",
      birthDate: "Дата рождения",
      insuranceCompany: "Название страховой компании",
      authorization: "Авторизация",
      scheduleListTitle: "Смены",
      specializationTitle: "Доктор",
      appointmentsTime: "Смена",
      durationTitle: "Длительность",
      filterToday: "Сегодня",
      filterTomorrow: "На завтра",
      filterWeek: "На неделю",
      filterAll: "Все",
      filterFormTitle: "Фильтр",
      filterAllSchedules: "Все смены",
      filterActiveSchedules: "Активные смены",
      filterTypeOfService: "Формат услуги",
      common: "Общее",
      name: "Имя",
      lastName: "Фамилия",
      patronymic: "Отчество",
      gender: "Пол",
      experience: "Опыт работы",
      old: "Лет",
      specializationDescription: "Описание",
      education: "Образование",
      typeEducation: "Вид образования",
      phoneNumber: "Номер телефона",
      newEmployee: "Новый сотрудник",
      // Specialty
      cost: "Стоимость",
      priceType: "Онлайн-консультация",
      callOnHome: "Вызов на дом",
      doctor: "Доктор",
      addSchedule: "Добавить смену",
      placeholderSpecialties: "Специализации",
      placeholderSpecialty: "Специализация",
      placeholderSearch: "Поиск",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Визит на дом",
      optionsServiceTypeInVideo: "Видеозвонок",
      optionsServiceTypeCall: "Звонок",
      optionsServiceTypeInChat: "В чате",
      optionsServiceTypeInHospital: "Визит в клинику",
      newScheduleFilterFormTitle: "Новая смена",
      newScheduleFilterFormTitleEdit: "Редактирование",
      manually: "Вручную",
      fromFile: "Из файла",
      duration: "Длительность консультации",
      date: "Дата смены",
      timeStart: "Время начала",
      timeEnd: "Время окончания",
      fileUploaderTitle: "Загрузить файл .xlsx",
      // Employees
      employeesFlowTitle: "Сотрудники",
      doctors: "Доктора",
      operators: "Операторы",
      operator: "Оператор / Админ",
      phone: "Телефон",
      //Auth
      login: "Войти",
      loginError: "Неверный логин",
      passwordError: "Неверный пароль",
      //onBoarding
      onBoarding: "Онбоардинг",
      edit: "Редактировать",
      newOnBoard: "Новый онбординг",
      addImage: "Добавить изображение",
      showInList: "Показать в списке",
      //PromoCodes Page
      promocodeStatus: "Статус промокода",
      promocodeStatus_0: "Не использован",
      promocodeStatus_1: "Использован",
      promocodeType: "Тип промокода",
      promocodeType_0: "Подписка",
      promocodeType_1: "Консультация",
      promocodeGenerateSuccess: "Промокоды успешно созданы",
      promocodesCount: "Всего промокодов:",
      promocodesActivateCount: "Промокодов активировано:",
      promoCodes: "Промокоды",
      addPromoCodes: "Добавить промокоды",
      promocodeName: "Промокод",
      promocodeCreated: "Создан",
      promocodeExpityDate: "Действителен до",
      promocodeCount: "Кол-во промокодов",
      //Symptoms Page
      addSymptom: "Добавить симптом",
      chooseSpecialty: "Выберите специальность",
      symptomsAndSpeciality: "Специализации и симптомы",
      symptoms: "Симптомы",
      emptyList: "Список пуст",
      existingSymptoms: "Существующие симптомы",
      deleteSymptom: "Удалить симптом",
      //Placeholders
      placeholderLogin: "Логин",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторите пароль",
      placeholderAddSymptom: "Добавить симптом",
      placeholderText: "Текст",
      placeholderNumber: "Введите число от 0 до 100",
      placeholderLink: "Ссылка",
      placeholderTitle: "Заголовок",
      //Buttons
      addButton: "Добавить",
      changeButton: "Заменить",
      saveButton: "Сохранить",
      deleteButton: "Удалить",
      editButton: "Редактировать",
      closeButton: "Закрыть",
      createButton: "Создать",
      createUserProfileButton: "Создать профиль клиента",
      lockButton: "Заблокировать",
      unLockButton: "Разблокировать",
      acceptButton: "Применить",
      // OPERATOR
      requestCreated: "Заявка создана",
      newPacient: "Новый пациент",
      mainCard: "Главная медкарта",
      relations: "Родство",
      adress: "Адрес",
      policeNumber: "№ полиса",
      insurance: "Компания/страховка",
      registration: "Оформление",
      //Specializations
      priceTitle: "Стоимость",
      //Placeholders
      success: "Успех",
      successCreatingPatient: "Пациент успешно создан",
      patientNotFound: "Пациент не найден",
      placeholderMedCard: "Медкарта",
      bookDate: "Дата бронирования",
      paymentType: "Способ оплаты",
      //Buttons
      arrangeButton: "Оформить консультацию",
      goodButton: "Хорошо",
      cancelButton: "Отменить",
      placeholderChatMessage: "Введите сообщение",
      placeholderImageOrVideo: "Выберите изображение или видеозапись",
      placeholderDocument: "Выберите документ",
      placeholderStartConsultation: "Начать консультацию",
      chat: "Чат",
      changeLang: "Язык",
      exitButton: "Вийти",
    },
  },
  uk: {
    translation: {
      createPassword: "Призначте пароль для вашого акаунту",
      month: "Місяці",
      newSubscription: "Нова підписка",
      percentileDiscount: "Відсоток знижки",
      priceDiscount: "Ціна підписки",
      company: "Компанія",
      companySuccessCreated: "Підписка створена",
      activateSubscription: "Активувати підписку",
      addPatient: "Додати пацієнта",
      isAdmin: "Админістратор компанiї",
      //sendPDFFile
      exportButton: "Єкспортувати",
      sendToEmail: "Відправити до єл. адреси",
      sendPDF: "Будь ласка, виберить компанію, до якої бажаєте відправити файл",
      sendSuccess: "Успішно відправлено",
      //Companies
      companies: "Компанії",
      createCompany: "Стоворити компанію",
      //Success
      successOperatorUpdate: "Оновлення оператора успішне",
      successDoctorUpdate: "Оновлення доктора успішне",
      successOperatorDelete: "Оператора успішно видалено",
      successDoctorDelete: "Доктора успішно видалено",
      successBlockUser: "Працівника було заблоковано",
      successUnblockUser: "Працівника було розблоковано",
      successOperatorCreate: "Оператора успішно створено",
      successDoctorCreate: "Доктора успішно створено",
      // ERRORS
      submitConfirm:
        "Ви впевнені, що бажаєте зберегти зміни та завершити консультацію?",
      cancelConfirm:
        "Ви впевнені, що бажаєте зачинити та відмінити консультацію?",
      birthDateInFuture: "Неможливо народитись у майбутньому",
      templateTitle: "Шаблони",
      min: "мин",
      from: "с",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "У разі, якщо потрібна технічна підтримка - прохання зателефонувати",
      medQuestion: "Мед. питання",
      techSupport: "Техпідтримка",
      infoMedQueation: "B разі, якщо хочете уточнити медичне питання",
      missedCall: "Пропущений дзвінок",
      canceledCall: "Скасований виклик",
      canceledConsultationMessage:
        "Шановний пацієнт, ваша консультацiя була вiдмiнена лiкарем.",
      finishConsultationMessage:
        "Ваша консультація завершена. Заключення лікаря:",

      //Sider
      shifts: "Розклад",
      employees: "Працівники",
      specializations: "Спеціалізації",
      subscriptions: "Підписки",
      promocodes: "Промокоди",
      rates: "Тариф",
      patients: "Пацієнтів",
      onboarding: "Онбоардінг",
      client: "Кліент",
      //=====
      //MODAL
      help: "Допомога",
      helpModalText_1:
        "У випадках, якщо потрібна технічна допомога - будь-ласка наберіть за телефоном:",
      helpModalText_2: "Якщо вибажаєте, задати медичне питання",
      techSupportButton: "Технічна допомога",
      medQuestionButton: "Медична допомога",
      //NetworkErrorPopup
      errorCode: "Помилка. Код:",
      errorDescription: "Опис помилки:",
      //UserTool
      notifications: "Повідомлення",
      //Validate
      addressValidate: "Усі поля з * повинні бути заповнені",
      //Address Component
      address: "Адреса",
      city: "Місто",
      district: "Район",
      street: "Вулиця",
      building: "будинок",
      apartment: "Квартира",
      codeOrNote: "Код або примітка",
      //СardData
      noPayment: "Не потребує оплаты",
      currentDiseaseHistory: "Анамнез поточного захворювання",
      allergicDiseases: "Аллергічні захворювання",
      medicalHistory: "Перенесенні захворювання",
      treatmentPlan: "План лікування",
      recommendations: "Рекомендаціі",
      //Client
      warning: "Увага!",
      consultationIsNotPayed: "Косультація не була сплачена",
      consultationCard: "Картка консультації",
      cardData: "Дані картки",
      //HistoryItemDetails
      consultationDetails: "Деталі консультації",
      onlineConsultation: "Онлайн консультація",
      consultationWithDoctor: "Консультація з лікарем",
      complaints: "Скарги",
      presumptiveDiagnosis: "Можливий діагноз (по МКХ-10)",
      TreatmentPlan: "План лікування",
      conclusion: "Висновок",
      medications: "Препарати",
      examinations: "Інструментальні дослідження",
      tests: "Аналізи",
      //History
      january: "січня",
      february: "лютого",
      march: "березня",
      april: "квітня",
      may: "травня",
      june: "червня",
      july: "липня",
      august: "серпня",
      september: "вересня",
      october: "жовтня",
      november: "листопада",
      december: "грудня",
      //Anamnez
      medCardTitle: "Медична карта",
      anamnez: "Анамнез",
      history: "Історія",
      deleteConfirm: "Ви впевнені, що бажаете видалити картку?",
      //MedCard
      sex: "Стать",
      maleSex: "Чоловіча",
      femaleSex: "Жіноча",
      //Monitoring
      monitoring: "Моніторинг",
      patient: "Пацієнт",
      cause: "Причина",
      status: "Статус",
      new: "Нова",
      newConsultation: "Нова",
      filled: "Заповнена",
      filledConsultation: "Заповнена",
      closed: "Закінчена",
      closedConsultation: "Закінчена",
      in_process: "У процесі",
      patientNotFound: "Пацієнта не знайдено",
      inProgressConsultation: "У процесі",
      cancelled: "Відмінена",
      cancelledConsultation: "Відмінена",
      //Specializations
      acceptButton: "Застосувати",
      requestCreated: "Заявка створена",
      newPacient: "Новий пацієнт",
      mainCard: "Головна мед карта",
      relations: "Спорідненість",
      adress: "Адреса",
      policeNumber: "№ полісу",
      insurance: "Компанія/страховка",
      registration: "Оформлення",
      //Specializations
      newSpecialty: "Нова спеціалізація",
      title: "Найменування",
      priceTitle: "Вартість",
      priceOnline: "Вартість онлайн консультаціі",
      priceHome: "Вартість виклика на дім",
      priority: "Пріоритет",
      showOnMainScreen: "Показати на головному екрані",
      showOnDoctorsList: "Показати в переліку лікарів",
      //Banners
      banners: "Банери",
      newBanner: "Новий банер",
      //Subscribers
      subscribers: "Підписані пацієнти",
      fullName: "П.І.П.",
      policeStartDate: "Час початку дії полісу",
      policeExpireDate: "Час закінчення дії полісу",
      insuranceCompany: "Назва страхової компаніі",
      authorization: "Авторизація",
      scheduleListTitle: "Зміни",
      specializationTitle: "Лікар",
      appointmentsTime: "Зміна",
      durationTitle: "Тривалість",
      filterToday: "Сьогодні",
      filterTomorrow: "Завтра",
      filterWeek: "На неділю",
      filterAll: "Всі",
      filterFormTitle: "Фільтр",
      filterAllSchedules: "Всі зміни",
      filterActiveSchedules: "Активні зміни",
      filterTypeOfService: "Формат послуги",
      // Specialty
      cost: "Вартість",
      priceType: "Онлайн консультація",
      callOnHome: "Виклик на дім",
      // =====
      common: "Загальне",
      name: "Ім'я",
      lastName: "Прізвище",
      patronymic: "Побатькові",
      birthDate: "Дата народження",
      gender: "Стать",
      experience: "Досвід роботи",
      old: "Років",
      email: "Ел. пошта",
      specializationDescription: "Опис",
      education: "Освіта",
      typeEducation: "Вид освіти",
      doctor: "Лікар",
      phoneNumber: "Номер телефону",
      newEmployee: "Новий співпрацівник",
      createButton: "Створити",
      createUserProfileButton: "Створити профіль клієнта",
      lockButton: "Заблокувати",
      unLockButton: "Розблокувати",
      cancelButton: "Скасувати",
      addSchedule: "Додати зміну",
      placeholderSpecialties: "Спеціалізації",
      placeholderSpecialty: "Спеціальність",
      placeholderSearch: "Пошук",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Візит на дом",
      optionsServiceTypeInHospital: "Візит до клініки",
      optionsServiceTypeInVideo: "Видеодзвінок",
      optionsServiceTypeCall: "Дзвінок",
      optionsServiceTypeInChat: "У чаті",
      newScheduleFilterFormTitle: "Нова зміна",
      newScheduleFilterFormTitleEdit: "Редагування",
      manually: "Вручну",
      fromFile: "З файлу",
      duration: "Тривалість консультації",
      date: "Дата зміни",
      timeStart: "Час початку",
      timeEnd: "Час закінчення",
      fileUploaderTitle: "Завантажити файл .xlsx",
      // Employees
      employeesFlowTitle: "Співпрацівники",
      doctors: "Лікарі",
      operators: "Оператори",
      operator: "Оператор / Адмін",
      phone: "Телефон",
      //Auth
      login: "Увійти",
      loginError: "Невірний логін",
      passwordError: "Невірний пароль",
      //onBoarding
      onBoarding: "Онбоардінг",
      edit: "Редагувати",
      newOnBoard: "Новий онбордінг",
      addImage: "Додати зображеня",
      showInList: "Показувати в списку",
      //PromoCodes Page
      promocodeStatus: "Статус промокоду",
      promocodeStatus_0: "Не використаний",
      promocodeStatus_1: "Використаний",
      promocodeType: "Тип промокоду",
      promocodeType_0: "Підписка",
      promocodeType_1: "Консультація",
      promocodeGenerateSuccess: "Промокоди успішно створено",
      promocodesCount: "Усього промокодів:",
      promocodesActivateCount: "Промокодів активовано:",
      promoCodes: "Промокоди",
      addPromoCodes: "Додати промокоди",
      promocodeName: "Промокод",
      promocodeCreated: "Создан",
      promocodeExpityDate: "Дійсний до",
      promocodeCount: "Кількість промокодів",
      //Symptoms Page
      addSymptom: "Додайте симптоми",
      chooseSpecialty: "Виберіть спеціалізацію",
      symptomsAndSpeciality: "Симптоми та спеціалізації",
      symptoms: "Симптоми",
      emptyList: "Пустий лист",
      existingSymptoms: "Існуючі симптоми",
      deleteSymptom: "Видалити симптом",
      //Placeholders
      success: "Успіх",
      successCreatingPatient: "Пацієента успішно створено!",
      placeholderLogin: "Логін",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторіть пароль",
      placeholderAddSymptom: "Додайте симптоми",
      placeholderText: "Текст",
      placeholderNumber: "Введіть число от 0 до 100",
      placeholderLink: "Посилання",
      placeholderTitle: "Заголовок",
      placeholderMedCard: "Медкартка",
      bookDate: "Дата бронювання",
      paymentType: "Спосіб оплати",
      //Buttons
      addButton: "Добавити",
      changeButton: "Змінити",
      saveButton: "Зберегти",
      deleteButton: "Видалити",
      editButton: "Редагувати",
      closeButton: "Зачинити",
      arrangeButton: "Оформити консультацію",
      goodButton: "Добре",
      placeholderChatMessage: "Введіть повідомлення",
      placeholderImageOrVideo: "Оберіть зображення або відеозапис",
      placeholderDocument: "Оберить документ",
      placeholderStartConsultation: "Почати консультацію",
      chat: "Чат",
      changeLang: "Мова",
      exitButton: "Вийти",
    },
  },
  en: {
    translation: {
      createPassword: "Create password to your account",
      month: "month",
      newSubscription: "New subscription",
      percentileDiscount: "Discount percentage",
      priceDiscount: "Discount price",
      company: "Company",
      companySuccessCreated: "Subscription create",
      activateSubscription: "Activate subscription",
      addPatient: "Add patient",
      isAdmin: "Company admin",
      //sendPDFFile
      exportButton: "Export",
      sendToEmail: "Send to email",
      sendPDF:
        "Please, select insurance company, witch you want to send the file too",
      sendSuccess: "Success",
      //Companies
      companies: "Companies",
      createCompany: "Create company",
      //Success
      successOperatorUpdate: "Operator update was successful",
      successDoctorUpdate: "Doctor update was successful",
      successOperatorDelete: "Operator was deleted successful",
      successDoctorDelete: "Doctor was deleted successful",
      successBlockUser: "Employee was blocked successfully",
      successUnblockUser: "Employee was unblocked successfully",
      successOperatorCreate: "Operator was created successful",
      successDoctorCreate: "Doctor was created successful",
      // ERRORS
      submitConfirm: "Do you want to close this consultation and finish it?",
      cancelConfirm: "Do you want to cancel this consultation and reject it?",
      birthDateInFuture: "You can`t be born in future",
      templateTitle: "Templates",
      min: "min",
      from: "from",
      to: "to",
      empty: "Empty",
      // Chat - CALL
      infoText: "If you need technical support - please call",
      medQuestion: "Medical question",
      techSupport: "Support",
      infoMedQueation: "If you want to clarify a medical question",
      missedCall: "Missed call",
      canceledCall: "Canceled call",
      canceledConsultationMessage:
        "Dear patient, your consultation has been canceled by a doctor.",
      finishConsultationMessage:
        "Your consultation is now complete. Doctor's conclusion:",
      //Sider
      shifts: "Shifts",
      employees: "Employees",
      specializations: "Specializations",
      subscriptions: "Subscriptions",
      promocodes: "Promocodes",
      rates: "Rate",
      patients: "Patients",
      onboarding: "Onboarding",
      client: "Client",
      //=====
      //MODAL
      help: "Help",
      helpModalText_1: "If you need a technical help - please call: ",
      helpModalText_2: "If you have a medical question: ",
      techSupportButton: "Technical help",
      medQuestionButton: "Medical help",
      //NetworkErrorPopup
      errorCode: "Error. Code:",
      errorDescription: "Error description:",
      //UserTool
      notifications: "Notification",
      //Validate
      addressValidate: "All fields with * need to be fulled",
      //Address Component
      address: "Address",
      city: "City",
      district: "District",
      street: "Street",
      building: "Building",
      apartment: "Apartment",
      codeOrNote: "Code or note",
      //CardData
      noPayment: "Payment not required",
      currentDiseaseHistory: "Current disease history",
      allergicDiseases: "Allergic diseases",
      medicalHistory: "Medical history",
      treatmentPlan: "Treatment plan",
      recommendations: "Recommendations",
      //Client
      warning: "Warning",
      consultationIsNotPayed: "Consultation is not payed",
      consultationCard: "Card of consultation",
      cardData: "Card data",
      //HistoryItemDetails
      consultationDetails: "Consultations detail",
      onlineConsultation: "Online consultation",
      consultationWithDoctor: "Consultation with doctor",
      complaints: "Complains",
      presumptiveDiagnosis: "Presumptive diagnosis (per ICD-10)",
      TreatmentPlan: "Treatment plan",
      conclusion: "Conclusion",
      medications: "Medications",
      examinations: "Examinations",
      tests: "Tests",
      //History
      january: "of january",
      february: "of february",
      march: "of march",
      april: "of april",
      may: "of may",
      june: "of june",
      july: "of july",
      august: "of august",
      september: "of september",
      october: "of october",
      november: "of november",
      december: "of december",
      //Anamnez
      medCardTitle: "Medical Card",
      anamnez: "Case history",
      history: "History",
      deleteConfirm: "Are you sure, you want to delete a medical card?",
      //MedCard
      sex: "Sex",
      maleSex: "Male",
      femaleSex: "Female",
      //Monitoring
      monitoring: "Monitoring",
      patient: "Patient",
      cause: "Reason",
      status: "Status",
      new: "New",
      newConsultation: "New",
      filled: "Filled",
      filledConsultation: "Filled",
      closed: "Closed",
      closedConsultation: "Closed",
      in_process: "In progress",
      patientNotFound: "Patient not found",
      inProgressConsultation: "In progress",
      cancelled: "Cancelled",
      cancelledConsultation: "Cancelled",
      newSpecialty: "New specialty",
      title: "Title",
      priceOnline: "Online consultation fee",
      priceHome: "Home visit fee",
      priority: "Priority",
      showOnMainScreen: "Show on main screen",
      showOnDoctorsList: "Show in doctors list",
      //Banners
      banners: "Banners",
      newBanner: "New banner",
      //Subscribers
      subscribers: "Subscribers",
      fullName: "Full name",
      policeStartDate: "Policy start date",
      policeExpireDate: "Policy expiry date",
      email: "Email",
      birthDate: "Date of birth",
      insuranceCompany: "Insurance company",
      authorization: "Authorization",
      scheduleListTitle: "Shifts",
      specializationTitle: "Doctor",
      appointmentsTime: "Shift",
      durationTitle: "Duration",
      filterToday: "For today",
      filterTomorrow: "Tomorrow",
      filterWeek: "For the week",
      filterAll: "All",
      filterFormTitle: "Filter",
      filterAllSchedules: "All shifts",
      filterActiveSchedules: "Active shifts",
      filterTypeOfService: "Service format",
      // Specialty
      cost: "Price",
      priceType: "Online consultation",
      callOnHome: "Call on home",
      // =====
      // =====
      common: "General",
      name: "First name",
      lastName: "Last name",
      patronymic: "Patronymic",
      gender: "Gender",
      experience: "Experience",
      old: "Years",
      specializationDescription: "Description",
      education: "Education",
      typeEducation: "Education type",
      doctor: "Doctor",
      phoneNumber: "Tel. number",
      newEmployee: "New employee",
      createButton: "Create",
      createUserProfileButton: "Create user profile",
      lockButton: "Lock",
      unLockButton: "Unlock",
      addSchedule: "Add a shift",
      cancelButton: "Cancel",
      placeholderSpecialties: "Specialties",
      placeholderSpecialty: "Specialty",
      placeholderSearch: "Search",
      optionsServiceTypeOnline: "Online",
      optionsServiceTypeAtHome: "Visit at home",
      optionsServiceTypeInHospital: "Visit at hospital",
      optionsServiceTypeInVideo: "Video call",
      optionsServiceTypeCall: "Call",
      optionsServiceTypeInChat: "In chat",
      newScheduleFilterFormTitle: "New shift",
      newScheduleFilterFormTitleEdit: "Edit",
      manually: "Manually",
      fromFile: "From a file",
      duration: "Duration",
      date: "Date",
      timeStart: "Start time",
      timeEnd: "End time",
      fileUploaderTitle: "Upload file .xlsx",
      // Employees
      employeesFlowTitle: "Employees",
      doctors: "Doctors",
      operators: "Operators",
      operator: "Operator / Admin",
      phone: "Telephone",
      //Auth
      login: "Sign in",
      loginError: "Incorrect login",
      passwordError: "Incorrect password",
      //onBoarding
      onBoarding: "Onboarding",
      edit: "Edit",
      newOnBoard: "New onboarding",
      addImage: "Add an image",
      showInList: "Show in list",
      //PromoCodes Page
      promocodeStatus: "Promocode status",
      promocodeStatus_0: "Used",
      promocodeStatus_1: "Unused",
      promocodeType: "Promocode type",
      promocodeType_0: "Subscription",
      promocodeType_1: "Consultation",
      promocodeGenerateSuccess: "Promocode generate success",
      promocodesCount: "All promocodes:",
      promocodesActivateCount: "Promocodes activated:",
      promoCodes: "Promocodes",
      addPromoCodes: "Add promocodes",
      promocodeName: "Promocode",
      promocodeCreated: "Created",
      promocodeExpityDate: "Expiry Date",
      promocodeCount: "Promocodes count",
      //Symptoms Page
      addSymptom: "Add a symptom",
      chooseSpecialty: "Choose specialty",
      symptomsAndSpeciality: "Symptoms and specialty",
      symptoms: "Symptoms",
      emptyList: "The list is empty",
      existingSymptoms: "Existing symptoms",
      deleteSymptom: "Delete a symptom",
      //Placeholders
      placeholderLogin: "Login",
      placeholderPassword: "Password",
      placeholderRepeatPassword: "Repeat password",
      placeholderAddSymptom: "Add a symptom",
      placeholderText: "Text",
      placeholderNumber: "Write number from 0 to 100",
      placeholderLink: "Link",
      placeholderTitle: "Title",
      //Buttons
      addButton: "Add",
      changeButton: "Change",
      saveButton: "Save",
      deleteButton: "Delete",
      editButton: "Edit",
      closeButton: "Close",
      acceptButton: "Apply",
      // OPERATOR
      requestCreated: "Request created",
      newPacient: "New patient",
      mainCard: "Main card",
      relations: "Relationship",
      adress: "Address",
      policeNumber: "Police number",
      insurance: "Company/insurance",
      registration: "Registration",
      priceTitle: "Price",
      // Specialty
      //Placeholders
      success: "Success",
      successCreatingPatient: "Patient has been created successful!",
      placeholderMedCard: "Medical cards",
      bookDate: "Book date",
      paymentType: "Payment type",
      //Buttons
      arrangeButton: "Arrange",
      goodButton: "Good",
      placeholderChatMessage: "Type message",
      placeholderImageOrVideo: "Chose image or video",
      placeholderDocument: "Chose document",
      placeholderStartConsultation: "Start consultation",
      chat: "Chat",
      changeLang: "Lang",
      exitButton: "Logout",
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
