import * as types from "./types";
import api from "../../api";

export const getAllPatients = () => (dispatch) => {
  api.patients.getAllPatients().then((res) =>
    dispatch({
      type: types.GET_ALL_PATIENTS,
      ...res,
    })
  );
};
export const getPatientCard = (patientCard) => ({
  type: types.GET_PATIENT_CARD,
  patientCard,
});

export const getThePatient = (patientId, cardId) => (dispatch) =>
  api.patients.getThePatient(patientId, cardId).then((res) =>
    dispatch({
      type: types.GET_THE_PATIENT,
      patient: { ...res },
    })
  );

export const getThePatientAnamnesis = (patientId, cardId) => (dispatch) => {
  api.patients.getThePatientAnamnesis(patientId, cardId).then((res) =>
    dispatch({
      type: types.GET_THE_PATIENT_ANAMNESIS,
      anamnesis: { ...(res && res.data) },
    })
  );
};
export const getPatientCards = (patientId, cardId) => (dispatch) =>
  api.patients.getPatientCards(patientId, cardId).then((res) =>
    dispatch({
      type: types.GET_PATIENT_CARDS,
      cards: { ...(res && res.data) },
    })
  );

export const addNewPatientCard = (id, values) => (dispatch) =>
  api.patients
    .createPatientCard(id, {
      patientCard: { ...values },
    })
    .then((res) => dispatch({ type: "CREATE_PATIENT_CARD", res }));

export const creteNewPatientCard = (values) => (dispatch) =>
  api.patients
    .createNewPatientCard({ ...values })
    .then((res) => dispatch({ type: "CREATE_NEW_PATIENT_CARD", res }));

export const updatePatientCard = (id, data) => (dispatch) =>
  api.patients
    .updatePatientCard(id, data)
    .then(() => dispatch({ type: "UPDATE_PATIENT_CARD" }));

export const getThePatientAppointments = (cardId) => (dispatch) => {
  api.patients.getThePatientAppointments(cardId).then((res) =>
    dispatch({
      type: types.GET_THE_PATIENT_APPOINTMENTS,
      appointments: { ...res },
    })
  );
};

export const searchPatients = (role, searchKey) => (dispatch) => {
  api.patients.searchPatients(role, searchKey).then((res) =>
    dispatch({
      type: types.SEARCH_PATIENTS,
      searchList: res && res.data && res.data.items,
      searchKey,
    })
  );
};

export const endOfConsultation = (value, reason) => ({
  type: types.END_OF_CONSULTATION,
  endOfConsultation: value,
  reasonOfEndingConsultation: reason,
});

export const cancelOfConsultationFlagSet = (value) => ({
  type: types.CANCEL_OF_CONSULTATION,
  cancelledConsultation: value,
});

export const getPatientRelations = () => (dispatch) =>
  api.patients
    .getPatientRelations()
    .then((res) =>
      dispatch({ type: types.GET_RELATIONS, data: { ...(res && res.data) } })
    );

export const clearPatient = () => ({ type: types.RESET_PATIENT });
