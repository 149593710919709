import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getAllPromoCodes: async (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/promocodes${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  getFilteredPromocodes: async (
    status = "",
    type = "",
    dateStart = "",
    dateEnd = "",
    companyId = "none",
    numberOfPage = "1"
  ) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/promocodes?filter[status]=${status}&filter[type]=${type}&filter[activatedFrom]=${dateStart}&filter[activatedTo]=${dateEnd}&filter[insuranceCompany]=${companyId}&page=${numberOfPage}`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  generateNewPromocodes: async (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/promocodes/generate`,
      headers: setHeader(),
      data,
    };

    const res = await axios(config);
    return res && res.data;
  },
};
