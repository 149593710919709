import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./networkErrorPopup.scss";

export const NetworkErrorPopup = () => {
  const { t } = useTranslation();

  const isGetError = useSelector((state) => state.networkError.isGetError);
  const status = useSelector((state) => state.networkError.status);
  const err_arr = useSelector((state) => state.networkError.erorrsArr);
  const text = useSelector(
    ({ networkError }) => {
      if (networkError && networkError.status === 401) {
        return (
          (networkError &&
            networkError.data &&
            networkError.data.message &&
            networkError.data.message.message) ||
          networkError.statusText
        );
      } else {
        return (
          (networkError && networkError.data && networkError.data.message) ||
          networkError.statusText
        );
      }
    }
    // if (
    //   (state.networkError.error &&
    //     state.networkError.error.data &&
    //     state.networkError.error.data.code === 401) ||
    //   (state.networkError.error &&
    //     state.networkError.error.data &&
    //     state.networkError.error.data.code === 409)
    // ) {
    //   return (
    //     state.networkError.error.data.message.message ||
    //     state.networkError.error.data.message
    //   );
    // } else {
    //   return (
    //     state.networkError.error.statusText ||
    //     (state.networkError &&
    //       state.networkError.error &&
    //       state.networkError.error.data &&
    //       state.networkError.error.title) ||
    //     "Ошибку не удалось идентифицировать"
    //   );
    // }
  );
  const hold = useSelector((state) => state.networkError.hold);
  const succes = useSelector((state) => state.networkError.succes);

  const [popupIn, setPopupIn] = useState(false);
  const [popupOut, setPopupOut] = useState(false);

  useEffect(() => {
    if (isGetError) {
      setPopupIn(true);
      setPopupOut(false);
      setTimeout(() => {
        setPopupIn(false);
        setPopupOut(true);
      }, 3000); 
    }
  }, [isGetError, hold]);
  

  return (
    <>
      <div
        className={`networkErrorPopupWrapper
        ${succes ? "succes" : ""}
        ${popupIn ? "slideIn" : ""}
        ${popupOut ? "slideOut" : ""}`}
      >
        <div className="networkErrorPopupContent">
          {succes && <p>{`${t("status")} ${status}`}</p>}
          {succes && <p>{`${text}`}</p>}
          {!succes && <p>{`${t("errorCode")} ${status}`}</p>}
          {!succes && <p>{`${t("errorDescription")} ${text}`}</p>}
          {!succes && err_arr && Object.keys(err_arr).length > 0 && Object.keys(err_arr).map((user_key, index) => {
            return <p key={index}>{`#${user_key} ${err_arr[user_key]}`}</p>
          })}
        </div>
      </div>
    </>
  );
};

export default NetworkErrorPopup;
