import React, { useEffect, useState } from "react";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import clientAva from "../../../style/icons/Avatar/avatar-cat.svg";
import shevronUp from "../../../style/icons/ShevronUp/shevron-up.svg";
import shevronDown from "../../../style/icons/ShevronDown/shevron-down.svg";
import "./Client.scss";
import Chat from "../../components/Chat/Chat";
import ConsultationCard from "../../components/ConsultattionCard/ConsultationCard";
import NewConsultation from "../../components/NewConsultation/NewConsultation";
import NewCardForm from "../../forms/NewCardForm/NewCardForm";
import { connect } from "react-redux";
import {
  getAllPatients,
  getPatientCards,
  getThePatient,
  getPatientCard,
  getThePatientAppointments,
  getThePatientAnamnesis,
} from "../../../redux/patients/actions";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  getAllChatMessagesTemplates,
  setFullScreenVideoMode,
} from "../../../redux/chat/actions";
import Button from "../../components/ui/Button/Button";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import { compose } from "redux";
import { useRouteMatch, withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import RelativesList from "../../components/RelativesList/RelativesList";
import { setHeader, TEST_API } from "../../../api";
import axios from "axios";
import ShowMedCard from "../../components/MedCard/ShowMedCard";
import { getCurrentConsultation } from "../../../redux/consultations/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import { useTranslation } from "react-i18next";

import Spinner from "../../components/ui/Spinner/Spinner";

const Client = ({
  patients,
  id,
  role,
  history,
  patient,
  patientCard,
  getThePatientAppointments,
  user,
  chat,
  relatives = [],
  getAllChatMessagesTemplates,
  setFullScreenVideoMode,
  getThePatient,
  getPatientCards,
  getPatientCard,
  getThePatientAnamnesis,
  anamnesis = [],
  cards,
  resetInitialValues,
  getCurrentConsultation,
  setNetworkError,
}) => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const [newCard, setNewCard] = useState(false);
  const [showRelatives, setShowRelatives] = useState(false);
  const [rel, setRel] = useState([]);
  const [localPatientRelationShip, setLocalPatientRelationship] = useState();
  const [started, setStarted] = useState(false);
  const [loading, setLoading] = useState();

  let year = new Date(Date.parse(patient && patient.birthDate)).getFullYear();
  let month = new Date(Date.parse(patient && patient.birthDate)).getMonth();
  let day = new Date(Date.parse(patient && patient.birthDate)).getDate();

  const addCard = () => {
    setNewCard(true);
  };
  const removeCard = () => {
    setNewCard(false);
  };
  const age = () => {
    let y = new Date().getFullYear();
    let m = new Date().getMonth();
    let d = new Date().getDate();
    month = Number(month);
    let res = () => {
      let dif = y - year;
      if (y > year && m > month - 1) {
        return dif;
      }
      if (y > year && m === month - 1 && d >= day) {
        return dif;
      }
      if (y > year && m === month - 1 && d < day) {
        return dif - 1;
      }
      if (y > year && m < month - 1) {
        return dif - 1;
      }
      if (y === year) {
        return dif;
      }
    };
    let str = String(res());
    let last = str[str.length - 1];

    if (last === "1") {
      return res() + " год";
    }
    if (last === "2" || last === "3" || last === "4") {
      return res() + " года";
    } else {
      return res() + " лет";
    }
  };

  let birthDay = () => {
    return (
      (day < 10 ? (day = "0" + day) : day) +
      "." +
      (month + 1 < 10 ? (month = "0" + (month + 1)) : month + 1) +
      "." +
      year
    );
  };

  const showRel = () => {
    setShowRelatives(true);
  };

  const replaceAva = (person) => {
    if (person && person.patronymic && person.firstName)
      return (
        person.patronymic[0].toUpperCase() + person.firstName[0].toUpperCase()
      );
  };

  const toUpperCase = (person) => {
    return (
      person.relationShip &&
      person.relationShip.title[0].toUpperCase() +
        person.relationShip.title.slice(1)
    );
  };

  const addNewCard = newCard && (
    <NewCardForm toClose={removeCard} setNewCard={setNewCard} />
  );

  const medicalcard = !newCard && <ShowMedCard propsRole={role} />;

  useEffect(() => {
    // console.log("MATCH", match.params);
    // if (match.params.patient) {
    getPatientCards(
      match.params.patient || localStorage.getItem("patientID")
    ).then((res) => {
      // console.log({ res });
      if (
        res.cards &&
        res.cards.items &&
        res.cards.items.length &&
        res.cards.items.length > 0
      ) {
        getAllChatMessagesTemplates();
        getThePatient(
          match.params.patient,
          match.params.card
        ).then(({ patient }) =>
          setLocalPatientRelationship(patient.relationShip)
        );
        // TODO switch by role
        getThePatientAnamnesis(match.params.patient, match.params.card);
        getThePatientAppointments(match.params.card);
        // getPatientCards(match.params.patient);
        localStorage.getItem("currentConsultationId") &&
          getCurrentConsultation(
            localStorage.getItem("currentConsultationId")
          ).then(({ currentConsultation }) => {
            // console.log(
            //   "-------------------------------> Внимание",
            //   currentConsultation
            // );
            if (currentConsultation && currentConsultation.isNeedToPay) {
              // console.log("-------------------------------> Внимание");
              setNetworkError({
                response: {
                  status: t("warning"),
                  statusText: t("consultationIsNotPayed"),
                  hold: true,
                },
              });
            }
          });
      }
    });
    // }
  }, [match.params]);

  useEffect(() => {
    return function () {
      resetInitialValues();
    };
  }, []);

  return (
    <div className="doctorWrapper">
      {loading && (
        <div className="locker">
          <div className="absoluteCenter">
            <Spinner />
          </div>
        </div>
      )}
      <div className="header">
        <div className="clientInfo">
          <NavLink to={`/home/${role}/monitoring/`}>
            <img className="arrow-left" alt="" src={arrowLeft} />
          </NavLink>
          {patient && patient.photoUrl && (
            <img
              className="doctorImage"
              src={patient && patient.photoUrl}
              alt="userImg"
            />
          )}
          {patient && !patient.photoUrl && (
            <div className={"patientImage"}>{replaceAva(patient)}</div>
          )}
          {!loading && (
            <div className="doctorDetails">
              <span className="doctorName">{patient && patient.fullName}</span>
              {localPatientRelationShip && (
                <span className="doctorMe">
                  {localPatientRelationShip.title}
                </span>
              )}
              <br />
              <span className="doctorPhone">{`${t("phone")} ${
                patient && patient.phone ? patient && patient.phone : t("empty")
              }`}</span>
            </div>
          )}
          <img
            onClick={showRel}
            className="arrow-down"
            alt=""
            src={shevronDown}
          />
          {patient && patient.patient && patient.patient.isCompanySubscribed && (
            <div className="subscriber">
              <div className="blueCircle">
                <div className="checkMark"></div>
              </div>
            </div>
          )}
        </div>
        {showRelatives && (
          <RelativesList
            setShowRelatives={setShowRelatives}
            replaceAva={replaceAva}
            toUpperCase={toUpperCase}
          />
        )}
        {role === "operator" && (
          <Button
            className={"btn alignCenter flex noBackground"}
            withIcon
            icon={plus}
            type="button"
            onClick={addCard}
            text={t("addButton")}
          />
        )}
      </div>
      <div className="doctorContentContainer">
        {addNewCard}
        {medicalcard}
        {role !== "insurance-operator" && (
          <Chat
            role={role}
            user={user}
            chat={chat}
            setFullScreenVideoMode={setFullScreenVideoMode}
            started={started}
            setStarted={setStarted}
          />
        )}
        {role === "doctor" /* || role === "admin" */ && (
          <ConsultationCard
            started={started}
            setStarted={setStarted}
            loading={loading}
            setLoading={setLoading}
          />
        )}
        {(role === "operator" || role === "insurance-operator") && (
          <NewConsultation
            cards={cards}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, user, chat }) => ({
  patients,
  patient: patients && patients.patient,
  id:
    patients &&
    patients.patient &&
    patients.patient.patient &&
    patients.patient.patient.id,
  cards: patients && patients.cards && patients.cards.items,
  anamnesis: patients && patients.anamnesis && patients.anamnesis.items,
  patientCard: patients && patients.patient,
  user,
  chat,
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getAllPatients,
    setInitalValues,
    getThePatient,
    getPatientCards,
    getAllChatMessagesTemplates,
    getThePatientAppointments,
    setFullScreenVideoMode,
    getPatientCard,
    getThePatientAnamnesis,
    resetInitialValues,
    getCurrentConsultation,
    setNetworkError,
  })
);

export default enhance(Client);
