import React from "react";

const Button = ({
  disabled,
  className,
  type,
  onClick,
  loading,
  secondary,
  shevron,
  freeTopSpace,
  text,
  withIcon,
  icon,
  filter,
  danger,
  disabledIcon,
  optionAsList,
  labelsOption,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`${className} ${freeTopSpace ? " freeTopSpace" : ""}${
        filter ? " filter" : ""
      }${withIcon ? "withIcon" : ""}${secondary ? " secondary" : ""}${
        danger ? " danger" : ""
      }${disabled ? " disabled" : ""}`}
      onClick={() => onClick && onClick()}
    >
      <span>
        {withIcon && icon && (
          <img
            className="btn-icon"
            src={disabled ? disabledIcon : icon}
            // alt="btn-icon"
          />
        )}
        {!optionAsList ? (
          text || ''
        ) : (
            <div className="selectedLabels">
              {(labelsOption && labelsOption.length === 0) ||
                labelsOption.length === undefined
                ? text || ""
                : labelsOption.map((el, idEl) => <span key={idEl}>{el || ''}</span>)}
            </div>
          )}
      </span>
      {shevron && <img className="btn-icon" src={shevron} alt="btn-icon" />}
    </button>
  );
};

export default Button;
