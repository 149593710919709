import React from "react";

import "./Splitter.scss";

const Splitter = ({ left = {}, right = {} }) => (
  <>
    <div className="panel-left">{left}</div>
    <div className="panel-right">{right}</div>
  </>
);

export default Splitter;
