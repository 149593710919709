import React, { useState, useEffect, useRef } from "react";

import Button from "../../ui/Button/Button";
import Spinner from "../../ui/Spinner/Spinner";

import ShevronRight from "../../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";
import markDone from "../../../../style/icons/MarkDoneBlue/mark.svg";
import searchIcon from "../../../../style/icons/Search/search-icon.svg";

import api from "../../../../api";

import "./filterSelect.scss";
import { useTranslation } from "react-i18next";

const FilterSelect = ({
  options,
  input,
  withSearch,
  withIcon,
  icon,
  withPreview,
  placeholder,
  previewIcon,
  multiple,
  withIconInOptions,
  initialValues,
  disabled,
  onChangeHandler,
  disabledIcon,
  optionAsList,
  apiSearchURL,
}) => {
  const { t } = useTranslation();

  const [localOptions, setLocalOptions] = useState(options);
  const [showOptions, setOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(multiple ? [] : "");
  const [count, setCount] = useState(null);
  const [previewIconLocal, setPreviewIcon] = useState(previewIcon);
  const [x, setX] = useState(612);
  const [page, setPage] = useState(1);
  const [loading, isLoading] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setX(ref.current.offsetHeight);
    setPage(1);
  }, []);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedOption(input.value);
    setCount(input.value.length);
    // WIP : uncomment when u need to scroll to top after pick choise
    // ref.current.scrollTo(0, 0);
    if (input.value === "") {
      multiple ? setSelectedOption([]) : setSelectedOption(null);
      setCount(input.value.length);
      setPreviewIcon(previewIcon);
    }
  }, [input.value]);

  const choiseHandler = (e, elem) => {
    let removeItemIndex;
    if (multiple) {
      if (
        selectedOption.includes(elem) ||
        selectedOption.map((el) => el.title).includes(elem.title)
      ) {
        selectedOption.forEach((el, i) => {
          if (el.id === elem.id) {
            removeItemIndex = i;
          }
        });
        selectedOption.splice(removeItemIndex, 1);
        setSelectedOption(selectedOption);
        setCount(selectedOption.length);
        onChangeHandler && onChangeHandler(selectedOption);
        return input.onChange(selectedOption);
      }

      selectedOption.push(elem);
      setSelectedOption(selectedOption);
      setCount(selectedOption.length);
      onChangeHandler && onChangeHandler(selectedOption);

      return input.onChange(selectedOption);
    } else {
      setSelectedOption(/* localOptions[index].title ||  */ elem);
      setPreviewIcon(
        elem.imgUrl ||
          (selectedOption && selectedOption.imageUrl) ||
          previewIcon
      );
      onChangeHandler && onChangeHandler(elem);
      return input.onChange(/* localOptions[index].title || */ elem);
    }
  };

  const searchInputHandler = (e) => {
    ref.current.scrollTo(0, 0);
    if (e.target.value.length) {
      setX(ref.current.offsetHeight);
      setPage(1);
      if (apiSearchURL) {
        return api.consultations
          .getCardOptions(`${apiSearchURL}${e.target.value}`)
          .then((res) => setLocalOptions([...(res.data && res.data.items)]));
      } else {
        if (!withIconInOptions) {
          return setLocalOptions(
            options.filter((elem) =>
              elem.title.toLowerCase().includes(e.target.value)
            )
          );
        } else {
          return setLocalOptions(
            options.filter((elem) =>
              elem.title.toLowerCase().includes(e.target.value)
            )
          );
        }
      }
    } else {
      return setLocalOptions(options);
    }
  };

  const onScrollHandler = () => {
    if (apiSearchURL) {
      if (ref.current.scrollTop > x - 150) {
        isLoading(true);
        setX(x + ref.current.offsetHeight);
        if (!loading) {
          return api.consultations
            .getCardOptions(apiSearchURL, page + 1)
            .then((res) => {
              localOptions.push(...res.data.items);
            })
            .then(() => setPage(page + 1))
            .then(() => isLoading(false));
        }
      }
    }
  };

  return (
    <>
      <Button
        className="btn"
        secondary
        optionAsList={optionAsList}
        labelsOption={
          optionAsList &&
          selectedOption &&
          selectedOption.length > 0 &&
          selectedOption.map((el) => el.title)
        }
        text={
          (withPreview &&
            multiple &&
            (selectedOption.map((el) => el.title).join(", ") ||
              selectedOption.join(", "))) ||
          (withPreview &&
            withIconInOptions &&
            selectedOption &&
            selectedOption.length > 0 &&
            (selectedOption.title || selectedOption)) ||
          (withPreview && initialValues) ||
          (withPreview &&
            !multiple &&
            selectedOption &&
            (selectedOption.title || selectedOption)) ||
          "" ||
          placeholder
        }
        shevron={ShevronRight}
        withIcon={withIcon}
        icon={
          withPreview
            ? withIconInOptions
              ? selectedOption &&
                (selectedOption.imgUrl || selectedOption.imageUrl)
                ? selectedOption &&
                  (selectedOption.imgUrl || selectedOption.imageUrl)
                : previewIcon
              : previewIcon
            : icon
        }
        type="button"
        onClick={() => setOptions(true)}
        disabled={disabled}
        disabledIcon={disabledIcon}
      />
      <div className={`optionSelectingContainer ${showOptions ? "show" : ""}`}>
        {loading && (
          <div className={"background"}>
            <Spinner />
          </div>
        )}
        <div className="formTitle">
          <img
            src={arrowLeft}
            alt="icon"
            onClick={() => {
              setOptions(false);
              setX(0);
              setPage(1);
            }}
          />
          {placeholder}
        </div>
        {withSearch && (
          <div className="search">
            <img src={searchIcon} alt="icon" />
            <input
              className="search"
              type="text"
              placeholder={t("placeholderSearch")}
              onChange={searchInputHandler}
            />
            {multiple && count > 0 && (
              <div className="count">
                <span>{count}</span>
              </div>
            )}
          </div>
        )}
        <div className="filterOptions" onScroll={onScrollHandler} ref={ref}>
          {localOptions && localOptions.length ? (
            localOptions.map((elem, index) => (
              <div
                key={index}
                className="option"
                onClick={(e) => choiseHandler(e, elem)}
              >
                {elem && (elem.imgUrl || elem.title) && (
                  <span>
                    {elem && elem.imgUrl && (
                      <img src={elem && elem.imgUrl} alt="" />
                    )}
                    {elem && (elem.title || elem.value)}
                  </span>
                )}
                {(selectedOption && selectedOption.title === elem.title) ||
                (selectedOption &&
                  selectedOption.length &&
                  selectedOption.length > 0 &&
                  selectedOption.includes(elem)) ||
                (selectedOption &&
                  selectedOption.length &&
                  selectedOption.length > 0 &&
                  [...selectedOption]
                    .map((el) => el.title)
                    .includes(elem.title)) ? (
                  <img src={markDone} alt="icon" />
                ) : withIconInOptions && selectedOption === elem.title ? (
                  <img src={markDone} alt="icon" />
                ) : undefined}
              </div>
            ))
          ) : (
            <div className="empty">{t("emptyList")}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterSelect;
