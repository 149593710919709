import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  openModal: false,
  list: [],
};

const notificationsReducer = createReducer(initialState)({
  [types.TOGGLE_MODAL]: (state, { openModal }) => {
    return {
      ...state,
      openModal,
    };
  },
  [types.GET_NOTIFICATIONS_LIST]: (state, { list }) => {
    return {
      ...state,
      list,
    };
  },
  [types.ADD_ITEM_TO_LIST]: (state, { item }) => {
    return {
      ...state,
      list: state.list.concat(item),
    };
  },
});

export default notificationsReducer;
