import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import api from "../../../api";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import Paginator from "../../components/Paginator/Paginator";
import Filter from "../../components/Filter/Filter";

import {
  getAllPromoCodes,
  setPagination,
} from "../../../redux/promocodes/actions";

import plus from "../../../style/icons/PlusInCircle/plus.svg";
import greyPlus from "../../../style/icons/PlusInCircle/plus-grey.svg";

import "./PromoCodes.scss";
import PromocodesList from "../../components/PromocodesList/PromocodesList";

const PromoCodes = ({
  items = [],
  getAllPromoCodes,
  page_count,
  current_page,
  setPagination,
  itemsCount,
  role,
}) => {
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState({});
  const [isItemsFiltered, setItemsFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insuranceOptions, setInsuranceOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllPromoCodes().then(() => setLoading(false));
    api.employees
      .getInsuranceCompanies()
      .then((res) => setInsuranceOptions(res.data.items));
  }, []);
  
    return (
    <div className="promocodesContainer flex justifyBeetwen">
      <div className="promocodesListContainer">
        <div className="title flex justifyBeetwen">
          <p className="pageTitle">{t("promoCodes")}</p>
          <div className="promocodesCountContainer">
            <div className="promocodesCount">
              {t("promocodesCount")} {itemsCount}
            </div>
            {/*<div className="promocodesCount">*/}
            {/*  {t("promocodesActivateCount")} {itemsCount}*/}
            {/*</div>*/}
          </div>
        </div>
        {loading ? (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        ) : (
          <>
            <PromocodesList items={items} t={t} />
            {page_count !== 0 && !loading && (
              <Paginator
                current_page={current_page}
                filterParams={filterParams}
                setPagination={setPagination}
                page_count={page_count}
                scrollElement="promoCodesItemsContainer"
                setLoading={setLoading}
                isItemsFiltered={isItemsFiltered}
                intervalDisabled
              />
            )}
          </>
        )}
      </div>
      <Filter
        mode="promocodes"
        role={role}
        setFilterParams={setFilterParams}
        loading={loading}
        setLoading={setLoading}
        insuranceOptions={insuranceOptions}
        getAllPromoCodes={getAllPromoCodes}
        setItemsFiltered={setItemsFiltered}
      />
    </div>
  );
};

const AddPromoCodes = ({
  create,
  setCreating,
  inputValue,
  setInputValue,
  newPromoCode,
  t,
}) => {
  return (
    <div className={`promoCodesAddictedFormWrapper ${create ? "showing" : ""}`}>
      <div className="formContent">
        <label className="promoCodesCounterLabel" htmlFor="promoCodesCounter">
          {t("promocodeCount")}:
        </label>
        <input
          type="number"
          min={0}
          max={100}
          name="promoCodesCounter"
          value={inputValue}
          title={t("placeholderNumber")}
          placeholder={t("placeholderNumber")}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          type="button"
          className="btn btn-add alignCenter flex noBackground"
          text={t("addButton")}
          onClick={() => {
            newPromoCode();
            setCreating(false);
          }}
        />
        <Button
          type="button"
          className="btn btn-cncl alignCenter flex noBackground"
          text={t("closeButton")}
          onClick={() => {
            setCreating(false);
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ promocodes }) => ({
  page_count: promocodes && promocodes.page_count,
  current_page: promocodes && promocodes.current_page,
  items: promocodes && promocodes.items,
  itemsCount: promocodes && promocodes.total_item_count,
});

export default connect(mapStateToProps, {
  setPagination,
  getAllPromoCodes,
})(PromoCodes);
