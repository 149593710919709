import api from "../api";
import React from "react";
import { useTranslation } from "react-i18next";

import { format } from "./values";

export const toBase64 = (src, callback, outputFormat) => {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var dataURL;
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat, 0.5);
    callback(dataURL);
  };
  img.src = src;
  if (img.complete || img.complete === undefined) {
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
    img.src = src;
  }
};

export const convertBase64ToFile = (base64) => {
  const arr = base64.split(",");

  if (arr[0].match(/:(.*?);/)) {
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "file", { type: mime });
  } else {
    return base64;
  }
};

export const consultationDate = (item) => {
  let year = new Date(
    Date.parse(item.start || item.startDate || item)
  ).getFullYear();
  let month = new Date(
    Date.parse(item.start || item.startDate || item)
  ).getMonth();
  let day = new Date(
    Date.parse(item.start || item.startDate || item)
  ).getDate();
  if (day < 10) {
    day = "0" + day;
  }
  if (month === 0) {
    month = "Января";
  }
  if (month === 1) {
    month = "Февраля";
  }
  if (month === 2) {
    month = "Марта";
  }
  if (month === 3) {
    month = "Апреля";
  }
  if (month === 4) {
    month = "Мая";
  }
  if (month === 5) {
    month = "Июня";
  }
  if (month === 6) {
    month = "Июля";
  }
  if (month === 7) {
    month = "Августа";
  }
  if (month === 8) {
    month = "Сентября";
  }
  if (month === 9) {
    month = "Октября";
  }
  if (month === 10) {
    month = "Ноября";
  }
  if (month === 11) {
    month = "Декабря";
  }
  return day + " " + month + " " + year;
};

export const relationIs = (strValue) => {
  const tmpRel = {
    я: 1,
    дружина: 2,
    син: 3,
    дочка: 4,
  };
  return tmpRel[strValue];
};

export const statusIs = (status) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const t = useTranslation()
  const tmpStatus = {
    new: "Новая",
    filled: "Заполнена",
    closed: "Завершена", // status: "closed"
    cancelled: "Отменена",
    in_process: "В процессе",
  };
  return tmpStatus[status];
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const isActionType = (number) => {
  let tmpActions = {
    0: "TYPE_CHAT",
    1: "TYPE_PHONE",
    2: "TYPE_VIDEO",
    3: "TYPE_HOME",
    4: "TYPE_HOSPITAL",
  };
  return tmpActions[number];
};

export const actionTypeIs = (type) => format.find((el) => el.value === type);

export const roleIs = (role) => {
  let tmpRoles = {
    ROLE_OPERATOR: "operator",
    ROLE_INSURANCE_OPERATOR: "insurance-operator",
    ROLE_ADMIN: "admin",
    ROLE_DOCTOR: "doctor",
    ROLE_INSURANCE_ADMIN: "insurance-admin",
  };
  return tmpRoles[role];
};
