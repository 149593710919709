import axios from "axios";
import {
  setHeader,
  TEST_API,
  SIGNALING_SERVER,
  PORT_CHAT_SOCKET,
} from "../index";

export default {
  getChatMessagesTemplates: (data) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/chat-template-groups`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  deviceCall: ({ appointmentId, data }) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/call/${appointmentId}`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  chatFileUpload: ({ id, file }) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${TEST_API}/admin/chats/${id}/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },
  chatOperatorFileUpload: ({ id, file }) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${TEST_API}/admin/support/${id}/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  getChatHistory: (id, page) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/history/${id}?page=${
        page ? page : ""
      }`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => res && res.data);
  },

  getOperatorChatHistory: (id, page) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/support/history/${id}?page=${
        page ? page : ""
      }`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => res && res.data);
  },
};
