import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import shevronUp from "../../../style/icons/ShevronUp/shevron-up.svg";
import { connect } from "react-redux";
import { getThePatient } from "../../../redux/patients/actions";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import "./RelativesList.scss";

const RelativesList = ({
  relatives = [],
  id,
  setShowRelatives,
  getThePatient,
  replaceAva,
  history,
  toUpperCase,
}) => {
  const { t } = useTranslation();
  const changeCard = (person) => {
    const currentPathNameArray = window.location.pathname.split("/");

    if (id) {
      getThePatient(id, person.id);
    } else {
      getThePatient(currentPathNameArray[4], person.id);
    }

    history.replace(
      `/${currentPathNameArray[1]}/${currentPathNameArray[2]}/${currentPathNameArray[3]}/${currentPathNameArray[4]}/${person.id}/`
    );
    setShowRelatives(false);
  };

  return (
    <div
      className={"showRelativesWrapper"}
      onClick={() => {
        setShowRelatives(false);
      }}
    >
      <div className="showRelatives">
        {relatives.map((person) => (
          <div
            key={person.id}
            className={"showRelatives-unit"}
            onClick={() => changeCard(person)}
          >
            {person && person.photoUrl && (
              <img
                src={person.photoUrl}
                className={"showRelatives-unit-photo"}
              />
            )}
            {person && person.photoUrl === "" && (
              <div className={"showRelatives-unit-replaceAva"}>
                {replaceAva(person)}
              </div>
            )}
            <div className={"showRelatives-unit-data"}>
              <div className="showRelatives-unit-data-name">
                {person.fullName}
              </div>
              <div className="showRelatives-unit--data-phone">{`${t("phone")} ${
                person.phone
              }`}</div>
            </div>
            <div className="showRelatives-unit-relation">
              ({person.relationShip && toUpperCase(person)})
            </div>
          </div>
        ))} 
        {relatives && (
          <img
            src={shevronUp}
            alt="shevronUp"
            className={"shevronUp"}
            onClick={() => {
              setShowRelatives(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({ patients }) => ({
  id:
    patients &&
    patients.patient &&
    patients.patient.patient &&
    patients.patient.patient.id,
  relatives: patients && patients.cards && patients.cards.items,
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    getThePatient,
  })
);
export default enhance(RelativesList);
