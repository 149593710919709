import React, { useState, useEffect } from "react";
import { compose } from "redux";
import {
  Form,
  getFormValues,
  reduxForm,
  Field,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import close from "../../../style/icons/Close/close-icon.svg";

import activeDocIcon from "../../../style/icons/Doc/doc-blue.svg";
import activeOperatorIcon from "../../../style/icons/Operator/operator-blue.svg";

import inActiveDocIcon from "../../../style/icons/Doc/doc-grey.svg";
import inActiveOperatorIcon from "../../../style/icons/Operator/operator-grey.svg";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";

import {
  createOperator,
  updateOperator,
  createDoctor,
  updateDoctor,
  deleteDoctor,
  deleteEmployee,
  getAllEmployees,
  setPagination,
  getInsuranceCompanies,
} from "../../../redux/employees/actions";

import {
  setNetworkError,
  setPopUpSuccess,
} from "../../../redux/networkError/actions";

import { getAllSpecializations } from "../../../redux/specializations/actions";

import Toggle from "../../components/formComponent/Toggle/Toggle";
import ToggleImg from "../../components/formComponent/ToggleImg/ToggleImg";
import Doc from "./Doc/Doc";
import Operator from "./Operator/Operator";
import Button from "../../components/ui/Button/Button";
import FileUploader from "../../components/formComponent/FIleUploader/FileUploader";
import Spinner from "../../components/ui/Spinner/Spinner";

import api from "../../../api";
import "./newEmployeeForm.scss";

const NewEmployeeForm = ({
  edit,
  setEditingOff,
  setInitalValues,
  createDoctor,
  formValues = {},
  createOperator,
  initialValues,
  reset,
  handleSubmit,
  // specializations,
  updateDoctor,
  deleteDoctor,
  deleteEmployee,
  specialities,
  dirty,
  invalid,
  filterMode,
  getAllEmployees,
  addingImg,
  current_page,
  setAddingImg,
  setPagination,
  setBlob,
  blob,
  setNetworkError,
  setPopUpSuccess,
  getInsuranceCompanies,
  insuranceCompanies,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);
  const [locking, setLocking] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInsuranceCompanies();
  }, []);

  const closeEditing = () => {
    setEditingOff();
    setLocking(false);
    setDeleting(false);
    setLoading(false);
    resetInitialValues();
    reset();
    setInitalValues({
      fillType: { value: "hand", text: "Вручную" },
      birthDate: "",
    });
  };

  const formSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (formValues.fillType && formValues.fillType.value === "file") {
      if (formValues.employeeFile && formValues.employeeFile.file) {
        return handleSubmit((_v) => {
          api.employees
            .uploadFromFile(_v.employeeFile.file)
            .then(
              (res) => res.entity && console.log("Фото успeшно загруженно", res)
            )
            .catch((err) =>
              console.log("Во время загрузки файла что-то пошло не так!", err)
            );
        })();
      } else {
        window.alert("Загрузите файл перед отправкой");
      }
    } else {
      handleSubmit((values) => {
        values.birthDate = values.birthDate.split("T")[0];
        if (edit && !deleting && !locking) {
          console.log("edit");
          if (formValues.employeeType.value === "operator") {
            if (values.sex === t("maleSex")) {
              values.gender = 0;
            } else {
              values.gender = 1;
            }
            if (values.insuranceCompany) {
              insuranceCompanies &&
                insuranceCompanies.map((item) => {
                  if (item.title === values.insuranceCompany) {
                    values.insuranceCompany = item.id;
                  }
                });
            }
            return updateOperator(values.employeeID, values).then(
              async (res) => {
                if (res.code) {
                  setLoading(false);
                  for (let key in res.errors) {
                    const inputNameWithError = key.split(".").pop();
                    throw new SubmissionError({
                      [inputNameWithError]: res.errors[key],
                    });
                  }
                  return;
                }
                let {
                  entity: { id },
                } = res;
                console.log("Update operator with id: " + id);
                if (blob) {
                  await api.employees
                    .uploadPhoto(values.id, blob)
                    .then(({ entity: { id } }) => {
                      console.log(
                        "Upload photo in operator with id:" + id + " success..."
                      );
                      setBlob(null);
                    });
                }
                setPagination({ filterMode, numberOfPage: current_page });
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successOperatorUpdate"),
                    },
                  },
                });
                closeEditing();
              }
            );
          }
          if (formValues.employeeType.value === "doc") {
            if (filterMode === "all") {
              if (values.sex === t("maleSex")) {
                values.gender = 0;
              } else {
                values.gender = 1;
              }
              updateDoctor(values.doctorId, values).then(
                async ({
                  res: {
                    entity: {
                      employee: { id },
                    },
                  },
                }) => {
                  if (blob) {
                    await api.employees
                      .uploadPhoto(id || values.employeeID, blob)
                      .then((id) => {
                        setBlob(null);
                      });
                  }
                  setPagination({ filterMode, numberOfPage: current_page });
                  setPopUpSuccess({
                    response: {
                      succes: true,
                      status: t("success"),
                      data: {
                        message: t("successDoctorUpdate"),
                      },
                    },
                  });
                  closeEditing();
                }
              );
            }
            if (filterMode === "doctors") {
              if (values.sex === t("maleSex")) {
                values.gender = 0;
              } else {
                values.gender = 1;
              }
              updateDoctor(values.editingId, values).then(async (res) => {
                if (blob) {
                  await api.employees
                    .uploadPhoto(values.employeeID, blob)
                    .then((id) => {
                      console.log(
                        "Upload photo in operator with id:" + id + " success..."
                      );
                      setBlob(null);
                    });
                }
                setPagination({ filterMode, numberOfPage: current_page });
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successDoctorUpdate"),
                    },
                  },
                });
                closeEditing();
              });
            }
          }
        }
        if (edit && deleting) {
          console.log("deleting");
          if (values.employeeType.value === "doc") {
            filterMode === "all" &&
              deleteDoctor(values.id).then(() => {
                // setPagination({ filterMode, numberOfPage: current_page })
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successDoctorUpdate"),
                    },
                  },
                });
                setPagination({ filterMode, numberOfPage: 1 });
              });
            filterMode === "doctors" &&
              deleteDoctor(values.editingId).then(() => {
                // setPagination({ filterMode, numberOfPage: current_page })
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successDoctorDelete"),
                    },
                  },
                });
                setPagination({ filterMode, numberOfPage: 1 });
              });
          }
          if (values.employeeType.value === "operator") {
            deleteEmployee(values.editingId).then(() => {
              // setPagination({ filterMode, numberOfPage: current_page })
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successOperatorDelete"),
                  },
                },
              });
              setPagination({ filterMode, numberOfPage: 1 });
            });
          }
          setBlob(null);
          closeEditing();
        }
        if (edit && locking) {
          if (formValues.roles && formValues.roles.includes("ROLE_ADMIN")) {
            setNetworkError({
              response: {
                status: "Внимание!",
                statusText:
                  "Админ не может быть заблокирован. Он слишком крут.",
              },
            });
            closeEditing();
            return;
          }
          if (formValues.isBlocked) {
            api.employees
              .toUnlockEmployee(
                initialValues.employee
                  ? initialValues.employee.id
                  : initialValues.editingId
              )
              .then(() => {
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successUnblockUser"),
                    },
                  },
                });
                setPagination({ filterMode, numberOfPage: current_page });
              });
          } else {
            api.employees
              .toLockEmployee(
                initialValues.employee
                  ? initialValues.employee.id
                  : initialValues.editingId
              )
              .then(() => {
                setPopUpSuccess({
                  response: {
                    succes: true,
                    status: t("success"),
                    data: {
                      message: t("successBlockUser"),
                    },
                  },
                });
                setPagination({ filterMode, numberOfPage: current_page });
              });
          }
          closeEditing();
        }
        if (!edit) {
          console.log("adding");
          if (formValues.employeeType.value === "doc") {
            if (values.sex === t("maleSex")) {
              values.gender = 0;
            } else {
              values.gender = 1;
            }
            return createDoctor(values).then(async ({ res }) => {
              if (res.code) {
                setLoading(false);
                for (let key in res.errors) {
                  const inputNameWithError = key.split(".").pop();
                  throw new SubmissionError({
                    [inputNameWithError]: res.errors[key],
                  });
                }
                return;
              }
              // let { entity: { id } } = res;
              // console.log(res);
              let {
                entity: {
                  employee: { id },
                },
              } = res;
              if (blob) {
                await api.employees.uploadPhoto(id, blob).then((res) => {
                  console.log(
                    // res,
                    "Upload photo in employee with id:" + id + " success..."
                  );
                  setBlob(null);
                });
              }
              // api.user.sendPassLinkOnEmail(id).then(() => {
              setPagination({ filterMode, numberOfPage: current_page });
              setLoading(false);
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successDoctorCreate"),
                  },
                },
              });
              // });
              closeEditing();
            });
          }
          if (formValues.employeeType.value === "operator") {
            if (values.sex === t("maleSex")) {
              values.gender = 0;
            } else {
              values.gender = 1;
            }
            if (values.insuranceCompany) {
              insuranceCompanies &&
                insuranceCompanies.map((item) => {
                  if (item.title === values.insuranceCompany) {
                    values.insuranceCompany = item.id;
                  }
                });
            }
            return createOperator(values).then(({ res }) => {
              if (res.code) {
                setLoading(false);
                for (let key in res.errors) {
                  const inputNameWithError = key.split(".").pop();
                  throw new SubmissionError({
                    [inputNameWithError]: res.errors[key],
                  });
                }
                return;
              }
              let {
                entity: { id },
              } = res;
              // console.log(res, id);
              if (blob) {
                api.employees.uploadPhoto(id, blob).then((res) => {
                  console.log(
                    // res,
                    "Upload photo in employee with id:" + id + " success..."
                  );
                  setBlob(null);
                  setLoading(false);
                });
              }
              // api.user.sendPassLinkOnEmail(id).then(() => {
              setPopUpSuccess({
                response: {
                  succes: true,
                  status: t("success"),
                  data: {
                    message: t("successOperatorCreate"),
                  },
                },
              });
              setLoading(false);
              setPagination({ filterMode, numberOfPage: current_page });
              // });
              closeEditing();
            });
          }
        }
      })();
    }
  };

  return (
    <div className="wrappNewEmployeeForm">
      <div className={`formTitle ${edit ? "edit" : ""}`}>
        {`
      ${edit ? t("newScheduleFilterFormTitleEdit") : t("newEmployee")} `}
        {edit && (
          <img src={close} alt="arrow-icon" onClick={() => closeEditing()} />
        )}
      </div>
      <Form
        className="newEmployeeForm"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <Field
          name="fillType"
          component={Toggle}
          options={[
            { value: "hand", text: t("manually") },
            { value: "file", text: t("fromFile") },
          ]}
        />

        {!edit ? (
          <Field
            name="employeeType"
            component={ToggleImg}
            options={[
              {
                value: "doc",
                text: t("doctor"),
                imgActive: activeDocIcon,
                imgInactive: inActiveDocIcon,
              },
              {
                value: "operator",
                text: t("operator"),
                imgActive: activeOperatorIcon,
                imgInactive: inActiveOperatorIcon,
              },
            ]}
          />
        ) : (
          <div className="employeePreviewCard">
            <div className="title">
              {formValues.employeeType.value === "doc"
                ? t("doctor")
                : t("operator")}
            </div>
            <div className="flex alignCenter">
              {formValues && formValues.photo && formValues.photo.cropImg && (
                <img
                  className="cropPhoto"
                  src={formValues.photo.cropImg}
                  alt="avatar-icon"
                />
              )}
              <div className="details">
                <span className="name">
                  {formValues.fullName ||
                    (formValues.employee && formValues.employee.fullName)}
                </span>
                {((formValues.doctor && formValues.doctor.educationSpecialty) ||
                  (formValues && formValues.educationSpecialty)) && (
                  <span className="spec">
                    {(formValues.doctor &&
                      formValues.doctor.educationSpecialty) ||
                      formValues.educationSpecialty}
                  </span>
                )}
                {/*<div className="details">*/}
                {/*  <span className="name">*/}
                {/*    {formValues.fullName ||*/}
                {/*      (formValues.employee && formValues.employee.fullName)}*/}
                {/*  </span>*/}
                {/*  {((formValues.doctor && formValues.doctor.educationSpecialty) ||*/}
                {/*    (formValues && formValues.educationSpecialty)) && (*/}
                {/*      <span className="spec">*/}
                {/*        {(formValues.doctor &&*/}
                {/*          formValues.doctor.educationSpecialty) ||*/}
                {/*          formValues.educationSpecialty}*/}
                {/*      </span>*/}
                {/*    )}*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        )}

        <div className={`formContent ${loading ? "relative" : ""}`}>
          {formValues.fillType && formValues.fillType.value === "hand" ? (
            <>
              {loading && (
                <div className="absoluteCenter">
                  <Spinner />
                </div>
              )}
              {!loading &&
                formValues.employeeType &&
                formValues.employeeType.value === "doc" && (
                  <Doc
                    formValues={formValues}
                    setAddingImg={setAddingImg}
                    addingImg={addingImg}
                    setEditingOff={setEditingOff}
                    initialValues={initialValues}
                    reset={reset}
                    setInitalValues={setInitalValues}
                    fillType={formValues.fillType}
                    specializations={specialities.map((spec, i) => ({
                      imgUrl: spec.imageUrl,
                      title: spec.title,
                      id: spec.id,
                    }))}
                    setBlob={setBlob}
                    isBlocked={formValues.isBlocked}
                    t={t}
                  />
                )}
              {!loading &&
                formValues.employeeType &&
                formValues.employeeType.value === "operator" && (
                  <Operator
                    formValues={formValues}
                    setAddingImg={setAddingImg}
                    addingImg={addingImg}
                    setEditingOff={setEditingOff}
                    initialValues={initialValues}
                    reset={reset}
                    setInitalValues={setInitalValues}
                    fillType={formValues.fillType}
                    setBlob={setBlob}
                    isBlocked={formValues.isBlocked}
                    insuranceCompanies={insuranceCompanies}
                    t={t}
                  />
                )}
            </>
          ) : (
            <Field
              name="employeeFile"
              // validate={[]}
              component={FileUploader}
              // disabled={!doctors || (formValues && !formValues.specialty)}
            />
          )}
        </div>
        <div className="formFooter">
          {!edit ? (
            <Button
              className="btn"
              type="submit"
              disabled={!dirty || invalid}
              text={t("createButton")}
            />
          ) : (
            <>
              {!formValues.isBlocked && (
                <Button className="btn" type="submit" text={t("saveButton")} />
              )}
              <div>
                <Button
                  className="btn"
                  danger
                  onClick={() => setDeleting(true)}
                  type="submit"
                  text={t("deleteButton")}
                />
                <Button
                  className="btn"
                  secondary
                  type="submit"
                  text={
                    locking || formValues.isBlocked
                      ? t("unLockButton")
                      : t("lockButton")
                  }
                  onClick={() => {
                    setLocking(true);
                    // api.employees.toLockEmployee();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  createOperator,
  resetInitialValues,
  updateOperator,
  createDoctor,
  getAllSpecializations,
  updateDoctor,
  deleteDoctor,
  deleteEmployee,
  getAllEmployees,
  setPagination,
  setNetworkError,
  getInsuranceCompanies,
  setPopUpSuccess,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("employee")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  insuranceCompanies: state.employees && state.employees.insuranceCompanies,
  filterMode: state.employees && state.employees.filterMode,
  current_page: state.employees && state.employees.current_page,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "employee", enableReinitialize: true })
);

export default enhance(NewEmployeeForm);
