import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  getSubscribers,
  setPagination,
} from "../../../redux/subscriptions/actions";

import patientLogo from "../../../style/icons/Subscription/patient_active.svg";
// import editIcon from "../../../style/icons/PersonEdit/person-edit.svg";
import backArrow from "../../../style/icons/ArrowLeftLight/arrow-left.svg";

import Paginator from "../../components/Paginator/Paginator";
import Spinner from "../../components/ui/Spinner/Spinner";

import "./SubscribersList.scss";

const SubscribersList = ({
  items,
  getSubscribers,
  page_count,
  current_page,
  setPagination,
  edit,
  setEdit,
}) => {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSubscribers(params.id).then(() => setLoading(false));
  }, []);

  const redirectToSubscriptionList = () => {
    history.push("/home/admin/subscription");
  };

  return (
    <div className="subscribersListWrapper">
      <div className="title">
        <img
          className="backArrow"
          src={backArrow}
          alt=""
          onClick={() => {
            redirectToSubscriptionList();
          }}
        />
        <span className="pageTitle">{t("subscribers")}</span>
      </div>
      <div className="subscribersListItemsContainer">
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
        {!loading && (
          <SubscribersListItem
            edit={edit}
            setEdit={setEdit}
            items={items}
            t={t}
          />
        )}
      </div>
      {page_count !== 0 && (
        <Paginator
          id={params.id}
          current_page={current_page}
          setPagination={setPagination}
          page_count={page_count}
          scrollElement="subscribersListItemsContainer"
          setLoading={setLoading}
          intervalDisabled
        />
      )}
    </div>
  );
};

const SubscribersListItem = ({ items, t, edit, setEdit }) => (
  <>
    {items &&
      items.map((patient) =>
        patient.patientCards.map((card, i) => {
          if (card.relationShip.uniqueRelation === 1)
            return (
              <div key={i} className={`subscribersListItem`}>
                <img
                  className="subscriberAvatar"
                  src={card.photoUrl === "" ? patientLogo : card.photoUrl}
                  alt="user-avatar"
                />
                <p className="subscriberTitle">Пациент</p>
                <p className="subscriberFullName">{card.fullName}</p>
                <p className="subscriberPoliceNumber">
                  <span className="text-grey">{t("policeNumber")}:</span>{" "}
                  {patient.policeNumber}
                </p>
                <p className="insuranceDateStart">
                  <span className="text-grey">{t("policeStartDate")}:</span>{" "}
                  {moment(patient.companySubscriptionBookedAt).format("L")}
                </p>
                <p className="insuranceDateEnd">
                  <span className="text-grey">{t("policeExpireDate")}:</span>{" "}
                  {moment(patient.companySubscriptionExpiredAt).format("L")}
                </p>
                <div className="subscriberFooter">
                  <div className="idBlock">
                    <span className="itemIdTitle">ID</span>
                    <span className="itemIdValue">#{card.id}</span>
                  </div>
                  {/*<img*/}
                  {/*  className="editIcon"*/}
                  {/*  src={editIcon}*/}
                  {/*  alt="edit icon"*/}
                  {/*  onClick={() => {*/}
                  {/*    setEdit();*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
              </div>
            );
        })
      )}
  </>
);
const mapStateToProps = ({ subscription }) => ({
  page_count: subscription && subscription.page_count,
  current_page: subscription && subscription.current_page,
  items: subscription && subscription.items,
});

export default connect(mapStateToProps, {
  setPagination,
  getSubscribers,
})(SubscribersList);
