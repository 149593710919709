import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import api from "../../../api";
import { email, phoneFormat, voidRequired } from "../../../helpers/validate";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllCompanies } from "../../../redux/сompanies/actions";

import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";

import DatePicker from "../../components/formComponent/DatePicker/DatePicker";
import Input from "../../components/formComponent/Input/Input";
import Selecter from "../../components/formComponent/Select/Select";
import Button from "../../components/ui/Button/Button";

import "./NewCompanyForm.scss";

const NewCompany = ({ formValues = {}, setInitalValues, reset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formSubmit = (e) => {
    e.preventDefault();
    console.log("submit", formValues);
    const data = {
      division: {
        title: formValues.title,
        employee: {
          email: formValues.email,
          phone: formValues.phone,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          patronymic: formValues.patronymic,
          birthDate: formValues.birthDate,
          gender: formValues.gender,
          plainPassword: formValues.email,
        },
      },
    };
    return api.companies
      .createCompany(data)
      .then(() => resetInitialValues())
      .then(() => reset())
      .then(() => {
        dispatch(getAllCompanies());
      });
  };

  return (
    <div className="new-company-form-container">
      <p className="form-title">{t("createCompany")}</p>
      <Form
        className="new-company-form"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <div className="form-content">
          <Field
            name="title"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("company")}
            // disabled={isBlocked}
          />
          <Field
            name="firstName"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("name")}
            // disabled={isBlocked}
          />

          <Field
            name="lastName"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("lastName")}
            // disabled={isBlocked}
          />

          <Field
            name="patronymic"
            validate={[voidRequired]}
            component={Input}
            placeholder={t("patronymic")}
            // disabled={isBlocked}
          />

          <div className="dateOfBith">
            <Field
              validate={[voidRequired]}
              name={`birthDate`}
              inputProps={{
                name: "birthDate",
              }}
              component={DatePicker}
              placeholder={t("birthDate")}
              validDate={{ number: 16, type: "year", period: "isBefore" }}
            />
            <img
              className="closeImg"
              src={close}
              alt="close"
              onClick={async () => {
                await setInitalValues({
                  ...formValues,
                  birthDate: null,
                });
                let picker = document.getElementsByClassName("form-control");
                for (let i = 0; i < picker.length; i++) {
                  const element = picker[i];
                  element.value = "";
                }
              }}
            />
            <img className="calendarImg" src={calendar} alt="calendar" />
          </div>

          <Field
            name="sex"
            validate={[voidRequired]}
            options={[t("maleSex"), t("femaleSex")]}
            component={Selecter}
            placeholder={t("gender")}
            // disabled={isBlocked}
          />

          <Field
            name="email"
            validate={[voidRequired, email]}
            component={Input}
            placeholder={t("email")}
            // disabled={isBlocked}
          />

          <Field
            name="phone"
            validate={[voidRequired, phoneFormat]}
            component={Input}
            placeholder={t("phoneNumber")}
            // disabled={isBlocked}
          />
        </div>
        <div className="form-footer">
          <Button text={t("createButton")} className="btn" type="submit" />
        </div>
      </Form>
    </div>
  );
};

const mapDispatchToProps = { setInitalValues, resetInitialValues };

const mapStateToProps = (state) => ({
  formValues: getFormValues("NewCompanyForm")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "NewCompanyForm", enableReinitialize: true })
);

export default enhance(NewCompany);
