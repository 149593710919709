import React, { useEffect } from "react";
import DatePicker from "react-datetime";
import moment from "moment";

import { formatDate } from "../../../../helpers/values";

import cm from "classnames";
import s from "./datePicker.scss";

import "moment/locale/uk";
import "moment/locale/ru";

import "react-datetime/css/react-datetime.css";

const DatePickerUI = ({
  name,
  disabled,
  value,
  onChange,
  visited,
  error,
  placeholder,
  validDate,
  closeOnSelect = true,
  touched,
  ...props
}) => {
  useEffect(() => {
    // console.log(localStorage.getItem('lang'));
    moment.locale(localStorage.getItem("lang"));
  }, []);

  var adulthood =
    validDate && moment().subtract(validDate.number, validDate.type);

  const valid = (current) =>
    validDate.period
      ? current[validDate.period](adulthood)
      : validDate(current);

  (value &&
    value.value &&
    value.value.indexOf("Z") !== -1 &&
    onChange(value && value.value.substr(0, 10))) ||
    (value && value.indexOf("Z") !== -1 && onChange(value.substr(0, 10)));

  // console.log(value);

  return (
    <>
      <DatePicker
        className={cm("datePicker", {
          error: visited && error,
        })}
        name={name}
        value={value && moment(value, formatDate).format("DD/MM/YYYY")}
        timeFormat={false}
        closeOnSelect={closeOnSelect}
        dateFormat="DD/MM/YYYY"
        onChange={onChange}
        inputProps={{ placeholder }}
        isValidDate={validDate && valid}
        viewDate={validDate ? new Date(adulthood) : new Date()}
        disabled={disabled}
        {...props}
      />
      {/* {console.log("children", props.children)} */}
      {props.children && props.children && (
        <div className="children flex">{props.children}</div>
      )}
    </>
  );
};

export default DatePickerUI;
