import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import docAva from "../../../style/icons/Doc/doc-blue.svg";
import plus from "../../../style/icons/PlusInCircle/plus-white.svg";
import patient from "../../../style/icons/Subscription/patient_active.svg";

import Button from "../ui/Button/Button";

import PatientsSearchList from "../PatientsSearchList/PatientsSearchList";

import "./DoctorsListBySearch.scss";

const FoundDoctorsList = ({
  items = [],
  values,
  role,
  patients = [],
  searchKey,
  list = [],
}) => {
  const [clients, setClients] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    let arr = values && values.search.split(" ");
    patients &&
      patients.map((pat) => {
        let patName = pat.patientCard.fullName;
        if (values && values.search === patName) {
          clients.push(pat);
        }
      });
    setClients(clients);
  }, [values]);

  return (
    <>
      {role === "admin" && values && values.search && (
        <div className={"foundDoctorsWrapper with-scrollbar"}>
          {items.map((doctor) => (
            <div key={doctor.id} className="doctorUnit">
              <div className={"docPhotoAndName"}>
                <img
                  src={
                    doctor && doctor.photoUrl
                      ? doctor && doctor.photoUrl
                      : patient
                  }
                  className="doctorPhoto"
                  alt={"doctor photo"}
                />
                <span className={"doctorName"}>
                  {doctor && doctor.fullName}
                </span>
              </div>
              <div className="smallContent">
                <div className="specialtyContent">
                  {doctor &&
                    doctor.specialty &&
                    doctor.specialty.map((spec, i) => (
                      <span key={i} className="specialty">
                        {`${spec && spec.title}${
                          i !== doctor.specialty.length - 1 ? ", " : ""
                        }`}
                      </span>
                    ))}
                </div>
                <div className={"phoneContent"}>
                  <div className={"phone"}>{t("phone")}</div>
                  <div className={"phoneNumber"}>{doctor && doctor.phone}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {(role === "operator" || role === "doctor") &&
        values &&
        values.search &&
        searchKey &&
        list && (
          <div
            className={`notFound ${
              list.length === 0 && role === "operator" ? "emptyList" : "filled"
            }`}
          >
            {list.length !== 0 && <PatientsSearchList role={role} />}
            {list.length === 0 && (
              <>
                <div className={"text"}>{t("patientNotFound")}</div>
                <NavLink to={`/home/${role}/newClient`}>
                  {role === "operator" && (
                    <Button
                      className={"btn alignCenter flex"}
                      withIcon
                      icon={plus}
                      type="button"
                      text={t("createUserProfileButton")}
                    />
                  )}
                </NavLink>
              </>
            )}
          </div>
        )}
    </>
  );
};
const mapStateToProps = ({ employees, form, monitoring, patients }) => ({
  employees,
  form,
  monitoring,
  items: employees && employees.data && employees.data.items,
  values: form && form.search && form.search.values,
  patients: monitoring && monitoring.items,
  list: patients && patients.searchList,
  searchKey: patients && patients.searchKey,
});

const DoctorsListBySearch = compose(connect(mapStateToProps, null));

export default DoctorsListBySearch(FoundDoctorsList);
