import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = null;

const subscriptionReducer = createReducer(initialState)({
  [types.GET_ALL_SUBSCRIPTIONS]: (state, { subscriptions }) => ({
    ...state,
    subscriptions,
  }),
  [types.GET_SUBSCRIPTED_USERS]: (
    state,
    {
      items,
      filterMode,
      current_page,
      page_count,
      items_per_page,
      total_item_count,
    }
  ) => {
    return {
      items,
      filterMode,
      current_page,
      page_count,
      items_per_page,
      total_item_count,
    };
  },
});

export default subscriptionReducer;
