import * as types from "./types";
import api from "../../api";

export const getAllChatMessagesTemplates = () => (dispatch) =>
  api.chat.getChatMessagesTemplates().then((data) => {
    return dispatch({
      type: types.GET_CHAT_MESSAGES_TEMPLATES,
      ...data,
    });
  });

export const setFullScreenVideoMode = (mode = false) => (dispatch) => {
  if (typeof mode === "boolean") {
    return dispatch({
      type: types.SET_FULL_SCREEN_VIDEO_MODE,
      mode,
    });
  }
};
