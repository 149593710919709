import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setSubs } from "../../../redux/subscriptions/actions";

import "./SubscriptionList.scss";

const SubscriptionList = ({ setSubs, subscriptions, token }) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setSubs();
  }, [token]);

  const redirectToSubscribers = (id) => {
    history.push(`/home/admin/subscription/subscribers/${id}`);
  };

  return (
    <div className="dashBoardContainer">
      {subscriptions &&
      subscriptions.subscriptions.items.length > 0 &&
      subscriptions.companies.items.length > 0 ? (
        <>
          {/*{subscriptions && subscriptions.subscriptions.items.length > 0 && (*/}
          {/*  <>*/}
          {/*    <div className="title">{t("subscriptions")}</div>*/}
          {/*    <div className="subscription-wrapper">*/}
          {/*      {subscriptions.subscriptions.items.map((item, i) => {*/}
          {/*        return (*/}
          {/*          <div key={i} className="subscription-item">*/}
          {/*            <p className="subscription-header">{t("rates")}</p>*/}
          {/*            <p className="subscription-title">{item.title}</p>*/}
          {/*            <p className="subscription-amount">*/}
          {/*              {item.usersCount}*/}
          {/*              <span className="subscription-patient">*/}
          {/*                {t("patients")}*/}
          {/*              </span>*/}
          {/*            </p>*/}
          {/*          </div>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
          {subscriptions && subscriptions.companies.items.length > 0 && (
            <>
              <div className="title">{t("companies")}</div>
              <div className="subscription-wrapper">
                {subscriptions.companies.items.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        // Что-то не так с переходом по айди компании
                        redirectToSubscribers(item.id);
                      }}
                      key={index}
                      className="subscription-item"
                    >
                      <p className="subscription-header">{t("rates")}</p>
                      <p className="subscription-title">
                        {item.subscription.title}
                      </p>
                      <p className="subscription-amount">
                        {item.subscription.usersCount}
                        <span className="subscription-patient">
                          {t("patients")}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </>
      ) : (
        <p className="empty-list">{t("emptyList")}</p>
      )}
    </div>
  );
};

const mapStateToProps = ({ subscription, user }) => ({
  subscriptions: subscription && subscription.subscriptions,
  token: user && user.token,
});

export default connect(mapStateToProps, { setSubs })(SubscriptionList);
