import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Peer from "simple-peer";

import Button from "../ui/Button/Button";
import checkSign from "../../../style/icons/CheckSign/CheckSign.svg";
import checkSignReaded from "../../../style/icons/CheckSign/CheckSignReaded.svg";
import call from "../../../style/icons/PhoneCall/phone-call.svg";
import video from "../../../style/icons/VideoCall/video-call.svg";
import chatSearch from "../../../style/icons/Search/searche-blue-icon.svg";
import info from "../../../style/icons/InfoSign/info-sign-blue.svg";
import infoRed from "../../../style/icons/InfoSign/info-sign-red.svg";
import arrow_right from "../../../style/icons/Chat/arrow-right.svg";
import close_icon from "../../../style/icons/Chat/close-icon.svg";

import stop_call from "../../../style/icons/Chat/stop_call.svg";
import muted_video from "../../../style/icons/Chat/muted_video.svg";
import muted_audio from "../../../style/icons/Chat/muted_audio.svg";
import make_full_screen from "../../../style/icons/Chat/make_full_screen_video.svg";
import close_full_screen from "../../../style/icons/Chat/close_full_screen_video.svg";
import microphone from "../../../style/icons/Chat/microphone.svg";

import io from "socket.io-client";

import "./Chat.scss";
import { connect } from "react-redux";
import api, { PORT_SUPPORT_SOCKET } from "../../../api";

import attach_file from "../../../style/icons/Chat/attach-file.svg";
import open_menu from "../../../style/icons/Chat/open-menu.svg";
import { useHistory, withRouter } from "react-router-dom";
import ModalWindow from "../ModalWindow/ModalWindow";
import Spinner from "../ui/Spinner/Spinner";
import {
  endOfConsultation,
  cancelOfConsultationFlagSet,
} from "../../../redux/patients/actions";
import {
  getConsultationAnamnesis,
  getCurrentConsultation,
} from "../../../redux/consultations/actions";
import { setNetworkError } from "../../../redux/networkError/actions";
import { bytesToSize } from "../../../helpers/functions";
import { useTranslation } from "react-i18next";
import {
  SIGNALING_SERVER,
  PORT_CHAT_SOCKET,
  PORT_CALL_SOCKET,
} from "../../../api/index";

const TemplatesModalContent = (props) => {
  const [selectedGroup, setSelectedGroup] = useState(
    props.templates.length > 0 ? props.templates[0].id : 0
  );
  const { t } = props;

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleMessageClick = (text) => {
    if (props.handleMessageClick) {
      props.handleMessageClick(text);
    }
  };

  return (
    <div className="templates-modal-content">
      <div className="templates-header">
        <span>{t("templateTitle")}</span>
        <div className="icon-button" onClick={handleClose}>
          <img src={close_icon} alt="Закрыть" />
        </div>
      </div>
      <div className="templates-content">
        <div className="groups">
          {props.templates.map((item) => {
            const className =
              item.id === selectedGroup
                ? "templates-modal-item selected"
                : "templates-modal-item";
            return (
              <div
                className={className}
                key={item.id}
                onClick={() => setSelectedGroup(item.id)}
              >
                <span>{item.title}</span>
                <img src={arrow_right} alt="" />
              </div>
            );
          })}
        </div>
        <div className="messages-list">
          {props.templates.length > 0 &&
            props.templates
              .find((item) => item.id === selectedGroup)
              .templates.map((item) => {
                return (
                  <div
                    className="templates-modal-item"
                    onClick={() => handleMessageClick(item.text)}
                    key={item.id}
                  >
                    <span>{item.text}</span>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

const HelpModalContent = (props) => {
  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const { t } = props;
  return (
    <div className="templates-modal-content help-modal">
      <div className="templates-header modal-header">
        <div
          className="icon-button help-modal-icon-button"
          onClick={handleClose}
        >
          <img src={close_icon} alt="Закрыть" />
        </div>
      </div>
      <div className="templates-content help-modal-content">
        <img src={infoRed} alt="red-info-icon" />
        <p className="help-modal-header">{t("help")}</p>
        <p className="help-modal-text">
          {t("infoText")}
          <br />
          <span className="help-modal-text-phone">080 033 00 36</span>
        </p>
        <p className="help-modal-text">
          {t("infoMedQueation")}
          <br />
          <span className="help-modal-text-phone">080 033 00 36</span>
        </p>
        <div className="help-modal-footer">
          <button className="help-modal-buttons red-button">
            {t("techSupport")}
          </button>
          <button className="help-modal-buttons blue-button">
            {t("medQuestion")}
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageModalContent = (props) => {
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => setLoader(false), 500);
  }, []);

  return (
    <div className="templates-modal-content image-modal">
      {loader ? (
        <Spinner />
      ) : (
          <>
            <div className="templates-header modal-header image-header">
              <div
                className="icon-button help-modal-icon-button"
                onClick={handleClose}
              >
                <img src={close_icon} alt="Закрыть" />
              </div>
            </div>
            <div className="templates-content image-modal-content">
              <img src={props.imgUrl} alt="userImage" />
            </div>
          </>
        )}
    </div>
  );
};

const MessageComponent = ({ item, token, t, bothInChat }) => {
  const [isOpenImageModal, toggleImageModal] = useState(false);

  let className =
    item.isSentByUser || item.chat_bot_doc === "1"
      ? "messageText occupantMessage"
      : "messageText";
  const time = moment(item.dateSent).format("HH:mm");

  // console.log(item.status);
  if (item.type === "file" || item.type === "image" || item.type === "video")
    className += " with-media";

  const getUrl = (url = undefined) => {
    if (
      item.type === "file" ||
      item.type === "image" ||
      item.type === "video"
    ) {
      // const wotoken = url ? url.split("token=") : item.message.split("token=");
      return item.message;
    } else {
      return item.message;
    }
  };

  if (item.type === "file" && className.includes("occupantMessage")) {
    // console.log(file)
    className += " with-media";
    return (
      <div className={className}>
        {item.type.includes("video") ? (
          <video controls src={item.message} />
        ) : item.type.includes("image") ? (
          <>
            <img
              src={item.message}
              alt="occupantImage"
              onClick={() => toggleImageModal(true)}
            />
            <br />
            <a target="_blank" href={item.message}>
              {item.customParameters.file_name}
            </a>
            {isOpenImageModal && (
              <ModalWindow
                isOpen={isOpenImageModal}
                toggleOpen={() => toggleImageModal(false)}
                t={t}
              >
                <ImageModalContent
                  imgUrl={getUrl()}
                  t={t}
                  handleClose={() => toggleImageModal(false)}
                />
              </ModalWindow>
            )}
          </>
        ) : (
              <a target="_blank" href={item.message}>
                {item.customParameters.file_name}
              </a>
            )}
        <div className="msgTime">
          <div className="msgDate">{time}</div>
          <img
            className="checkSign"
            src={bothInChat ? checkSignReaded : checkSign}
            alt="status-ico"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      {item.type === "file" ? (
        <a target="_blank" href={item.message}>
          {item.customParameters && item.customParameters.file_name}
        </a>
      ) : item.type === "image" ? (
        <>
          <img
            style={{ cursor: "pointer" }}
            src={item.message}
            alt="userImage"
            onClick={() => toggleImageModal(true)}
          />
          <br />
          <a target="_blank" href={item.message}>
            {item.customParameters && item.customParameters.file_name}
          </a>
          {isOpenImageModal && (
            <ModalWindow
              isOpen={isOpenImageModal}
              toggleOpen={() => toggleImageModal(false)}
              t={t}
            >
              <ImageModalContent
                t={t}
                imgUrl={getUrl()}
                handleClose={() => toggleImageModal(false)}
              />
            </ModalWindow>
          )}
        </>
      ) : item.type === "video" ? (
        <video controls src={item.message} />
      ) : (
              <span>{item.message}</span>
            )}
      <div className="msgTime">
        <div className="msgDate">{time}</div>
        <img
          className="checkSign"
          src={+item.status === 2 || bothInChat ? checkSignReaded : checkSign}
          alt="status-ico"
        />
      </div>
    </div>
  );
};

const configuration = {
  // initiator: true,
  iceServers: [
    // { urls: "stun:91.231.55.155:3478" },
    // { urls: "stun:stun.l.google.com:19302" },
    // { urls: "stun:stun1.l.google.com:19302" },
    // { urls: "stun:stun2.l.google.com:19302" },
    // { urls: "stun:stun3.l.google.com:19302" },
    // { urls: "stun:stun4.l.google.com:19302" },
    // { urls: "stun:relay1.gooddoc.fun:3478" },
    { urls: "stun:relay1.gooddoc.fun:80" },
    // {
    //   urls: "turn:relay1.gooddoc.fun:3478",
    //   username: "domus",
    //   credential: "sPy80Hb7o("
    // },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=udp",
      username: "domus",
      credential: "sPy80Hb7o("
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=tcp",
      username: "domus",
      credential: "sPy80Hb7o("
    },
  ]
};

var peerConnection = new RTCPeerConnection(configuration);
const Chat = ({
  appointments,
  getCurrentConsultation,
  getConsultationAnamnesis,
  endOfConsultationFlag,
  cancelledConsultationFlag,
  cancelOfConsultationFlagSet,
  reasonOfEndingConsultation,
  endOfConsultation,
  setStarted,
  started,
  setNetworkError,
  patientCard,
  doctor,
  ...props
}) => {
  const { t } = useTranslation();
  const { sendPush } = props;

  const [isOpenTemplatesModal, toggleTemplatesModal] = useState(false);
  const [isOpenHelpModal, toggleHelpModal] = useState(false);

  const [messages, setMessages] = useState([]);
  const [videoCall, setVideoCall] = useState(false);
  const [audioCall, setAudioCall] = useState(false);
  const [videoFullScreen, setVideoFullScreen] = useState(false);
  const [mutedAudio, setMutedAudio] = useState(false);
  const [mutedVideo, setMutedVideo] = useState(false);
  const [videoSession, setVideoSession] = useState(undefined);
  const [bothInChat, setBothInChat] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [bothInCall, setBothInCall] = useState(false);
  const [answeredCall, setAnseredCall] = useState(false);
  const [
    failClosedDissconnection_FLAG,
    setFailedClosedDissconnection_FLAG,
  ] = useState(false);

  const [loadingChat, setLoadingChat] = useState(true);

  const [fileAttach, setFileAttach] = useState(false);

  const [status, setStatus] = useState();

  const socket = useRef();
  const callSocket = useRef();
  const chat = useRef();

  const [a, setA] = useState();
  const [b, setB] = useState();

  const partnerVideo = useRef();
  const userVideo = useRef();

  const [localStream, setLocalStream] = useState();

  const [connectedFlag, setConnectedFlag] = useState();
  const [resendSDPFlag, setResendSDPFlag] = useState();
  const [textarea_rows, setTextareaRows] = useState(1)

  const history = useHistory();

  const iceCandidates = [];
  const URL = SIGNALING_SERVER + PORT_CHAT_SOCKET;
  const CALL_URL = SIGNALING_SERVER + PORT_CALL_SOCKET;
  const SUPPORT_CHAT_URL = SIGNALING_SERVER + PORT_SUPPORT_SOCKET;

  // console.log({ URL });

  let PartnerVideo;
  PartnerVideo = (
    <video
      playsInline
      ref={partnerVideo}
      autoPlay
      className={audioCall ? "myVideo hidden" : "myVideo"}
    />
  );
  let UserVideo;
  UserVideo = (
    <video
      playsInline
      ref={userVideo}
      autoPlay
      className={audioCall ? "opponentVideo hidden" : "opponentVideo"}
    />
  );

  async function makeCall() {
    // const remoteDesc = new RTCSessionDescription(message.answer);
    // await peerConnection.setRemoteDescription(remoteDesc);
    const offer = await peerConnection.createOffer({
      iceRestart: true,
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    });

    console.log(offer);

    await peerConnection.setLocalDescription(offer);

    // setConnectedFlag(true);

    // if (partnerVideo.current && localStream) {
    //   partnerVideo.current.srcObject = localStream;
    // }

    callSocket.current.send(
      JSON.stringify({
        type: "offer",
        offer: offer.sdp,
      })
    );
  }

  async function catchAnswer({ answer }) {
    const remoteDesc = new RTCSessionDescription({
      type: "answer",
      sdp: /* answer.sdp || */ answer || answer.nameValuePairs.answer,
    });
    console.log(remoteDesc);
    await peerConnection.setRemoteDescription(remoteDesc);
  }

  peerConnection.onicecandidate = (rtcp, ev) => {
    console.log("onicecandidate", rtcp.candidate);
    if (rtcp.candidate) {
      iceCandidates.push(rtcp.candidate);
      callSocket.current.send(
        JSON.stringify({
          type: "candidate",
          candidate: rtcp.candidate,
          // {
          //   sdpMid: `${candidate && candidate.sdpMid}`,
          //   sdpMLineIndex: `${candidate && candidate.sdpMLineIndex}`,
          //   sdp: `${candidate && candidate.candidate}`,
          //   isLast: iceCandidates.length === i + 1 ? "1" : "0",
          // },
        })
      );
    }
    if (rtcp.candidate === null) {
      console.log("ice candidate finish");
      console.log(iceCandidates);
      // iceCandidates.forEach((candidate, i) => {
      //   callSocket.current.send(
      //     JSON.stringify({
      //       type: "candidate",
      //       candidate,
      //       // {
      //       //   sdpMid: `${candidate && candidate.sdpMid}`,
      //       //   sdpMLineIndex: `${candidate && candidate.sdpMLineIndex}`,
      //       //   sdp: `${candidate && candidate.candidate}`,
      //       //   isLast: iceCandidates.length === i + 1 ? "1" : "0",
      //       // },
      //     })
      //   );
      // });

      // callSocket.current.send(
      //   JSON.stringify({
      //     type: "candidate",
      //     customParameters: rtcp.candidate,
      //     // sdpMid: `${rtcp.candidate && rtcp.candidate.sdpMid}`,
      //     // sdpMLineIndex: `${rtcp.candidate && rtcp.candidate.sdpMLineIndex}`,
      //     // sdp: `${rtcp.candidate && rtcp.candidate.candidate}`,
      //   })
      // );
      console.log(iceCandidates);
    }
  };
  peerConnection.oniceconnectionstatechange = ({ currentTarget }) => {
    console.log("oniceconnectionstatechange");
    console.log("connectionState", currentTarget.connectionState);
  };
  peerConnection.onicegatheringstatechange = (a) => {
    console.log("onicegatheringstatechange", a);
  };

  peerConnection.onnegotiationneeded = (rtcp) => {
    console.log("onnegotiationneeded", rtcp);
    if (peerConnection.signalingState === "stable") {
      iceCandidates.forEach((candidate, i) => {
        console.log(
          "lastIndex ",
          i + 1,
          iceCandidates.length,
          iceCandidates.length === i + 1 ? "1" : "0"
        );
      });
    }
  };
  peerConnection.onsignalingstatechange = (a, s, d, q) => {
    console.log("onsignalingstatechange", a, s, d, q);
  };

  peerConnection.addEventListener(
    "connectionstatechange",
    ({ currentTarget }) => {
      console.log(
        "=====>>>>> connectionstatechange ",
        currentTarget.connectionState
      );
      if (
        currentTarget.connectionState === "failed" ||
        currentTarget.connectionState === "closed" ||
        currentTarget.connectionState === "disconnected"
      ) {
        setFailedClosedDissconnection_FLAG(true);
      }
    }
  );

  const remoteStream = new MediaStream();
  peerConnection.addEventListener("track", async (event) => {
    console.log("<<<<<<<<<TRACK>>>>>>>>", event, event.track);
    remoteStream.addTrack(event.track, remoteStream);
    try {
      userVideo.current.srcObject = remoteStream;
    } catch (e) {
      console.log("<<<<<<<<<TRACK _______ E R O R R>>>>>>>>", e);
    }
  });

  useEffect(() => {
    if (props.role === "doctor") {
      console.log("Док", { role: props.role });
      api.chat
        .getChatHistory(localStorage.getItem("currentConsultationId"))
        .then(({ data: { items } }) => {
          setMessages(items);
          setLoadingChat(false);
          let area = document.getElementById("messagesArea");
          if (area && area.scrollHeight) area.scrollTo(0, area.scrollHeight);
        })
        .catch((err) => setLoadingChat(false));
    } else {
      console.log("Саппорт", { role: props.role });

      api.chat
        .getOperatorChatHistory(localStorage.getItem("patientID"))
        .then(({ data: { items } }) => {
          console.log({ items });
          setMessages(items);
          setLoadingChat(false);
          let area = document.getElementById("messagesArea");
          if (area && area.scrollHeight) area.scrollTo(0, area.scrollHeight);
        })
        .catch((err) => setLoadingChat(false));

      chat.current = io.connect(SUPPORT_CHAT_URL + "/support", {
        forceNew: true,
        rejectUnauthorized: false,
        query: {
          userId: localStorage.getItem("patientID"),
          token: localStorage.getItem("token"),
        },
      });
    }
  }, []);

  // useEffect(() => {

  //   if (callSocket.current) {
  //     callSocket.current.disconnect();
  //   }

  // }, []);

  useEffect(() => {
    if (status !== "cancelled" && status !== "closed" && status !== undefined && status !== null) {

      // if (props.role !== 'operator') {
      chat.current = io(URL + "/chat", {
        forceNew: true,
        query: {
          appointmentId: localStorage.getItem("currentConsultationId"),
        },
      });
      // }
      callSocket.current = io.connect(CALL_URL + "/call", {
        forceNew: true,
        rejectUnauthorized: false,
        query: {
          userId: localStorage.getItem("patientID"),
          doctorId: doctor.doctor.id,
          doctorSpecialtyId:
            doctor.doctor.specialty && doctor.doctor.specialty[0].id,
        },
      });

    };

    // console.log(chat.current, callSocket.current, chat.current && callSocket.current);
    if (chat.current) {
      chat.current.on("chatRoomLeave", (data) => {
        console.log("emited  event", data);
        data > 1 ? setBothInChat(true) : setBothInChat(false);
        // stopCall({ clearClose: true })
      });

      chat.current.on("joinRoom", (data) => {
        console.log("emited  joinRoom event", data);
        data > 1 ? setBothInChat(true) : setBothInChat(false);
      });

      chat.current.on("message", (message) => {
        console.log("--------> ONMESSAGE <--------");
        console.log(message);
        let lastMsg = message;
        // +lastMsg.usersCount === 2 ? setBothInChat(true) : setBothInChat(false);
        // setTestMsg(true);
        if (
          message.type === "message" ||
          message.type === "file" ||
          message.type === "image" ||
          message.type === "video" ||
          message.type === "chatBot" ||
          message.type === "finished" ||
          message.type === "cancelation"
        ) {
          setMessages((p) => [...p, message]);
          const area = document.getElementById("messagesArea");
          if (area && area.scrollHeight) area.scrollTo(0, area.scrollHeight);
        }
      });
      // callSocket.current.onopen = (e) => {
      //   console.log("--------> ONOPEN CALL SOCKET <--------");
      //   console.log(e);
      // };
    }
    if (callSocket.current) {
      callSocket.current.on("message", (msg) => {
        if (audioCall || videoCall) {
          console.log("--------> ONMESSAGE CALL SOCKET <--------");
          console.log({ msg });

          if (msg.type === "connect") {
            console.log({ msg });
          }
          if (msg.type === "answer") {
            console.log("answer", msg);
            catchAnswer(msg);
          }
          if (msg.type === "candidate") {
            console.log("candidate", msg);
            const tmp = msg.candidate;
            console.log({ tmp });
            try {
              peerConnection.addIceCandidate(new RTCIceCandidate(tmp));
            } catch (er) {
              console.error(er);
            }
          }
          if (msg.type === "ready") {
            setAnseredCall(true);
            makeCall();
          }
          if (msg.type === "reject") {
            stopCall({ rejectFromOpponent: true });
            // sendRejectMessage(false);
          }
          if (msg.type === "ended") {
            stopCall({ clearClose: true });
          }
        }
      });

      callSocket.current.on("joinCall", (count) => {
        if (!audioCall && !videoCall) return;
        console.log("joinCall", count);
        // setBothInCall(false);
        if (count >= 2) {
          setBothInCall(true);
        } else {
          setBothInCall(false);
        }
      });

      callSocket.current.on("busy", () => {
        console.log("busy");
        callSocket.current.disconnect();
      });
    }

    return function () {
      if (chat.current) {
        chat.current.emit(
          "chatRoomLeave",
          localStorage.getItem("currentConsultationId")
        );
        chat.current.disconnect();
      }
      if (callSocket.current) {
        callSocket.current.disconnect();
      }
    };
  }, [status, audioCall, videoCall]);

  useEffect(() => {
    // console.log({ audioCall, videoCall, bothInCall });
    if ((audioCall || videoCall) && bothInCall) {
      console.log("outgoing call ", { audioCall, videoCall, bothInCall });
      callSocket.current.send(
        JSON.stringify({
          type: "call",
          customParameters: {
            call_type: audioCall ? "audio" : "video",
            doctor_id: `${doctor.doctor.id}`,
            doctor_name: doctor.fullName,
            doctor_photo: doctor.photoUrl,
            doctor_specialty:
              doctor.doctor.specialty && doctor.doctor.specialty[0].title,
            appointment_id: localStorage.getItem("currentConsultationId"),
          },
        })
      );
    }
  }, [audioCall, videoCall, bothInCall]);

  function callPeer(audio) {
    setFailedClosedDissconnection_FLAG(false);
    if (audioCall || videoCall) {
      stopCall({
        rejectFromOpponent: false,
        clearClose: answeredCall ? true : false,
      });
      return;
    }

    console.log("WEBRTC_SUPPORT", Peer.WEBRTC_SUPPORT);
    setResendSDPFlag(true);

    if (audio) {
      setAudioCall(true);
      api.chat.deviceCall({
        appointmentId: localStorage.getItem("currentConsultationId"),
        data: {
          type: 1,
          text: "Начало аудио звонка",
          userId: localStorage.getItem("patientID"),
          doctorId: `${doctor.doctor.id}`,
          doctor_name: doctor.fullName,
          doctorSpecialtyId: `${doctor.doctor.specialty && doctor.doctor.specialty[0].id
            }`,
        },
      });
    } else {
      setVideoCall(true);
      api.chat.deviceCall({
        appointmentId: localStorage.getItem("currentConsultationId"),
        data: {
          type: 2,
          text: "Начало видео звонка",
          userId: localStorage.getItem("patientID"),
          doctorId: `${doctor.doctor.id}`,
          doctorName: doctor.fullName,
          doctorSpecialtyId: `${doctor.doctor.specialty && doctor.doctor.specialty[0].id
            }`,
        }, // type: 1 for type 'call'
      });
    }

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setLocalStream(stream);
        const streamClone = new MediaStream();
        console.log(stream.getTracks());
        stream.getTracks().forEach((track) => {
          try {
            console.log({ track });
            peerConnection.addTrack(track, stream);
            if (track.kind === "video") streamClone.addTrack(track, stream);
            streamClone
              .getAudioTracks()
              .forEach((track) => console.log("streamClone =>>> ", { track }));
          } catch (e) {
            console.log(e);
            // debugger;
          }
          if (partnerVideo.current) {
            partnerVideo.current.srcObject = streamClone;
          }
        });
      });
  }

  useEffect(() => {
    if (connectedFlag) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          console.log(stream.getTracks());
          stream.getTracks().forEach((track) => {
            try {
              peerConnection.addTrack(track, stream);
            } catch (e) {
              console.log(e);
              // debugger;
            }
            if (partnerVideo.current) {
              partnerVideo.current.srcObject = stream;
            }
          });
        });
      setConnectedFlag(false);
    }
  }, [connectedFlag]);

  useEffect(() => {
    if (failClosedDissconnection_FLAG) {
      stopCall({ clearClose: true });
    }
  }, [failClosedDissconnection_FLAG]);

  const changeHandler = (e) => {
    setFileAttach(false);
    setLoadingChat(true);

    const inputFile = e.target.files[0];
    console.log(inputFile, "inputFile");

    const fileParams = {
      name: inputFile.name,
      file: inputFile,
      type: inputFile.type,
      size: bytesToSize(inputFile.size),
      public: false,
    };

    const isFileType = (fileType = "") => {
      if (fileType.includes("image")) {
        return "image";
      }
      if (fileType.includes("video")) {
        return "video";
      }
      if (
        fileType.includes("pdf") ||
        fileType.includes("application") ||
        fileType.includes("text")
      ) {
        return "file";
      }
    };

    if (props.role === "doctor") {
      api.chat
        .chatFileUpload({
          id: localStorage.getItem("currentConsultationId"),
          file: inputFile,
        })
        .then((res) => {
          chat.current.send(
            JSON.stringify({
              type: isFileType(inputFile.type),
              message: res.entity.file,
              dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              // isSentByUser: false,
              customParameters: {
                file_name: inputFile.name,
                size: bytesToSize(inputFile.size),
              },
            })
          );
        })
        .then(() => {
          setLoadingChat(false);
          console.log(chat.current, "SOCKET CURRENT");
        })
        .catch((err) => {
          setLoadingChat(false);
          console.log(err);
        });
    } else {
      api.chat
        .chatOperatorFileUpload({
          id: localStorage.getItem("patientID"),
          file: inputFile,
        })
        .then((res) => {
          chat.current.send(
            JSON.stringify({
              type: isFileType(inputFile.type),
              message: res.entity.file,
              dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
              // isSentByUser: false,
              customParameters: {
                file_name: inputFile.name,
                size: bytesToSize(inputFile.size),
              },
            })
          );
        })
        .then(() => {
          setLoadingChat(false);
          console.log(chat.current, "SOCKET CURRENT");
        })
        .catch((err) => {
          setLoadingChat(false);
          console.log(err);
        });
    }

    e.target.value = "";
  };

  const parseMessagesList = () => {
    let dates = [];
    for (let item of messages) {
      const date = moment(item.dateSent).format("YYYY-MM");
      if (!dates.includes(date)) {
        dates.push(date);
      }
    }
    // console.log({ dates });

    const mounths = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
    let groups = [];
    for (let date of dates) {
      const list = messages.filter(
        (item) => moment(item.dateSent).format("YYYY-MM") === date
      );
      const item_date = moment(list[0].dateSent).format("YYYY-MM");
      groups.push({
        date: `${item_date}`,
        list,
      });
    }
    // console.log({ groups });

    return groups.reverse();
  };

  const sendMessage = () => {
    // console.log({ chat: chat.current });
    chat.current.send(
      JSON.stringify({
        // id: 666,
        type: "message",
        message: messageValue,
        dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        // isSentByUser: false,
        customParameters: {
          some: "param",
        },
      })
    );

    // const TYPE_CHAT = 0;
    // const TYPE_PHONE = 1;
    // const TYPE_VIDEO = 2;
    // const TYPE_HOME = 3;
    // const TYPE_HOSPITAL = 4;
    if (props.role === "doctor") {
      api.notification.sendPushNotification({
        appointmentId: localStorage.getItem("currentConsultationId"),
        type: 0, // VIDEO = 2
        text: messageValue,
      });
    }
    if (props.role === "operator") {
      api.notification.sendPushNotificationFromOperatorRole(
        localStorage.getItem("patientID")
      );
    }
    // getChatHistory(dialogId);
    localStorage.removeItem(localStorage.getItem("currentConsultationId"));
    setMessageValue("");
  };

  const sendRejectMessage = (me) => {
    chat.current.send(
      JSON.stringify({
        type: "message",
        message: me ? t("missedCall") : t("canceledCall"),
        dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        isSentByUser: !me ? false : true,
        customParameters: {
          some: "param",
        },
      })
    );

    if (me) {
      callSocket.current.send(
        JSON.stringify({ type: "reject", customParameters: {} })
      );
    }
    setVideoCall(false);
    setAudioCall(false);
    setVideoFullScreen(false);
    setMutedAudio(false);
    setMutedVideo(false);
    // navigator.getUserMedia()
  };

  const sendFinishMessage = (cancelled, conclusionMsg) => {
    let msg = t(
      cancelled ? "canceledConsultationMessage" : "finishConsultationMessage"
    );
    if (!cancelled) {
      msg = msg + conclusionMsg;
    }
    chat.current.send(
      JSON.stringify({
        type: cancelled ? "cancelation" : "finished",
        message: msg,
        dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        customParameters: {
          some: "param",
        },
      })
    );
  };

  const handleTemplateMessage = (text) => {
    toggleTemplatesModal(false);

    chat.current.send(
      JSON.stringify({
        type: "message",
        message: text,
        dateSent: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        customParameters: {
          some: "sam some",
        },
      })
    );
    if (props.role === "operator") {
      api.notification.sendPushNotificationFromOperatorRole(
        localStorage.getItem("patientID")
      );
    } else {
      api.notification.sendPushNotification({
        // patientCardId: selected_user_id /* patientCard.patientCard.id, */,
        appointmentId: localStorage.getItem("currentConsultationId"),
        type: 0, // VIDEO = 2
      });
    }
  };

  const stopCall = ({ rejectFromOpponent, clearClose }) => {
    console.log({ rejectFromOpponent, clearClose });
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getTracks().forEach((tr) => {
          tr.stop();
        });
      });
    // console.log({ localStream });
    try {
      localStream.getTracks().forEach((track) => {
        track.stop();
        localStream.removeTrack(track);
        // localStream.removeTrack(tr);
        // stream.removeTrack(track);
      });
      setLocalStream(null);
    } catch (err) {
      console.log({ err });
    }

    // console.log({ answeredCall });

    // partnerVideo.current.srcObject = null
    // userVideo.current.srcObject = null

    peerConnection.close();
    peerConnection = null;
    peerConnection = new RTCPeerConnection(configuration);

    if (videoSession) {
      videoSession.stop({});
    }
    setVideoSession(undefined);
    setAnseredCall(false);
    setAudioCall(false);
    setVideoCall(false);
    setVideoFullScreen(false);
    setMutedAudio(false);
    setMutedVideo(false);
    if (!clearClose) {
      console.log("! clearClose");
      if (!answeredCall && !rejectFromOpponent) {
        sendRejectMessage(true);
      } else {
        sendRejectMessage(false);
      }
    }
  };

  useEffect(() => {
    localStream &&
      localStream
        .getAudioTracks()
        .forEach((track) => (track.enabled = !track.enabled));
  }, [mutedAudio]);

  useEffect(() => {
    localStream &&
      localStream
        .getVideoTracks()
        .forEach((track) => (track.enabled = !track.enabled));
  }, [mutedVideo]);

  useEffect(() => {
    props.setFullScreenVideoMode(videoFullScreen);
  }, [videoFullScreen]);

  useEffect(() => {
    // console.clear();
    setLoadingChat(true);
    // getToken();

    return () => {
      // localStorage.removeItem("currentConsultationId");
      localStorage.removeItem("patientID");
      stopCall({ clearClose: true });
    };
  }, []);

  useEffect(() => {
    if (endOfConsultationFlag || cancelledConsultationFlag) {
      // console.log("=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log({ endOfConsultationFlag });
      // console.log({ cancelledConsultationFlag });
      // console.log("=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      sendFinishMessage(
        cancelledConsultationFlag || false,
        reasonOfEndingConsultation
      );
      stopCall({ clearClose: true });
      setTimeout(() => {
        endOfConsultation(false);
        cancelOfConsultationFlagSet(false);
      }, 0);
    }
  }, [
    endOfConsultationFlag,
    cancelledConsultationFlag,
    reasonOfEndingConsultation,
  ]);

  useEffect(() => {
    if (props.role === "doctor") {
      getCurrentConsultation(
        localStorage.getItem("currentConsultationId")
      ).then((res) =>
        setStatus(
          res && res.currentConsultation && res.currentConsultation.status
        )
      );
    }
  }, []);

  useEffect(() => {
    const consultId = localStorage.getItem("currentConsultationId");
    if (localStorage.getItem("currentConsultationId") === consultId) {
      setMessageValue(localStorage.getItem(consultId));
    }
  }, []);
  // console.log(status);

  const keyActions = (event) => {
     if (event.keyCode===13 && event.ctrlKey) {
      let val = messageValue+'\r\n';
      setMessageValue(val);
      localStorage.setItem(
        localStorage.getItem("currentConsultationId"),
        val
      );
    } else if (event.keyCode===13) {
      event.preventDefault();
      sendMessage();
      setTextareaRows(1)
    }
  }

  const handleMessageChange = (e) => {
    setMessageValue(e.target.value);
    localStorage.setItem(
      localStorage.getItem("currentConsultationId"),
      e.target.value
    );
    const maxRows = 10;
    const minRows = 1;
    const textareaLineHeight = 16;
    const previousRows = e.target.rows;
  	e.target.rows = minRows; // reset number of rows in textarea
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
    	e.target.rows = currentRows;
    }
		if (currentRows >= maxRows) {
			e.target.rows = maxRows;
			e.target.scrollTop = e.target.scrollHeight;
    }
    setTextareaRows(currentRows < maxRows ? currentRows : maxRows)
  }

  return (
    <div className="doctorChat">
      {isOpenTemplatesModal && (
        <ModalWindow
          isOpen={isOpenTemplatesModal}
          toggleOpen={() => toggleTemplatesModal(false)}
        >
          <TemplatesModalContent
            t={t}
            templates={props.chat.items}
            handleClose={() => toggleTemplatesModal(false)}
            handleMessageClick={handleTemplateMessage}
          />
        </ModalWindow>
      )}
      {isOpenHelpModal && (
        <ModalWindow
          isOpen={isOpenHelpModal}
          toggleOpen={() => toggleHelpModal(false)}
        >
          <HelpModalContent handleClose={() => toggleHelpModal(false)} t={t} />
        </ModalWindow>
      )}
      <div className="DoctorChatTitle">
        <p className="chatTitle">{t("chat")}</p>
        <div className="connectToolBar">
          {props.user.doctor && (started || status === "in_process") && (
            <img
              src={videoCall || audioCall ? stop_call : call}
              alt=""
              onClick={() => callPeer(true)}
            />
          )}
          {props.user.doctor &&
            (started || status === "in_process") &&
            !(videoCall || audioCall) && (
              <img src={video} alt="" onClick={() => callPeer(false)} />
            )}
          {(videoCall || audioCall) && (
            <img
              src={mutedAudio ? muted_audio : microphone}
              alt=""
              onClick={() => setMutedAudio((prev) => !prev)}
            />
          )}
          {videoCall && (
            <img
              src={mutedVideo ? muted_video : video}
              alt=""
              onClick={() => setMutedVideo((prev) => !prev)}
            />
          )}
          <img src={chatSearch} alt="" />
          <img
            src={info}
            alt="info-icon"
            onClick={() => toggleHelpModal(true)}
          />
        </div>
      </div>
      {/* {audioCall && PartnerVideo} */}
      {/* // <video
      //   id="localVideo"
      //   style={{ display: "none" }}
      //   playsInline
      //   autoPlay
      //   muted
      // /> */}
      {
        audioCall && UserVideo
        // <video
        //   id="remoteOpponentVideoElementId"
        //   style={{ display: "none" }}
        //   playsInline
        //   autoPlay
        // />
      }
      {videoCall && (
        <div className={videoFullScreen ? "videoCall fullscreen" : "videoCall"}>
          {PartnerVideo}
          {UserVideo}

          {/* <video
            id="remoteOpponentVideoElementId"
            className="opponentVideo"
            playsInline
            autoPlay
          /> */}

          <video
            id="localVideo"
            className="myVideo"
            muted
            playsInline
            autoPlay
          />
          <div className="icon-buttons">
            {!videoFullScreen && (
              <img
                className="icon-button"
                src={make_full_screen}
                alt=""
                onClick={() => setVideoFullScreen(true)}
              />
            )}
            {videoFullScreen && (
              <img
                className="icon-button"
                src={videoCall ? stop_call : call}
                alt=""
                onClick={() => stopCall({ rejectFromOpponent: false })}
              />
            )}
            {videoFullScreen && (
              <img
                className="icon-button"
                src={mutedAudio ? muted_audio : microphone}
                alt=""
                onClick={() => setMutedAudio((prev) => !prev)}
              />
            )}
            {videoFullScreen && (
              <img
                className="icon-button"
                src={mutedVideo ? muted_video : video}
                alt=""
                onClick={() => setMutedVideo((prev) => !prev)}
              />
            )}
            {videoFullScreen && (
              <img
                className="icon-button"
                src={close_full_screen}
                alt=""
                onClick={() => setVideoFullScreen(false)}
              />
            )}
          </div>
        </div>
      )}
      <div
        className={videoCall ? "messagesArea video" : "messagesArea"}
        id="messagesArea"
      >
        {loadingChat ? (
          <div className="full">
            <Spinner />
          </div>
        ) : (
            parseMessagesList().map((group) => {
              return (
                <React.Fragment key={group.date}>
                  <div className="chatDate">{group.date}</div>
                  {group.list.map((item, i) => (
                    <MessageComponent
                      key={i}
                      item={item}
                      bothInChat={bothInChat}
                      t={t}
                    />
                  ))}
                </React.Fragment>
              );
            })
          )}
      </div>
      <div
        className={`chatFooter ${status === "cancelled" || status === "closed" ? "hidden" : ""
          }`}
      >
        {status === "in_process" || !props.user.doctor || started ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage();
            }}
          >
            <div className="textarea_wrapp">
              <textarea
                className="formFieldDoc textAreaDoc"
                id="chatField"
                placeholder={t("placeholderChatMessage")}
                onChange={handleMessageChange}
                rows={textarea_rows}
                value={messageValue || ""}
                onKeyDown={keyActions}
              ></textarea>
            </div>
            <div className="icon-buttons">
              <div
                className="icon-button"
                onClick={() => toggleTemplatesModal(true)}
              >
                <img src={open_menu} alt="" />
              </div>
              <div
                className="icon-button document-attach"
                onClick={() => setFileAttach((prev) => !prev)}
              >
                <img src={attach_file} alt="" />

                <div
                  className="file-inputs"
                  style={{ display: fileAttach ? "block" : "none" }}
                >
                  <label className="label" htmlFor="img">
                    {t("placeholderImageOrVideo")}
                  </label>
                  <input
                    className="fileinput"
                    onChange={changeHandler}
                    type="file"
                    id="img"
                    name="img"
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                  />
                  <label className="label" htmlFor="doc">
                    {t("placeholderDocument")}
                  </label>
                  <input
                    className="fileinput"
                    onChange={changeHandler}
                    type="file"
                    id="doc"
                    name="doc"
                    accept="text/plain,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                  />
                </div>
              </div>
            </div>
          </form>
        ) : (
            <Button
              onClick={() => {
                localStorage.getItem("currentConsultationId") &&
                  api.consultations
                    .startConsultation(
                      localStorage.getItem("currentConsultationId")
                    )
                    .then(
                      (response) =>
                        response && response.entity && setStarted(true)
                    );
              }}
              className={videoCall ? "startConsult video" : "startConsult"}
              text={t("placeholderStartConsultation")}
              disabled={status === "cancelled" || status === "closed"}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ patients, user }) => ({
  patientCard: patients && patients.patient,
  endOfConsultationFlag: patients && patients.endOfConsultation,
  cancelledConsultationFlag: patients && patients.cancelledConsultation,
  reasonOfEndingConsultation: patients && patients.reasonOfEndingConsultation,
  appointments: patients && patients.appointments,
  doctor: user,
});

export default withRouter(
  connect(mapStateToProps, {
    endOfConsultation,
    getCurrentConsultation,
    getConsultationAnamnesis,
    cancelOfConsultationFlagSet,
    setNetworkError,
  })(Chat)
);
