import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../ui/Button/Button";

import ShevronRight from "../../../../style/icons/ShevronRight/shevron.svg";
import arrowLeft from "../../../../style/icons/ArrowLeftLight/arrow-left.svg";

import "./Address.scss";

const Address = ({ placeholder, disabled, input, blur }) => {
  const { t } = useTranslation();

  const [showOptions, setOptions] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [showAddress, setShowAddress] = useState(false);
  // console.log(selectedOption);
  const handleOnChange = (e) => {
    setSelectedOption({
      ...selectedOption,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (input.value === "") {
      setSelectedOption({});
      setShowAddress(false);
    }
  }, [input.value]);

  const choiseHandler = (e) => {
    const values = Object.values(selectedOption);
    function isPositive(number) {
      return number !== null && number !== "";
    }
    if (values.every(isPositive)) {
      setShowAddress(true);
      setError(false);
      setOptions(false);
      input.onChange(selectedOption);
    } else {
      setError(true);
      setOptions(true);
    }
  };

  return (
    <div className="addressWrapper">
      <Button
        className={`btn ${blur ? "blur" : ""}`}
        secondary
        text={
          Object.keys(selectedOption).length !== 0
            ? `
            ${selectedOption.city ? `г. ${selectedOption.city}, ` : ""}
            ${
              selectedOption.thoroughfare
                ? `ул. ${selectedOption.thoroughfare}, `
                : ""
            }
            ${
              selectedOption.houseNumber
                ? `дом. ${selectedOption.houseNumber}, `
                : ""
            }
          ${
            selectedOption.flatNumber
              ? `кв. ${selectedOption.flatNumber}, `
              : ""
          }
          ${selectedOption.comment ? `код. ${selectedOption.comment}, ` : ""}
          `
            : placeholder
        }
        // ${selectedOption.district ? `р-н. ${selectedOption.district}, ` : ""}
        shevron={ShevronRight}
        type="button"
        onClick={() => setOptions(true)}
        disabled={disabled}
      />
      <div className={`optionSelectingContainer ${showOptions ? "show" : ""}`}>
        <div className="formTitle">
          <img
            src={arrowLeft}
            alt="icon"
            onClick={(e) => {
              choiseHandler(e);
            }}
          />
          {placeholder}
        </div>
        <div className="inputsContainer">
          <input
            name="city"
            onChange={handleOnChange}
            className="addressInput"
            type={"text"}
            placeholder={`${t("city")}*`}
          />
          {/* <input
            name="district"
            onChange={handleOnChange}
            className="addressInput"
            placeholder={`${t("district")}*`}
          /> */}
          <input
            name="thoroughfare"
            onChange={handleOnChange}
            className="addressInput"
            placeholder={`${t("street")}*`}
          />
          <div className={"inlineInputs"}>
            <input
              name="houseNumber"
              onChange={handleOnChange}
              className="addressInput inline"
              placeholder={`${t("building")}*`}
            />
            <input
              name="flatNumber"
              onChange={handleOnChange}
              className="addressInput inline"
              placeholder={`${t("apartment")}*`}
            />
          </div>

          <input
            name="comment"
            onChange={handleOnChange}
            className="addressInput"
            placeholder={t("codeOrNote")}
          />
          <span className={error ? "errorAddress shown" : "errorAddress"}>
            {t("addressValidate")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Address;
