import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Input from "../../components/formComponent/Input/Input";
import DatePicker from "../../components/formComponent/DatePicker/DatePicker";
import Selecter from "../../components/formComponent/Select/Select";
import Address from "../../components/formComponent/Address/Address";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import api from "../../../api";
import FileUploader from "../../components/formComponent/FIleUploader/FileUploader";

import { phoneFormat, voidRequired } from "../../../helpers/validate";

import close from "../../../style/icons/Close/close-icon.svg";
import calendar from "../../../style/icons/Calendar/calendar.svg";
import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";

import "./NewSubscriberForm.scss";

const NewSubscriberForm = ({
  formValues = {},
  setInitalValues,
  resetInitialValues,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [disableButton, isDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setInitalValues({ fillType: { value: "file", text: "Из файла" } });
  // }, []);

  useEffect(() => {
    if (
      formValues &&
      !formValues.newSubscriberFile &&
      formValues.fillType &&
      formValues.fillType.value === "file"
    ) {
      isDisableButton(true);
    } else {
      isDisableButton(false);
    }
  }, [formValues]);

  const toClose = () => {
    isDisableButton(true);
    resetInitialValues();
    setLoading(false);
    setInitalValues({ fillType: { value: "file", text: "Из файла" } });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formValues.fillType && formValues.fillType.value === "file") {
      if (formValues.newSubscriberFile && formValues.newSubscriberFile.file) {
        api.subscriptions
          .upload(formValues.newSubscriberFile.file, id)
          .then(() => {
            console.log("Файл успешно загружен");
          })
          .then(() => {
            setLoading(false);
            toClose();
          })
          .catch((err) =>
            console.log("Во время загрузки файла что-то пошло не так!", err)
          );
      }
    }
  };

  return (
    <div className="newSubscriberFormWrapper">
      <p className="formTitle">{t("addPatient")}</p>
      <Form
        className="newSubscriberForm"
        onSubmit={formSubmit}
        autoComplete="off"
      >
        <Field
          name="fillType"
          component={Toggle}
          options={[
            // { value: "hand", text: "Вручную" },
            { value: "file", text: t("fromFile") },
          ]}
        />
        {loading ? (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="formContent">
              {/*{formValues.fillType && formValues.fillType.value === "hand" ? (*/}
              {/*  <>*/}
              {/*    <div className="commonInfo">Данные пациента</div>*/}
              {/*    <Field*/}
              {/*      name="phone"*/}
              {/*      validate={[voidRequired, phoneFormat]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Номер телефона*"*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="firstName"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Имя*"*/}
              {/*    />*/}

              {/*    <Field*/}
              {/*      name="lastName"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Фамилия*"*/}
              {/*    />*/}

              {/*    <Field*/}
              {/*      name="patronymic"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Отчество*"*/}
              {/*    />*/}

              {/*    <div className="dateOfBirth">*/}
              {/*      <Field*/}
              {/*        validate={[voidRequired]}*/}
              {/*        name={`birthDate`}*/}
              {/*        inputProps={{*/}
              {/*          name: "birthDate",*/}
              {/*        }}*/}
              {/*        component={DatePicker}*/}
              {/*        placeholder="Дата рождения*"*/}
              {/*        validDate={{*/}
              {/*          number: 16,*/}
              {/*          type: "year",*/}
              {/*          period: "isBefore",*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <img*/}
              {/*        className="closeImg"*/}
              {/*        src={close}*/}
              {/*        alt="close"*/}
              {/*        onClick={async () => {*/}
              {/*          await setInitalValues({*/}
              {/*            ...formValues,*/}
              {/*            birthDate: null,*/}
              {/*          });*/}
              {/*          let picker = document.getElementsByClassName(*/}
              {/*            "form-control"*/}
              {/*          );*/}
              {/*          for (let i = 0; i < picker.length; i++) {*/}
              {/*            const element = picker[i];*/}
              {/*            element.value = "";*/}
              {/*          }*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <img*/}
              {/*        className="calendarImg"*/}
              {/*        src={calendar}*/}
              {/*        alt="calendar"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <Field*/}
              {/*      name="sex"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      options={["Мужской", "Женский"]}*/}
              {/*      component={Selecter}*/}
              {/*      placeholder="Пол*"*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="relationShip"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      options={["Йа", "Жона", "sister"]}*/}
              {/*      component={Selecter}*/}
              {/*      placeholder="Родство*"*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="address"*/}
              {/*      component={Address}*/}
              {/*      placeholder="Адрес"*/}
              {/*      label="Адрес*"*/}
              {/*      blur={!formValues.address}*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="insurance"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Компания/страховая*"*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="policeNumber"*/}
              {/*      validate={[voidRequired]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="№ полиса*"*/}
              {/*    />*/}

              {/*    <Field*/}
              {/*      name="email"*/}
              {/*      // validate={[voidRequired, email]}*/}
              {/*      component={Input}*/}
              {/*      placeholder="Электронная почта"*/}
              {/*    />*/}
              {/*  </>*/}
              {/*) : (*/}
              <Field name="newSubscriberFile" component={FileUploader} />
              {/*)}*/}
            </div>
            <div className="formFooter">
              <Button
                text={t("activateSubscription")}
                className="btn"
                type="submit"
                disabled={disableButton}
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

const mapDispatchToProps = { setInitalValues, resetInitialValues };

const mapStateToProps = (state) => ({
  formValues: getFormValues("newSubscriber")(state),
  initialValues: state.formInitialValues,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "newSubscriber", enableReinitialize: true })
);

export default enhance(NewSubscriberForm);
