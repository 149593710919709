import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getAllSpecializations: async (perPage, numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/specialities${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }${
        numberOfPage && perPage
          ? `&perPage=${perPage}`
          : !numberOfPage && perPage
          ? `?perPage=${perPage}`
          : ""
      }`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
    // return axios(config).then((res) => !res.code && res.data);
  },

  addNewSpecializations: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/specialities`,
      headers: setHeader(),
      data,
    };

    return axios(config).then((res) => res && res.data);
  },

  updateSpecializations: (id, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/admin/specialities/${id}`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  uploadFile: (id, file) => {
    let formData = new FormData();
    formData.append("imageFile", file);
    return axios
      .post(`${TEST_API}/admin/specialities/${id}/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },

  deleteSpecializations: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/specialities/${id}`,
      headers: setHeader(),
    };

    return axios(config).then((res) => res && res.data);
  },
};
