import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  employeeList: [],
  filterMode: "all",
};

const employeeReducer = createReducer(initialState)({
  [types.GET_ALL_EMPLOYEES]: (
    state,
    {
      items,
      current_page,
      page_count,
      items_per_page,
      filterMode,
      total_item_count,
      ...props
    }
  ) => {
    return {
      ...state,
      items,
      filterMode,
      current_page,
      page_count,
      items_per_page,
      total_item_count,
      ...props,
    };
  },

  [types.GET_ALL_DOCTORS]: (
    state,
    { items, filterMode, total_item_count, page_count, current_page }
  ) => {
    return {
      ...state,
      countDoctors: total_item_count,
      items,
      filterMode,
      page_count,
      current_page,
    };
  },

  [types.GET_ALL_OPERATORS]: (
    state,
    { items, filterMode, page_count, current_page }
  ) => {
    return {
      ...state,
      items,
      filterMode,
      current_page,
      page_count,
    };
  },
  [types.GET_DOCTORS_BY_FILTER]: (
    state,
    { data: { total_item_count, ...props } }
  ) => {
    return {
      ...state,
      ...props,
    };
  },

  [types.SET_FILTER_MODE_EMPLOYEE]: (state, { filterMode }) => ({
    ...state,
    filterMode,
  }),

  [types.GET_DOCTOR_BY_SEARCH]: (state, { data }) => ({
    ...state,
    data,
  }),

  [types.GET_INSURANCE_COMPANIES]: (state, { data }) => ({
    ...state,
    insuranceCompanies: data.items,
  }),
});

export default employeeReducer;
