import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import siderLogo from "../../../style/images/sider-logo.svg";
import siderLogo2 from "../../../style/images/sider-logo-2.png";
import sheduleSectionIcon from "../../../style/icons/Shedule/shedule-section-icon.svg";
import stuffSectionIcon from "../../../style/icons/Staff/staff-section-icon.svg";
import specializationSectionIcon from "../../../style/icons/Specialization/specialization-section-icon.svg";
import bannerSectionIcon from "../../../style/icons/Banners/banners-section-icon.svg";
import subscriptionSectionIcon from "../../../style/icons/Subscription/subscriptions-section-icon.svg";

import activeBanners from "../../../style/icons/Banners/active-banners-icon.svg";
import activeSpecialization from "../../../style/icons/Specialization/active-spec-icon.svg";
import activeStuff from "../../../style/icons/Staff/active-staff-icon.svg";
import activeShedule from "../../../style/icons/Shedule/active-shedule-icon.svg";
import activeSubscription from "../../../style/icons/Subscription/active-subscription-icon.svg";

import home from "../../../style/icons/Home/home.svg";
import activeHome from "../../../style/icons/Home/home-active.svg";

import client from "../../../style/icons/PlusInCircleSider/plus.svg";
import activeClient from "../../../style/icons/PlusInCircleSider/plus-active.svg";

// import activeSupport from "../../../style/icons/Headphones/headphones-active.svg";
// import support from "../../../style/icons/Headphones/headphones.svg";

import { store } from "../../..";
import { resetInitialValues } from "../../../redux/formInitialValues/actions";
import { setFilterMode as setFilterModeShedule } from "../../../redux/shedule/actions";
import { setFilterMode as setFilterModeConsultation } from "../../../redux/consultations/actions";
import { setFilterMode as setFilterModeEmployees } from "../../../redux/employees/actions";

import "./sider.scss";

const Sider = ({
  resetInitialValues,
  history,
  location: { pathname },
  match: {
    params: { role },
  },
}) => {
  const [forms, setForms] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let forms = store.getState().form;
    setForms(forms);
  }, []);

  const resetAllForms = (forms) => {
    dispatch(setFilterModeShedule("all"));
    dispatch(setFilterModeConsultation("all"));
    dispatch(setFilterModeEmployees("all"));
    Object.keys(forms).map((el) => resetInitialValues(el));
  };
  return (
    <section className="siderContainer">
      <div className="sectionListContainer">
        <img
          className="siderLogo"
          // src={siderLogo}
          src={siderLogo2}
          alt="sider-logo"
          onClick={() => history.push("/")}
        />
        <div className="sectionList">
          {/* if user === admin */}
          {role === "admin" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/shedule");
                }}
              >
                <img
                  src={
                    pathname.includes("shedule")
                      ? activeShedule
                      : sheduleSectionIcon
                  }
                  alt="schedule-icon"
                  title={t("shifts")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/employees");
                }}
              >
                <img
                  src={
                    pathname.includes("employees")
                      ? activeStuff
                      : stuffSectionIcon
                  }
                  alt="stuff-icon"
                  title={t("employees")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/specialization");
                }}
              >
                <img
                  src={
                    pathname.includes("specialization")
                      ? activeSpecialization
                      : specializationSectionIcon
                  }
                  alt="specialization-icon"
                  title={t("specializations")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/banners");
                }}
              >
                <img
                  src={
                    pathname.includes("banner")
                      ? activeBanners
                      : bannerSectionIcon
                  }
                  alt="banner-icon"
                  title={t("banners")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/subscription");
                  // history.push("/home/admin/subscription/subscriptedUsers");
                }}
              >
                <img
                  src={
                    pathname.includes("subscription")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="subscription-icon"
                  title={t("subscriptions")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/promocodes");
                }}
              >
                <img
                  src={
                    pathname.includes("promocodes")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="promocodes-icon"
                  title={t("promocodes")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/onboarding");
                }}
              >
                <img
                  src={
                    pathname.includes("onboarding")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="subscription-icon"
                  title={t("onboarding")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/symptoms");
                }}
              >
                <img
                  src={
                    pathname.includes("symptoms")
                      ? activeClient
                      : client
                  }
                  alt="subscription-icon"
                  title={t("symptoms")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/companies");
                }}
              >
                <img
                  src={
                    pathname.includes("companies")
                      ? activeClient
                      : client
                  }
                  alt="companies-icon"
                  title={t("companies")}
                />
              </div>
            </>
          )}
          {role === "insurance-admin" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/admin/promocodes");
                }}
              >
                <img
                  src={
                    pathname.includes("promocodes")
                      ? activeSubscription
                      : subscriptionSectionIcon
                  }
                  alt="promocodes-icon"
                  title={t("promocodes")}
                />
              </div>
            </>
          )}
          {/* if user === operator */}
          {role === "operator" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/operator/monitoring");
                }}
              >
                <img
                  className={"monitoringIcon"}
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/operator/newClient");
                }}
              >
                <img
                  src={pathname.includes("newClient") ? activeClient : client}
                  alt="client-icon"
                  title={t("client")}
                />
              </div>

              {/* <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/operator/support");
                }}
              >
                <img
                  src={pathname.includes("support") ? activeSupport : support}
                  alt="support-icon"
                  title="Support"
                />
              </div> */}
            </>
          )}
          {/* if user === insurance-operator */}
          {role === "insurance-operator" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/insurance-operator/monitoring");
                }}
              >
                <img
                  className={"monitoringIcon"}
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/insurance-operator/newClient");
                }}
              >
                <img
                  src={pathname.includes("newClient") ? activeClient : client}
                  alt="client-icon"
                  title={t("client")}
                />
              </div>
            </>
          )}
          {/* if user === doctor */}
          {role === "doctor" && (
            <>
              <div
                onClick={() => {
                  resetAllForms(forms);
                  history.push("/home/doctor/monitoring");
                }}
              >
                <img
                  src={pathname.includes("monitoring") ? activeHome : home}
                  alt="monitoring-icon"
                  title={t("monitoring")}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToprops = {
  resetInitialValues,
};

export default withRouter(connect(mapStateToProps, mapDispatchToprops)(Sider));
