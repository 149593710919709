import React from "react";
import { Route, withRouter } from "react-router-dom";
import Sider from "../../components/Sider/Sider";
import { Container } from "../../../style/grid";
import Header from "../../components/Header/Header";
import Shedule from "../Shedule/Shedule";
import Employees from "../Employees/Employees";
import Specialization from "../Specialization/Specialization";
import Banners from "../Banners/Banners";
import Subscription from "../Subscription/Subscription";
import Client from "../Client/Client";
import NewClient from "../NewClient/NewClient";
import { compose } from "redux";
import { connect } from "react-redux";
import Monitoring from "../Monitoring/Monitoring";
import Onboarding from "../Onboarding/Onboarding";
import { NetworkErrorPopup } from "../../components/ui/NetworkErrorPopup/NetworkErrorPopup";
import Symptoms from "../Symptoms/Symptoms";
import PromoCodes from "../PromoCodes/PromoCodes";
import { NewCompanies } from "../NewCompanies/NewCompanies";
import Subscribers from '../Subscribers/Subscribers';

const Home = ({
  match: {
    params: { role },
  },
}) => {
  return (
    <>
      <main>
        <Container className="mainRowBox">
          <Sider />
          <div className="content">
            <Header />
            <NetworkErrorPopup />
            <div className="contentContainer">
              <Route
                path="/home/admin/shedule"
                exact
                render={() => <Shedule role={role} />}
                role={role}
              />
              <Route
                path="/home/admin/employees"
                exact
                render={() => <Employees />}
              />
              <Route
                path="/home/admin/client"
                exact
                render={() => <Client role={role} />}
              />
              <Route
                path={`/home/${role}/client/:patient?/:card?`}
                exact
                render={() => <Client role={role} />}
              />
              <Route
                path="/home/operator/newClient"
                exact
                render={() => <NewClient role={role} />}
              />
              <Route
                path="/home/insurance-operator/newClient"
                exact
                render={() => <NewClient role={role} />}
              />
              <Route
                path="/home/admin/specialization"
                exact
                render={() => <Specialization />}
              />
              <Route
                path="/home/admin/banners"
                exact
                render={() => <Banners />}
              />
              <Route
                path="/home/admin/subscription"
                exact
                render={() => <Subscription />}
              />
              <Route
                path="/home/admin/onboarding"
                exact
                render={() => <Onboarding />}
              />
              <Route
                path="/home/admin/symptoms"
                exact
                render={() => <Symptoms />}
              />
              <Route
                path="/home/admin/companies"
                exact
                render={() => <NewCompanies />}
              />
              <Route
                path="/home/operator/monitoring"
                exact
                render={() => <Monitoring role={role} />}
              />
              <Route
                path="/home/insurance-operator/monitoring"
                exact
                render={() => <Monitoring role={role} />}
              />
              <Route
                path="/home/doctor/monitoring"
                exact
                render={() => <Monitoring role={role} />}
              />
              <Route
                path="/home/admin/subscription/subscribers/:id?"
                exact
                render={() => <Subscribers />}
              />
              <Route
                path="/home/admin/promocodes"
                exact
                render={() => <PromoCodes role={role} />}
              />
              <Route
                path="/home/insurance-admin/promocodes"
                exact
                render={() => <PromoCodes role={role} />}
              />
            </div>
          </div>
        </Container>
      </main>
    </>
  );
};
const mapStateToProps = ({ user, patients }) => ({
  user,
  patientCard: patients && patients.patientCard,
});

const enhance = compose(withRouter, connect(mapStateToProps, null));

export default enhance(Home);
