import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  isGetError: false,
  status: "",
  hold: "",
  succes: "",
  data: {
    message: "",
  },
};

const networkError = createReducer(initialState)({
  [types.GET_NETWORK_ERROR]: (state) => {
    return {
      ...state,
      isGetError: true,
    };
  },
  [types.SET_NETWORK_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGetError: false,
      ...payload,
    };
  },
});

export default networkError;
