import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, getFormValues, reduxForm, Field } from "redux-form";
import { useTranslation } from "react-i18next";

import Spinner from "../../components/ui/Spinner/Spinner";
import Button from "../../components/ui/Button/Button";
import Input from "../../components/formComponent/Input/Input";
import TextArea from "../../components/formComponent/TextArea/TextArea";

import { resetInitialValues } from "../../../redux/formInitialValues/actions";
import { getSymptomsBySpecializationId } from "../../../redux/symptoms/actions";

import { setInitalValues } from "../../../redux/formInitialValues/actions";
import close from "../../../style/icons/Close/close-icon.svg";
import trash from "../../../style/icons/Trash/trash.svg";

import editIcon from "../../../style/icons/PersonEdit/person-edit.svg";

import api from "../../../api";
import "./newSymptomsForm.scss";

const NewSymptomsForm = ({
  edit,
  setEditingOff,
  resetInitialValues,
  setInitalValues,
  initialValues,
  handleSubmit,
  reset,
  formValues,
  items = [],
  getSymptomsBySpecializationId,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [edited, setEdited] = useState({});

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit((values) => {
      if (values.title) {
        const body = {
          symptom: {
            title: values.title,
            specialty: [values.id],
          },
        };
        return api.symptoms
          .addSymptom(body)
          .then(() =>
            getSymptomsBySpecializationId(
              formValues && formValues.id
            ).then(() => setLoading(false))
          );
      }
      Object.keys(edited)
        .filter((el) => edited[el] === true)
        .map((id, i) => {
          const body = {
            symptom: {
              title: values[id],
              specialty: [values.id],
            },
          };
          return api.symptoms
            .editSymptom(body, id)
            .then(() =>
              getSymptomsBySpecializationId(
                formValues && formValues.id
              ).then(() => setLoading(false))
            );
        });
    })();
  };

  useEffect(() => {
    setLoading(true);
    getSymptomsBySpecializationId(formValues && formValues.id)
      .then(({ items }) => {
        if (items.length >= 1) {
          setFieldData(() =>
            items.reduce((acc, item) => {
              if (item.title) {
                acc[item.id] = item.title;
              }
              return acc;
            }, {})
          );
        }
      })
      .then(() => setLoading(false));
  }, [formValues && formValues.id]);

  useEffect(() => {
    setFieldData(() =>
      items.reduce((acc, item) => {
        if (item.title) {
          acc[item.id] = item.title;
        }
        return acc;
      }, {})
    );
  }, [items]);

  useEffect(() => {
    setInitalValues({ ...initialValues, ...fieldData });
  }, [fieldData]);

  const deleteItemHandler = (id) => {
    setLoading(true);
    api.symptoms
      .deleteSymptom(id)
      .then(() =>
        getSymptomsBySpecializationId(formValues && formValues.id).then(() =>
          setLoading(false)
        )
      );
  };

  return (
    <div className="newSymptomFormWrapper">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <p className={`formTitle ${edit ? "edit" : ""}`}>
            {`${edit ? t("addSymptom") : t("chooseSpecialty")} `}
            {edit && (
              <img
                className="titleBackArrow"
                src={close}
                alt="arrow-icon"
                onClick={() => {
                  setEditingOff();
                  resetInitialValues();
                  reset();
                }}
              />
            )}
          </p>
          {edit && (
            <Form className="newSymptomForm" onSubmit={formSubmit}>
              <div className="formContent">
                <Field
                  name="title"
                  component={Input}
                  placeholder={t("placeholderAddSymptom")}
                />
                <div className="separator" />
                <div className="symptomList">
                  {Object.keys(fieldData).length > 0 && (
                    <p>{t("existingSymptoms")}</p>
                  )}
                  {Object.keys(fieldData) &&
                    Object.keys(fieldData).length > 0 &&
                    Object.keys(fieldData).map((el) => {
                      return (
                        <div
                          className={`symptomItem ${
                            edited[el] ? "edited" : "faded"
                          }`}
                          key={el}
                        >
                          <Field
                            name={el}
                            component={TextArea}
                            disabled={!edited[el]}
                          />
                          <img
                            className="symptomTrashIcon"
                            src={trash}
                            alt="trash icon"
                            title={t("deleteSymptom")}
                            onClick={() => deleteItemHandler(el)}
                          />
                          <img
                            className="editIcon"
                            src={editIcon}
                            alt="edit icon"
                            title={t("edit")}
                            onClick={() => {
                              setEdited({ ...edited, [el]: !edited[el] });
                            }}
                          />
                        </div>
                      );
                    })}
                  {!Object.keys(fieldData) && (
                    <p className="emptyText">{t("emptyList")}</p>
                  )}
                </div>
              </div>
              <div className={`formFooter ${edit ? "edit" : ""}`}>
                <Button
                  className="btn"
                  type="submit"
                  text={t("saveButton")}
                  disabled={
                    !Object.keys(edited).length &&
                    formValues &&
                    !formValues.title
                  }
                />
              </div>
            </Form>
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  resetInitialValues,
  getSymptomsBySpecializationId,
  setInitalValues,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("symptoms")(state),
  initialValues: state && state.formInitialValues,
  items:
    state.symptoms &&
    state.symptoms.symptomsById &&
    state.symptoms.symptomsById.items,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "symptoms", enableReinitialize: true })
);

export default enhance(NewSymptomsForm);
