import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Form, Field, getFormValues } from "redux-form";

import api from "../../../api";
import Toggle from "../formComponent/Toggle/Toggle";
import Button from "../ui/Button/Button";
import Spinner from "../ui/Spinner/Spinner";
import FilterSelect from "../formComponent/FilterSelect/FilterSelect";
import DatePicker from "../formComponent/DatePicker/DatePicker";
import Input from "../formComponent/Input/Input";

import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import support from "../../../style/icons/Headphones/headphones.svg";
import home from "../../../style/icons/Home/home.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import clinic from "../../../style/icons/Doc/doc-blue-in-grey.svg";

import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import {
  getFilteredConsultations,
  getAllConsultations,
} from "../../../redux/consultations/actions";
import { getFilteredPromocodes } from "../../../redux/promocodes/actions";

import "./filter.scss";
import { setPopUpSuccess } from "../../../redux/networkError/actions";

const Filter = ({
  loading,
  setLoading,
  getFilteredConsultations,
  getFilteredPromocodes,
  getAllConsultations,
  resetInitialValues,
  formValues = {},
  setInitalValues,
  filterMode,
  mode,
  specializations,
  role,
  handleSubmit,
  reset,
  setFilterParams,
  language,
  setFilterParametres,
  filter_params,
  isItemsFiltered,
  insuranceOptions = [],
  setPopUpSuccess,
  getAllPromoCodes,
  setItemsFiltered,
}) => {
  const { t } = useTranslation();

  const statusOptions = [
    { title: t("newConsultation"), value: "new" },
    { title: t("filledConsultation"), value: "filled" },
    { title: t("closedConsultation"), value: "closed" },
    { title: t("inProgressConsultation"), value: "in_process" },
    { title: t("cancelledConsultation"), value: "cancelled" },
  ];

  const [change, setChange] = useState(false);
  const [isDisableButton, setDisabledButton] = useState(true);

  useEffect(() => {
    if (mode === "promocodes") {
      if (
        formValues &&
        formValues.promocodeToggle &&
        formValues.promocodeToggle.value === "Filter"
      ) {
        if (
          !!formValues.promocodeStatus ||
          !!formValues.promocodeType ||
          !!formValues.dateStart ||
          !!formValues.dateEnd ||
          !!formValues.insuranceCompany
        ) {
          setDisabledButton(false);
        }
      } else {
        if (
          formValues &&
          !!formValues.promocodeCount &&
          formValues &&
          !!formValues.promocodeType
        ) {
          setDisabledButton(false);
        }
      }
    }
  }, [formValues]);

  useEffect(() => {
    if (filter_params && Object.keys(filter_params).length > 0) {
      setFilterParams({ ...filter_params });
      //setInitalValues({ ...filter_params })
    }
  }, [])

  const formSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleSubmit((values) => {
      const filterType =
        formValues &&
        formValues.promocodeToggle &&
        formValues.promocodeToggle.value;
      if (mode === "promocodes" && filterType === "addPromocode") {
        const data = {
          count: formValues && formValues.promocodeCount,
          promocode: {
            type:
              formValues &&
              formValues.promocodeType &&
              formValues.promocodeType.value,
            insuranceCompany:
              formValues &&
              formValues.insuranceCompany &&
              formValues.insuranceCompany.id,
          },
        };
        return api.promocodes
          .generateNewPromocodes(data)
          .then(() =>
            setPopUpSuccess({
              response: {
                succes: true,
                status: t("success"),
                data: {
                  message: t("promocodeGenerateSuccess"),
                },
              },
            })
          )
          .then(() => {
            resetFormHandler();
            getAllPromoCodes();
            setLoading(false);
          });
      } else if (mode === "promocodes" && filterType === "Filter") {
        setItemsFiltered(true);
        setFilterParams({ ...formValues });
        getFilteredPromocodes(
          formValues &&
            formValues.promocodeStatus &&
            formValues.promocodeStatus.value,
          formValues &&
            formValues.promocodeType &&
            formValues.promocodeType.value,
          formValues && formValues.dateStart,
          formValues && formValues.dateEnd,
          formValues &&
            formValues.insuranceCompany &&
            formValues.insuranceCompany.id
        );
        setLoading(false);
      } else {
        setFilterParams({ ...values });
        getFilteredConsultations({...values, filterMode: 'all', role, isItemsFiltered }, 1).then(() => setLoading(false));
      }
    })();
  };

  const resetFormHandler = () => {
    getAllConsultations(role, filterMode);
    reset();
    resetInitialValues();
    setFilterParams({});
    setDisabledButton(true);
    setItemsFiltered(false);
    if (mode === "promocodes") {
      setInitalValues({
        promocodeToggle: { value: "Filter", text: "Фильтр" },
      });
      getAllPromoCodes();
    }
  };

  var chosenDateStart = formValues && formValues.dateStart;
  var valid = function (current) {
    return current.isSameOrAfter(chosenDateStart);
  };

  return (
    <div className="wrappFilterForm">
      {loading ? (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="formTitle">
            {formValues &&
            formValues.promocodeToggle &&
            formValues.promocodeToggle.value === "addPromocode"
              ? t("addPromoCodes")
              : t("filterFormTitle")}
            <img
              className="resetForm"
              title="Очистить форму"
              onClick={resetFormHandler}
              src={close}
              alt="closeIcon"
            />
          </div>
          <Form className="monitoringFilters" onSubmit={formSubmit}>
            <div className="formContent">
              {mode === "shedule" ? (
                <>
                  <Field
                    name="allOrActive"
                    component={Toggle}
                    options={[
                      { value: "all", text: t("filterAllSchedules") },
                      { value: "active", text: t("filterActiveSchedules") },
                    ]}
                  />
                </>
              ) : mode === "promocodes" ? (
                <Field
                  name="promocodeToggle"
                  component={Toggle}
                  options={[
                    { value: "Filter", text: "Фильтр" },
                    { value: "addPromocode", text: "Добавить промокод" },
                  ]}
                />
              ) : (
                <Field
                  name="allOrInWork"
                  component={Toggle}
                  options={[
                    { value: "all", text: t("filterAllSchedules") },
                    { value: "inWork", text: t("filterActiveSchedules") },
                  ]}
                />
              )}

              {mode !== "shedule" && mode !== "promocodes" && (
                <Field
                  name="status"
                  component={FilterSelect}
                  placeholder={t("status")}
                  options={statusOptions}
                  withIcon
                  withPreview={formValues && formValues.status}
                  icon={plus}
                  withIconInOptions
                />
              )}

              {mode === "promocodes" &&
                formValues &&
                formValues.promocodeToggle &&
                formValues.promocodeToggle.value !== "addPromocode" && (
                  <div className="dateRange">
                    <Field
                      name={`dateStart`}
                      inputProps={{
                        name: "dateStart",
                        // required: required,
                      }}
                      // validate={[required, date, maxDateNow]}
                      component={DatePicker}
                      placeholder="--/--/----"
                      locale={language.lang}
                    />
                    <Field
                      name={`dateEnd`}
                      inputProps={{
                        name: "dateEnd",
                        // required: required,
                      }}
                      // validate={[required, date, maxDateNow]}
                      component={DatePicker}
                      validDate={valid}
                      placeholder="--/--/----"
                    />
                    <img
                      src={close}
                      alt="close"
                      onClick={async () => {
                        await setInitalValues({
                          ...formValues,
                          dateStart: null,
                          dateEnd: null,
                        });
                        let picker = document.getElementsByClassName(
                          "form-control"
                        );
                        for (let i = 0; i < picker.length; i++) {
                          const element = picker[i];
                          element.value = "";
                        }
                      }}
                    />
                    <img src={calendar} alt="calendar" />
                  </div>
                )}
              {mode === "promocodes" && role !== "insurance-admin" && (
                <Field
                  name="insuranceCompany"
                  component={FilterSelect}
                  options={insuranceOptions}
                  placeholder={t("insuranceCompany")}
                  withPreview
                />
              )}
              {/*Form for FILTER promocodes*/}
              {mode === "promocodes" &&
                formValues &&
                formValues.promocodeToggle &&
                formValues.promocodeToggle.value === "Filter" && (
                  <>
                    <Field
                      name="promocodeType"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeType_0"), value: 0, id: 0 },
                        { title: t("promocodeType_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeType")}
                      withPreview
                    />
                    <Field
                      name="promocodeStatus"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeStatus_0"), value: 0, id: 0 },
                        { title: t("promocodeStatus_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeStatus")}
                      withPreview
                    />
                  </>
                )}

              {/*Form for ADDICTED promocodes*/}
              {mode === "promocodes" &&
                formValues &&
                formValues.promocodeToggle &&
                formValues.promocodeToggle.value === "addPromocode" && (
                  <>
                    <Field
                      name="promocodeType"
                      component={FilterSelect}
                      options={[
                        { title: t("promocodeType_0"), value: 0, id: 0 },
                        { title: t("promocodeType_1"), value: 1, id: 1 },
                      ]}
                      placeholder={t("promocodeType")}
                      withPreview
                    />
                    <Field
                      name="promocodeCount"
                      component={Input}
                      freeTopSpace
                      type="number"
                      min={0}
                      max={100}
                      placeholder={t("promocodeCount")}
                    />
                  </>
                )}
              {(role === "doctor" ||
                role === "operator" ||
                role === "insurance-operator") &&
                mode !== "promocodes" && (
                  <Field
                    name="serviceFormat"
                    component={FilterSelect}
                    placeholder={t("filterTypeOfService")}
                    options={[
                      {
                        imgUrl: support,
                        title: t("optionsServiceTypeOnline"),
                        type: 0,
                      },
                      {
                        imgUrl: home,
                        title: t("optionsServiceTypeAtHome"),
                        type: 3,
                      },
                      {
                        imgUrl: clinic,
                        title: t("optionsServiceTypeInHospital"),
                        type: 4,
                      },
                    ]}
                    withPreview
                    withIconInOptions
                    previewIcon={plus}
                    icon={plus}
                    withIcon
                  />
                )}

              {mode !== "shedule" &&
                role !== "doctor" &&
                mode !== "promocodes" && (
                  <Field
                    name="specialization"
                    component={FilterSelect}
                    placeholder={t("placeholderSpecialty")}
                    options={specializations}
                    withIcon
                    withPreview={formValues && formValues.specialization}
                    icon={plus}
                  />
                )}

              {mode === "shedule" && mode !== "promocodes" && (
                <>
                  <Field
                    name="specialization"
                    component={FilterSelect}
                    placeholder={t("placeholderSpecialty")}
                    options={specializations}
                    withSearch
                    multiple
                    withPreview={formValues && formValues.specialization}
                    previewIcon={docAvatar}
                  />
                  <Field
                    name="serviceFormat"
                    component={FilterSelect}
                    placeholder={t("filterTypeOfService")}
                    options={[
                      { imgUrl: support, title: "Онлайн" },
                      { imgUrl: home, title: "Визит на дом" },
                    ]}
                    withPreview
                    withIconInOptions
                  />
                </>
              )}
            </div>
            <div className="formFooter">
              <Button
                className="btn"
                disabled={
                  mode === "promocodes"
                    ? isDisableButton
                    : formValues &&
                      !formValues.serviceFormat &&
                      !(formValues.dateStart && formValues.dateEnd) &&
                      !formValues.status &&
                      !formValues.specialization
                }
                type="submit"
                text={t("acceptButton")}
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  getFilteredConsultations,
  getFilteredPromocodes,
  getAllConsultations,
  resetInitialValues,
  setPopUpSuccess,
};

const mapStateToProps = ({ language, monitoring, ...state }) => ({
  formValues: getFormValues("monitoringFilters")(state),
  initialValues: state.formInitialValues,
  filterMode: state.monitoring && state.monitoring.filterMode,
  language,
  filter_params: monitoring && monitoring.filter_params,
  isItemsFiltered: monitoring && monitoring.isItemsFiltered,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "monitoringFilters", enableReinitialize: true })
);

export default enhance(Filter);
